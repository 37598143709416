import {fetchMiddleWare as fetch} from '../fetchMiddleWare';

// Generated by https://quicktype.io

export interface Stages {
  [key: string]: StageDetail;
}

export interface StageDetail {
  create_account?: boolean;
  chrome_installed: boolean;
  outlook_installed: boolean;
  assign_data_expert: boolean;
  create_business_term: boolean;
}

export const getStages = async (): Promise<Stages> => {
  let response = await fetch('/api/users/profile/stages/', {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });
  let result = await response.json();
  return result.stages;
};
