import React from 'react';
import {Tr, Td, Text} from 'spekit-ui';
import {NoResults} from '../NoResults';

interface NoContentResultsProps {
  isLoading: boolean;
  hasFiltered: boolean;
  hasSearched: boolean;
  handleSearchClear: () => void;
  handleResetFilters: () => void;
}

const ContentWrapper = (props: {children: React.ReactNode}) => {
  return (
    <Tr>
      <Td border='none' colSpan={2} textAlign='center' height='450px'>
        {props.children}
      </Td>
    </Tr>
  );
};

export const NoContentResults = (props: NoContentResultsProps) => {
  const {isLoading, hasFiltered, hasSearched, handleSearchClear, handleResetFilters} =
    props;

  const hasNoResultsWithAppliedFilters = !isLoading && hasFiltered;
  const hasNoResultsWithSearch = !isLoading && hasSearched;
  const hasNoResultsWithoutSearch = !isLoading && !hasSearched;

  if (hasNoResultsWithSearch) {
    return (
      <ContentWrapper>
        <NoResults
          handleSearchClear={handleSearchClear}
          body={`Your search didn't return any results. Modify your search terms and try again.`}
        />
      </ContentWrapper>
    );
  }

  if (hasNoResultsWithAppliedFilters) {
    return (
      <ContentWrapper>
        <NoResults
          handleSearchClear={handleResetFilters}
          body={`The filters you applied didn't return any results. Modify your filters and try again.`}
          buttonText='Clear filters'
        />
      </ContentWrapper>
    );
  }

  if (hasNoResultsWithoutSearch) {
    return (
      <ContentWrapper>
        <Text variant='body1' fontWeight={400}>
          This folder is empty or contains file types that aren't supported.
        </Text>
      </ContentWrapper>
    );
  }

  return null;
};
