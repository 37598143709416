import React from 'react';
import {IFileOrFolder} from 'spekit-types';
import {ModalFooter, DSButton as Button, Flex, Text, Icon} from 'spekit-ui';
import {RiArrowLeftLine} from 'react-icons/ri';

interface IProps {
  breadcrumbs: {id: string; name: string}[];
  handleBack: () => void;
  handleNext: () => void;
  selectedFiles: IFileOrFolder[];
}

export const FilePickerFooter = ({
  breadcrumbs,
  handleBack,
  handleNext,
  selectedFiles,
}: IProps) => {
  return (
    <ModalFooter p={0} m={0} justifyContent={'space-between'}>
      {breadcrumbs.length > 1 && (
        <Button
          leftIcon={<Icon as={RiArrowLeftLine} />}
          variant='ghost'
          onClick={handleBack}
          colorScheme='white'
          size='medium'
          data-testid='treeview-back-button'
        >
          Back
        </Button>
      )}

      <Flex justifyContent='space-between' alignItems='center' gap='16' ml='auto'>
        <Text
          data-testid='files-count-label'
          variant='caption1'
          fontWeight={400}
          lineHeight={4}
        >
          {selectedFiles.length} file{selectedFiles.length === 1 ? '' : 's'} selected
        </Text>

        <Button
          variant='contained'
          colorScheme='primary'
          size='medium'
          disabled={selectedFiles.length === 0}
          onClick={handleNext}
          data-testid='next-btn'
        >
          Next
        </Button>
      </Flex>
    </ModalFooter>
  );
};
