import {useEffect} from 'react';
import {useState} from 'react';
import {
  Card,
  Flex,
  Icon,
  Heading,
  Text,
  Loader,
  Box,
  DSPagination as Pagination,
} from 'spekit-ui';
import {quizzes as KnowledgeCheckAPI} from 'spekit-datalayer';
import {IQuiz, IQuizListResponse, TQuizProgress} from 'spekit-types';
import {RiCheckDoubleLine} from 'react-icons/ri';
import ItemCard from './ItemCard';

const sort = (knowledgeChecks: IQuiz[]) => {
  knowledgeChecks.sort((a: IQuiz, b: IQuiz) => {
    return new Date(b.published_on!).getTime() - new Date(a.published_on!).getTime();
  });
  const progressOrder: TQuizProgress[] = ['Not started', 'In progress', 'Completed'];
  knowledgeChecks.sort((a: IQuiz, b: IQuiz) => {
    return progressOrder.indexOf(a.progress) - progressOrder.indexOf(b.progress);
  });
};

const PAGE_LIMIT = 4;

export const Assigned = () => {
  const [myKnowledgeChecks, setMyKnowledgeChecks] = useState<IQuizListResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchMyKnowledgeChecks = async () => {
      setIsLoading(true);
      try {
        const response = await KnowledgeCheckAPI.getQuizzesForViewer({
          assigned: true,
          limit: PAGE_LIMIT,
          offset: (currentPage - 1) * PAGE_LIMIT,
          status: ['published'],
        });
        sort(response.results);
        setMyKnowledgeChecks(response);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMyKnowledgeChecks();
  }, [currentPage]);

  return (
    <Card maxH='618px' p='16'>
      <Flex direction='column' gap='24'>
        <Flex gap='12'>
          <Icon as={RiCheckDoubleLine} color='primary.500' w='24px' h='24px' />
          <Heading as='h1' fontSize='large' fontWeight='semibold'>
            Knowledge Checks
          </Heading>
        </Flex>
        {isLoading ? (
          <Flex
            data-testid='widget-loader'
            h='500px'
            alignItems='center'
            justifyContent='center'
          >
            <Loader />
          </Flex>
        ) : myKnowledgeChecks && myKnowledgeChecks.highest_count !== 0 ? (
          <>
            {myKnowledgeChecks.results.map((knowledgeCheck) => (
              <ItemCard key={knowledgeCheck.id} {...knowledgeCheck} />
            ))}

            <Flex alignItems='baseline' justifyContent='space-between'>
              <Text variant='caption1' fontWeight='normal' flex='1'>
                Showing {(currentPage - 1) * PAGE_LIMIT + 1} to{' '}
                {Math.min(currentPage * PAGE_LIMIT, myKnowledgeChecks.highest_count)} of{' '}
                {myKnowledgeChecks.highest_count} results.
              </Text>
              <Pagination
                totalPages={Math.ceil(myKnowledgeChecks.highest_count / PAGE_LIMIT)}
                currentPage={currentPage}
                onPageSelect={(pageNumber: number) => {
                  if (pageNumber !== currentPage) {
                    setCurrentPage(pageNumber);
                  }
                }}
              />
              <Box flex='1' />
            </Flex>
          </>
        ) : (
          <Text variant='body1' fontWeight='normal'>
            All clear! You have no Knowledge Checks to complete right now. You'll be
            notified when one is available.
          </Text>
        )}
      </Flex>
    </Card>
  );
};

export default Assigned;
