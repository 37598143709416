import React, {forwardRef} from 'react';
import {
  Select as ChakraSelect,
  AsyncSelect as ChakraAsyncSelect,
  CreatableSelect as ChakraCreatableSelect,
  AsyncCreatableSelect as ChakraAsyncCreatableSelect,
  AsyncCreatableProps,
  CreatableProps,
  Props,
  ChakraStylesConfig,
  chakraComponents,
  DropdownIndicatorProps,
  AsyncProps,
  MultiValueGenericProps,
  SingleValueProps,
  ClearIndicatorProps,
  MenuProps,
  SelectInstance,
} from 'chakra-react-select';
import {MdErrorOutline} from 'react-icons/md';
import {RiArrowDownSLine, RiCloseLine} from 'react-icons/ri';
import {Icon} from '../Icon';
import {Flex} from '../Layout';
import {Tooltip} from '../Tooltip';
import {IconType} from 'react-icons';

export type TReactSelectProps = Props & {
  controlIcon?: IconType;
};

// This is typed as "any" because of the way chakra-react-select handles AsyncProps.
// To have proper types will require creating our own wrapper for react-select + chakraUI
export type TReactSelectAsyncProps = AsyncProps<any, any, any>;
export type TReactCreatableSelectProps = CreatableProps<any, any, any>;
export type TReactAsyncCreatableProps = AsyncCreatableProps<any, any, any>;

export type TSelectInstanceRef = SelectInstance<unknown, boolean, any> | null;

type TSelectRef =
  | ((instance: SelectInstance<unknown, boolean, any> | null) => void)
  | React.MutableRefObject<SelectInstance<unknown, boolean, any> | null>
  | null;

export const components = (label = '', menuTestId = '') => ({
  DropdownIndicator: (props: DropdownIndicatorProps) => {
    const {
      selectProps: {isInvalid, isDisabled},
    } = props;
    return isInvalid ? (
      <chakraComponents.DropdownIndicator {...props}>
        <Flex gap={12} justifyContent='center' alignItems='center'>
          <Icon as={MdErrorOutline} fontSize='1.25em' color='error.600' />
          <Icon
            data-testid='dropdown-indicator'
            as={RiArrowDownSLine}
            color='neutral.800'
            fontSize='22px'
            p={6}
            transition='all 0.2s'
            borderRadius={8}
            _hover={{background: isDisabled ? 'transparent' : 'neutral.50'}}
          />
        </Flex>
      </chakraComponents.DropdownIndicator>
    ) : (
      <chakraComponents.DropdownIndicator {...props}>
        <Icon
          data-testid='dropdown-indicator'
          as={RiArrowDownSLine}
          color='neutral.800'
          fontSize='22px'
          p={6}
          transition='all 0.2s'
          borderRadius={8}
          _hover={{background: isDisabled ? 'transparent' : 'neutral.50'}}
        />
      </chakraComponents.DropdownIndicator>
    );
  },
  ClearIndicator: (props: ClearIndicatorProps) => {
    return (
      <chakraComponents.ClearIndicator {...props}>
        <Icon as={RiCloseLine} color='neutral.800' data-testid='clear-indicator' />
      </chakraComponents.ClearIndicator>
    );
  },
  MultiValueLabel: (props: MultiValueGenericProps) => {
    return props.data.isFixed ? (
      <Tooltip label={label} placement='top'>
        <span>
          <chakraComponents.MultiValueContainer {...props} />
        </span>
      </Tooltip>
    ) : (
      <chakraComponents.MultiValueContainer {...props} />
    );
  },
  Menu: (props: MenuProps) => (
    <chakraComponents.Menu
      {...props}
      // @ts-ignore -- data-testid is not a supported inner prop but we need it
      // for e2e tests, there are no side effects
      innerProps={{...props.innerProps, 'data-testid': menuTestId}}
    />
  ),
  SingleValue: (props: SingleValueProps) => {
    // @ts-ignore --have to disable ts for the next line as that will require us to override chakra react selects typings
    const {controlIcon} = props.selectProps;
    return (
      <chakraComponents.SingleValue {...props}>
        {controlIcon && (
          <Flex mr={8} align='center'>
            <Icon
              as={controlIcon}
              color='neutral.800'
              fontSize='16px'
              transition='all 0.2s'
            />
          </Flex>
        )}

        {props.children}
      </chakraComponents.SingleValue>
    );
  },
});

export const chakraStyles: ChakraStylesConfig = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    background: 'transparent',
    pr: 6,
    fontSize: '14px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    ps: 12,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    display: state.selectProps.isInvalid ? 'none' : 'flex',
    color: 'neutral.800',
    fontSize: '1.25em',
    _hover: {
      bg: 'neutral.50',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  container: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    minH: '44px',
    height: 'revert',
    boxSizing: 'border-box',
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
  menuList: (provided) => ({
    ...provided,
    minW: 'revert',
    maxW: 'revert',
    padding: 'revert',
  }),
  option: (provided, state) => ({
    ...provided,
    width: 'auto',
    bg: state.isSelected || state.isFocused ? 'neutral.50' : 'none',
    fontWeight: '400',
    fontSize: 'small',
    color: 'neutral.700',
    p: '10px 18px', // This is provided in px because chakra-react-select overrides padding depending on the size on this particular property
    cursor: 'pointer',
    _hover: {
      bg: 'neutral.50',
    },
    _focus: {
      bg: 'neutral.50',
    },
  }),
  multiValue: (provided, state: any) => ({
    ...provided,
    bg: 'neutral.50',
    boxSizing: 'border-box',
    color: 'gray.800',
    py: 2,
    px: 8,
    borderRadius: 16,
    minWidth: 0,
    marginRight: 4,
    pointerEvents: state.isDisabled ? 'none' : 'revert',
    _hover: {
      cursor: state.data.isFixed ? 'not-allowed' : 'pointer',
    },
    h: '25px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: 'tiny',
    fontWeight: 'normal',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    fontSize: 'medium',
    opacity: '1',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    background: 'neutral.50',
    border: 'none',
    _hover: {
      background: 'neutral.200',
    },
    _focus: {
      boxShadow: 'none',
      background: 'transparent',
    },
  }),
  singleValue: (provided, state) => {
    // @ts-ignore
    if (state.selectProps.controlIcon) {
      return {
        ...provided,
        display: 'flex',
        alignItems: 'center',
        lineHeight: '20px',
      };
    }

    return provided;
  },
};

export const Select = forwardRef(
  (
    {chakraStyles: additionalChakraStyles, ...rest}: TReactSelectProps,
    ref: TSelectRef
  ) => (
    <ChakraSelect
      components={components()}
      chakraStyles={{...chakraStyles, ...additionalChakraStyles} as ChakraStylesConfig}
      id='select-container'
      ref={ref}
      blurInputOnSelect={!rest.isMulti}
      closeMenuOnSelect={!rest.isMulti}
      {...rest}
    />
  )
);

export const AsyncSelect = forwardRef(
  (
    {chakraStyles: additionalChakraStyles, ...rest}: TReactSelectAsyncProps,
    ref: TSelectRef
  ) => (
    <ChakraAsyncSelect
      components={components()}
      chakraStyles={{...chakraStyles, ...additionalChakraStyles} as ChakraStylesConfig}
      id='select-container'
      {...rest}
      ref={ref}
      blurInputOnSelect={!rest.isMulti}
      closeMenuOnSelect={!rest.isMulti}
    />
  )
);

export const CreatableSelect = forwardRef(
  (
    {chakraStyles: additionalChakraStyles, ...rest}: TReactCreatableSelectProps,
    ref: TSelectRef
  ) => (
    <ChakraCreatableSelect
      components={components()}
      chakraStyles={{...chakraStyles, ...additionalChakraStyles} as ChakraStylesConfig}
      id='select-container'
      {...rest}
      ref={ref}
      createOptionPosition='first'
      isClearable
      blurInputOnSelect={!rest.isMulti}
      closeMenuOnSelect={!rest.isMulti}
    />
  )
);

export const AsyncCreatableSelect = forwardRef(
  (
    {chakraStyles: additionalChakraStyles, ...rest}: TReactAsyncCreatableProps,
    ref: TSelectRef
  ) => (
    <ChakraAsyncCreatableSelect
      components={components()}
      chakraStyles={{...chakraStyles, ...additionalChakraStyles} as ChakraStylesConfig}
      id='select-container'
      {...rest}
      ref={ref}
      createOptionPosition='first'
      isClearable
      blurInputOnSelect={!rest.isMulti}
      closeMenuOnSelect={!rest.isMulti}
    />
  )
);

export {chakraComponents};
export type TSingleValueProps = SingleValueProps;
