import React from 'react';
import styles from './TabSelectInput.module.css';
import {classNames} from '../utils/classNames';
import Fontawesome from '../fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export interface TabSelectInputProps {
  value: string;
  options: string[];
  disabled?: boolean;
  icons?: Array<IconProp>;
  onChange: (item: string) => void;
}

export const TabSelectInput = (props: TabSelectInputProps) => {
  const {options, value, disabled, icons, onChange} = props;

  const handleOnChange = (item: string) => () => {
    if (onChange && typeof onChange === 'function' && !disabled) {
      onChange(item);
    }
  };

  return (
    <div style={{display: 'flex'}}>
      {options.map((item, i) => (
        <div
          data-testid={`tabselect-${item}`}
          key={item}
          className={classNames(
            styles.tab,
            disabled ? styles.disabled : '',
            item === value ? styles.selected : ''
          )}
          title={item}
          onClick={handleOnChange(item)}
        >
          {icons && icons.length === options.length && (
            <Fontawesome name={icons[i]} style={{marginRight: '0.5em'}} />
          )}
          {item}
        </div>
      ))}
    </div>
  );
};

export default TabSelectInput;
