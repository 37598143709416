export const QUERY_KEYS = {
  CustomFields: 'custom_fields',
  Seismic: 'seismic',
  Spek: 'spek',
  Summaries: 'context_summaries',
  RecentlyViewedContent: 'recent_views',
  BrandConfig: 'brand_config',
  FeatureFlags: 'feature_flags',
  StarredTerms: 'starred_terms',
  FeedbackQuestions: 'feedback_questions',
  ListContentFiles: 'list_content_files',
  SharePointSearch: 'sharepoint_search',
  TopicDetails: 'topic_details',
} as const;
