import {
  QueryCache,
  QueryClient,
  QueryClientProvider as RQQueryClientProvider,
} from '@tanstack/react-query';
import React from 'react';
import {logging} from 'spekit-datalayer';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => logging.capture(error, {force: true}),
  }),
  defaultOptions: {queries: {staleTime: 60 * 1000}},
});

export function QueryClientProvider({children}: {children: React.ReactNode}) {
  return <RQQueryClientProvider client={queryClient}>{children}</RQQueryClientProvider>;
}

export function TestQueryClientProvider({children}: {children: React.ReactNode}) {
  queryClient.clear();
  return <RQQueryClientProvider client={queryClient}>{children}</RQQueryClientProvider>;
}
