import React from 'react';
import {RiArrowRightSLine} from 'react-icons/ri';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  DSBreadcrumb as Breadcrumb,
  DSTooltip as Tooltip,
  MoreMenuButton,
  SimpleMenuItem,
} from 'spekit-ui';

interface FolderBreadcrumbsProps {
  items: {id: string; name: string}[];
  onItemClick: (item: {id: string; name: string}) => void;
}

export const FolderBreadcrumbs = (props: FolderBreadcrumbsProps) => {
  const {items, onItemClick} = props;
  if (!items) return null;

  const showMenu = items.length > 3;
  const menuItems = showMenu ? items.slice(0, -2) : [];
  const breadcrumbItems = showMenu ? items.slice(-2) : items;

  return (
    <Breadcrumb separator={<RiArrowRightSLine />} sx={{'> ol:first-of-type': {my: 0}}}>
      {showMenu && (
        <BreadcrumbItem>
          <MoreMenuButton
            size='small'
            variant='ghost'
            insidePortal={false}
            zIndex={2}
            tooltipLabel=''
          >
            {menuItems.map((item) => (
              <SimpleMenuItem key={item.id} onClick={() => onItemClick(item)}>
                {item.name}
              </SimpleMenuItem>
            ))}
          </MoreMenuButton>
        </BreadcrumbItem>
      )}
      {breadcrumbItems.map((item, index) => (
        <BreadcrumbItem
          key={item.id}
          isCurrentPage={index === breadcrumbItems.length - 1}
        >
          <BreadcrumbLink
            isCurrentPage={index === breadcrumbItems.length - 1}
            aria-label={`Navigate to ${item.name}`}
            onClick={() => {
              index === breadcrumbItems.length - 1 ? undefined : onItemClick(item);
            }}
            maxW='150px'
            noOfLines={1}
          >
            <Tooltip label={item.name.length > 20 ? item.name : ''} placement='top'>
              {item.name}
            </Tooltip>
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
