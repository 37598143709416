import {extendTheme} from '@chakra-ui/react';
import {colors, space, radii, boxShadow, breakpoints, updateColors} from './Foundations';
import {styles} from './global';
import {TextConfig as Text, fontSizes, HeadingConfig as Heading} from '../Typography';
import {TooltipConfig as Tooltip} from '../Tooltip';
import {ButtonConfig as Button} from '../Button';
import {DrawerConfig as Drawer} from '../Drawer';
import {MenuConfig as Menu} from '../Menu';
import {TagConfig as Tag} from '../Tag';
import {TabsConfig as Tabs} from '../Tabs';
import {CardConfig as Card} from '../Components';
import {ProgressConfig as Progress} from '../Progress';
import {InputConfig as Input} from '../Form/Input';
import {TextareaConfig as Textarea} from '../Form/Textarea';
import {FormControlConfig as FormControl} from '../Form';
import {RadioConfig as Radio} from '../Radio';
import {UtilityBarConfig as UtilityBar} from '../UtilityBar';
import {SwitchConfig as Switch} from '../Form/Switch';
import {CheckboxConfig as Checkbox} from '../Checkbox';
import {AlertConfig as Alert} from '../Alert';
import {ModalConfig as Modal} from '../Modal';
import {DividerConfig as Divider} from '../Divider';
import {PopoverConfig as Popover} from '../Popover';
import {Image} from '../Image';
import {AvatarConfig as Avatar} from '../Avatar';
import {TableConfig as Table} from '../Table';
import {PaginationConfig as Pagination} from '../Pagination';
import {BreadcrumbConfig as Breadcrumb} from '../Breadcrumb';
import {LinkConfig as Link} from '../Link';
import {SpinnerConfig as Spinner} from '../Spinner';
import {SkeletonConfig as Skeleton} from '../Skeleton';
import {getColorPalette} from '../ColorInput';
import {AccordionConfig as Accordion} from '../Accordion';

export const theme = extendTheme({
  colors,
  fontSizes,
  space,
  radii,
  boxShadow,
  styles: styles(),
  breakpoints,
  components: {
    Accordion,
    Skeleton,
    Spinner,
    Link,
    Breadcrumb,
    Image,
    Table,
    Avatar,
    Popover,
    Modal,
    Divider,
    Alert,
    Switch,
    Checkbox,
    FormControl,
    Input,
    Card,
    Menu,
    Tabs,
    Progress,
    Text,
    Textarea,
    Heading,
    Tooltip,
    Button,
    Drawer,
    Tag,
    Radio,
    UtilityBar,
    Pagination,
  },
  config: {
    cssVarPrefix: 'spekit',
  },
});

export const updateTheme = (
  primaryColor?: string | null,
  overrideGlobalStyles?: boolean
) => {
  let updatedTheme = theme;
  if (primaryColor) {
    // colors are used directly in some parts of the app and design system instead of using
    // design system colors. So we need to update them to completely reflect new colors across the app.
    updateColors({...colors, primary: getColorPalette(primaryColor)});
    updatedTheme = extendTheme(theme, {
      colors,
    });
  }
  if (overrideGlobalStyles) {
    updatedTheme = extendTheme(updatedTheme, {styles: styles(overrideGlobalStyles)});
  }

  return updatedTheme;
};

export default theme;
