import {
  IAsset,
  ISearchResult,
  TAssetExtension,
  TAssetMimeType,
  TContentIntegration,
  TDocumentType,
} from 'spekit-types';
import {VscSourceControl} from 'react-icons/vsc';
import {RiWindowsLine, RiDriveLine} from 'react-icons/ri';

const ALLOWED_FILE_MIME_TYPES = {
  pdf: 'application/pdf',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
};

const ALLOWED_FILE_MIME_TYPES_EXTENDED = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
  csv: 'text/csv',
};

// These are the mime types that are not allowed to be uploaded.
// Our backend supported them but our viewer does not.
const UPLOAD_RESTRICTED_MIME_TYPES = ['text/csv'];

const FILE_TAGS = {
  pdf: 'PDF',
  pptx: 'PPT',
  doc: 'DOC',
  docx: 'DOCX',
  xlsx: 'XLSX',
  xls: 'XLS',
  csv: 'CSV',
} as const;

const GOOGLE_DRIVE_FILE_TAGS = {
  ...FILE_TAGS,
  doc: 'GDOC',
  docx: 'GDOC',
  xlsx: 'GSHEET',
  xls: 'GSHEET',
  pptx: 'GSLIDE',
} as const;

const AllowedFileExtensions = Object.fromEntries(
  Object.entries(ALLOWED_FILE_MIME_TYPES).map(([k, v]) => [v, k])
);
const AllowedFileExtensionsExtended = Object.fromEntries(
  Object.entries(ALLOWED_FILE_MIME_TYPES_EXTENDED).map(([k, v]) => [v, k])
);

function assertNever(value: never): never {
  throw new Error(`Unexpected case of ${value}`);
}

export const getAllowedMimeTypes = (isExtended: boolean = false) => {
  return isExtended
    ? {...ALLOWED_FILE_MIME_TYPES, ...ALLOWED_FILE_MIME_TYPES_EXTENDED}
    : ALLOWED_FILE_MIME_TYPES;
};

export const getAllowedExtensions = (isExtended: boolean = false) => {
  return isExtended
    ? {...AllowedFileExtensions, ...AllowedFileExtensionsExtended}
    : AllowedFileExtensions;
};

export const getAssetExtension = (mimeType: TAssetMimeType): TAssetExtension => {
  const allowedExt = getAllowedExtensions(true);
  return (allowedExt[mimeType] as TAssetExtension) || assertNever(mimeType as never);
};

export const getAssetMimeType = (extension: TAssetExtension): TAssetMimeType => {
  const allowedMime: any = getAllowedMimeTypes(true);
  return (allowedMime[extension] as TAssetMimeType) || assertNever(extension as never);
};

export const getFileTag = (file: IAsset | ISearchResult) => {
  const {content_type, store} = file;

  const extension = getAssetExtension(content_type!);

  if (store === 'gdrive') {
    return GOOGLE_DRIVE_FILE_TAGS[extension] || 'PDF';
  }

  return FILE_TAGS[extension] || 'PDF';
};

export const getDropzoneAcceptedFiles = (isExtended: boolean = false) => {
  const allowedMime = getAllowedMimeTypes(isExtended);
  return Object.values(allowedMime)
    .filter((mt) => !UPLOAD_RESTRICTED_MIME_TYPES.includes(mt))
    .reduce((a, v) => ({...a, [v]: []}), {});
};

export const extensionToDocumentType: Record<
  TAssetExtension | 'ppt',
  TDocumentType | 'pdf'
> = {
  pdf: 'pdf',
  ppt: 'presentation',
  pptx: 'presentation',
  doc: 'document',
  docx: 'document',
  xls: 'spreadsheet',
  xlsx: 'spreadsheet',
  csv: 'spreadsheet',
};

export const getDocumentTypeFromContentType = (mimeType: TAssetMimeType) => {
  const extension = getAssetExtension(mimeType);
  return extensionToDocumentType[extension] || 'document';
};

export const getDocumentTypeFromExtension = (extension: TAssetExtension) => {
  return extensionToDocumentType[extension] || 'document';
};

export const getLabelForStore = (store: TContentIntegration) => {
  if (store === 'gdrive') {
    return 'View in Google Drive';
  }

  return 'View in SharePoint';
};

export const getIconForStoreSource = (store: TContentIntegration) => {
  const icons = {
    gdrive: RiDriveLine,
    'microsoft-sharepoint': RiWindowsLine,
  };
  return icons[store] || VscSourceControl;
};
