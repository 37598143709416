import {retrieve} from '../utils/APIHelpers';
import {TopicProgressResponse} from './topicProgression.types';

export const getTopicProgress = async (
  topicId: string
): Promise<TopicProgressResponse> => {
  return await retrieve(`/learning/v1/progression/progress/${topicId}`);
};

export const getCachedProgress = async (
  topicId: string
): Promise<TopicProgressResponse> => {
  return await retrieve(`/learning/v1/progression/progress/${topicId}/cached/`);
};
