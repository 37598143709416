import {useIntegrationApp, IntegrationAppClient} from '@integration-app/react';
import {useQuery} from '@tanstack/react-query';
import {QUERY_KEYS} from 'spekit-datalayer';
import {IFileOrFolder, IFilePickerParams} from 'spekit-types';
import {buildSharepointSearchQuery} from '../helpers';

interface ISearchActionOutput {
  records: IFileOrFolder[];
  from: string;
  size: string;
  moreResultsAvailable: boolean;
  total: number;
}

// performs search for the entire site
const performSearch = async (iApp: IntegrationAppClient, params: IFilePickerParams) => {
  const size = 100;
  let from = 0;
  let hasMoreResults = true;
  const records: IFileOrFolder[] = [];

  const query = buildSharepointSearchQuery(params);

  while (hasMoreResults) {
    const response = await iApp
      .connection(params.store)
      .action('search-filter-files')
      .run({from, size, query});
    const output = response.output as ISearchActionOutput;
    const files = output.records;

    records.push(...files);

    hasMoreResults = output.moreResultsAvailable;

    from += size;
  }

  return records;
};

export const useSharePointSearch = (params: IFilePickerParams) => {
  const iApp = useIntegrationApp();

  const hasSearchTerm = Boolean(params.searchTerm);
  const hasFilters =
    params.filter?.period?.value !== 'any' || params.filter?.type?.value !== 'all';

  const enabled =
    (hasSearchTerm || hasFilters) && params.store === 'microsoft-sharepoint';

  const query = useQuery({
    queryKey: [QUERY_KEYS.SharePointSearch, params],
    queryFn: () => performSearch(iApp, params),
    refetchOnWindowFocus: false,
    enabled,
    staleTime: 5 * 60 * 1000,
  });

  return query;
};
