import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {capture} from '../logging';
import {SEISMIC_API} from '../api';
import {ISeismicResponse} from 'spekit-types';

export const Seismic = {
  getSeismicContent: async (queryString: string): Promise<ISeismicResponse> => {
    try {
      let response = await fetch(`${SEISMIC_API}${queryString}`);
      let result = await response.json();
      return result.seismic;
    } catch (err) {
      capture(err);
      throw new Error(err);
    }
  },
  loadMore: async (continuationToken: string): Promise<ISeismicResponse> => {
    try {
      let response = await fetch(
        `${SEISMIC_API}continuation_token=${continuationToken}`,
        {}
      );
      let result = await response.json();
      return result.seismic;
    } catch (err) {
      capture(err);
      throw new Error(err);
    }
  },
};
