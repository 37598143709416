import React, {useState, useCallback} from 'react';
import Select from 'react-select/async';
import {Dropdown} from 'spekit-ui';
import {TeamDetail} from 'spekit-datalayer/src/team/team.types';
import {team} from 'spekit-datalayer';
import debounce from 'debounce-promise';
import {IOptionType} from 'spekit-types';

export interface ITeamSelectorCallback {
  label: string;
  value: string;
  permissions?: string;
  default?: boolean;
}

interface IProps {
  value?: IOptionType | null;
  onSelect: (cbValue: ITeamSelectorCallback | null) => void;
  selectedTeam?: any;
  enableAllTeamsOption?: boolean;
  isClearable?: boolean;
  sortAlphabetically?: boolean;
  dashboardSelector?: boolean;
  loadfirstTeamByDefault?: boolean;
  removeAccessAllTopicsTeams?: boolean;
  monitorScrollElement?: any;
  portal?: boolean;
  assignableOnly?: boolean;
  disablePermissions?: boolean;
  showOnlySpekitTeams?: boolean;
}

const EnhancedDropDown: any = Dropdown(Select);
export const TeamSelectorDropdown = ({
  onSelect,
  value,
  enableAllTeamsOption,
  isClearable,
  sortAlphabetically,
  loadfirstTeamByDefault,
  removeAccessAllTopicsTeams,
  monitorScrollElement,
  portal,
  assignableOnly,
  disablePermissions,
  showOnlySpekitTeams,
}: IProps) => {
  const defaultPermission = `teams.update_own`;
  const [teams, setTeams] = useState<any>(false);

  const handleTeamChange = useCallback(
    (newValue: any, actionMeta: any) => {
      let {action, removedValue} = actionMeta;
      if (action === 'select-option') {
        setTeams({
          label: newValue.label,
          value: newValue.value,
          permissions: newValue.permissions,
          default: newValue.default,
        });
        onSelect({
          label: newValue.label,
          value: newValue.value,
          permissions: newValue.permissions,
          default: newValue.default,
        });
      }
      if (action === 'remove-value' || action === 'pop-value') {
        let filteredTeams = teams.filter((t: any) => t !== removedValue.label);
        setTeams(filteredTeams);
        onSelect(filteredTeams);
      }
      if (action === 'clear') {
        setTeams(null);
        onSelect(null);
      }
    },
    [onSelect, teams]
  );

  const getTeams = (searchTerm: string) => {
    return team
      .getTeamListBySuggestions(
        {
          q: searchTerm,
          ordering: 'name',
          permissions: disablePermissions ? undefined : defaultPermission,
          ...(showOnlySpekitTeams && {dir_group: false}),
        },
        {
          sortAlphabetically,
          assignableOnly,
        }
      )
      .then((res: Array<TeamDetail>) => {
        let teams = res;
        if (removeAccessAllTopicsTeams) {
          teams = teams.filter((item) => !item.access_all_topics);
        }
        let dropDownTeams = teams.map((item) => ({
          value: item.value,
          label: item.label,
          permissions: item.dashboard_permissions,
          default: item.default,
        }));
        if (enableAllTeamsOption) {
          dropDownTeams.unshift({
            label: 'All Teams',
            value: 'All Teams',
            permissions: [],
            default: false,
          });
        }
        if (loadfirstTeamByDefault) {
          handleTeamChange(dropDownTeams[0], {
            action: 'select-option',
          });
        }
        return dropDownTeams;
      });
  };

  const getDouncedTeams = debounce(getTeams, 200, {leading: false});

  return (
    <EnhancedDropDown
      ariaLabel={'team-select'}
      isClearable={isClearable}
      defaultOptions
      noOptionMessage='No teams found'
      placeholder='Select a team'
      teamTopicStyle={enableAllTeamsOption ? true : false}
      teamStyles={enableAllTeamsOption ? false : true}
      loadOptions={getDouncedTeams}
      handleChange={handleTeamChange}
      menuPortalTarget={portal ? document.querySelector('body') : null}
      closeMenuOnScroll={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (
          monitorScrollElement &&
          monitorScrollElement.current &&
          event.target.isSameNode(monitorScrollElement.current)
        ) {
          return true;
        }
        return false;
      }}
      value={value}
    />
  );
};
