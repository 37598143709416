import {RexApp} from 'spekit-types';

export const isOutreachKaia = (url: string) => url.includes('web.outreach.io/kaia');
export const isSalesloft = (url: string) => url.includes('app.salesloft.com');
export const isGmail = (url: string) => url.includes('mail.google.com');
export const isChorus = (url: string) => url.includes('chorus.ai/meeting/');
export const isGong = (url: string) => url.includes('app.gong.io');
export const isOutlook = (url: string) =>
  url.includes('outlook.office365.com') ||
  url.includes('outlook.office.com') ||
  url.includes('outlook.live.com');
export const isSalesforce = (url: string) => url.includes('lightning.force.com');
export const isSalesforceOpportunity = (url: string) =>
  isSalesforce(url) && url.includes('lightning/r/Opportunity');
export const isClariCopilot = (url: string) => url.includes('copilot.clari.com/call');
export const isSmartSend = (url: string) =>
  url.includes('lightning.force.com') && url.includes('lightning/r/Opportunity');
export const isRexSupported = (url: string) =>
  isGmail(url) ||
  isChorus(url) ||
  isGong(url) ||
  isOutlook(url) ||
  isSalesloft(url) ||
  isSalesforceOpportunity(url) ||
  isOutreachKaia(url) ||
  isClariCopilot(url);
export const getApp = (url: string): RexApp => {
  if (isSalesforceOpportunity(url)) return 'salesforce_opportunity';
  if (isOutlook(url)) return 'outlook';
  if (isGmail(url)) return 'gmail';
  if (isSalesloft(url)) return 'salesloft';
  if (isChorus(url)) return 'chorus';
  if (isGong(url)) return 'gong';
  if (isOutreachKaia(url)) return 'outreach_kaia';
  if (isClariCopilot(url)) return 'clari_copilot';
  return '';
};
