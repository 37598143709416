import {Counts} from './accessSettings.types';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {GenericCountsApiError} from './error.types';

export const count = {
  get: function (): Promise<Counts> {
    return new Promise((res, rej) => {
      fetch('/api/generic/counts', {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((r: any) => r.json())
        .then((result: any) => {
          if (!result) {
            throw new GenericCountsApiError('unexpected response from user api');
          } else {
            let response: Counts = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
};
