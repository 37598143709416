import {useSelector} from 'react-redux';
import {Fontawesome} from 'spekit-ui';
import {faSun, faMoon} from '@fortawesome/free-solid-svg-icons';

const GreetingWidget = (props: {isOverview?: boolean}) => {
  const me = useSelector((state: any) => state.layout.me);

  const getTime = () => {
    let hour = new Date().getHours();
    if (hour < 12) {
      return {
        label: 'morning',
        icon: faSun,
      };
    }
    if (hour < 18) {
      return {
        label: 'afternoon',
        icon: faSun,
      };
    }
    return {
      label: 'evening',
      icon: faMoon,
    };
  };

  const styles = {
    goodLabel: {
      color: props.isOverview ? '#333E52' : '#637280',
      fontSize: props.isOverview ? '22px' : '16px',
      fontWeight: 600,
    },
    celestialIcon: {
      marginRight: '0.5em',
    },
  };

  return (
    <div>
      <Fontawesome style={styles.celestialIcon} name={getTime().icon} />
      <span style={styles.goodLabel}>
        Good {getTime().label}, {me.first_name}.
      </span>
    </div>
  );
};

export default GreetingWidget;
