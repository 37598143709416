import {ICustomFieldMultiOptions, TCustomField} from 'spekit-types';

export const mockMultiOption = {
  id: '70de5f35-534e-454a-99b3-52e040c32a15',
  name: 'Network Connection',
  data_type: 'multi-option',
  on: ['business_terms', 'files'],
  options: [
    {
      id: '66570675-9104-427b-81ad-fe18e2700962',
      name: 'Jazz',
    },
    {
      id: '15911d1a-b191-448c-827f-6045b863474a',
      name: 'Ufone',
    },
    {
      id: '1d4345c6-80de-4731-aa50-bddc13964b87',
      name: 'Telenor',
    },
  ],
} as ICustomFieldMultiOptions;

export const customFields = [
  {
    id: 'f9736a42-65cd-481a-8f12-e311835ab106',
    name: 'asdasd',
    data_type: 'string',
    on: ['business_terms'],
  },
  {
    id: 'b6f95df4-88b1-4da0-a083-e4bbaa024742',
    name: 'File Name',
    data_type: 'string',
    on: ['files'],
  },
  mockMultiOption,
  {
    id: '2c66a44c-a4ea-4b7d-a6a5-0a963e11aeb5',
    name: 'Salesforce_tag',
    data_type: 'string',
    on: ['objects', 'fields', 'field_values'],
  },
] as TCustomField[];
