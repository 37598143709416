import {IDockAPIResponse, IDockState} from 'spekit-types';
import {retrieve, create} from '../utils/APIHelpers';

export const fetchDockSettings = async () => {
  const dockResult = await retrieve<IDockAPIResponse>('/api/user_dock_settings');
  const {position, domains, id, size} = dockResult.settings;

  return {
    size,
    position,
    domains,
    id,
  };
};

export const setDockSettings = async (state: IDockState) => {
  const {domains, position, size, id} = state;

  let payload = {
    domains,
    position,
    size,
    id,
  };

  return create<Omit<IDockState, 'version'>, {success: boolean}>(
    '/api/user_dock_settings',
    payload
  );
};
