import {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import GreetingWidget from './greetingWidget';
import StepsWidget from './stepsWidget';
import StatsWidget from './statsWidget';
import TodoWidget from './todoWidget';
import MostViewedWidget from './mostViewedWidget';
import RecentReactionsWidget from './recentReactionsWidget';
import {PropagateLoader} from 'react-spinners';
import {track} from './utils/analytics';
import {SpotlightAnalyticsTable} from 'spekit-shared-components';
import {showSpotlightModalWithId} from './redux/actions';
import {Assigned as MyKnowledgeChecksWidget} from './features/KnowledgeChecks/components/Widget';
import TeamSelectorDashboard from './components/TeamSelectorDashboard/TeamSelectorDashboard';

const tempHideStatsWidget = true;
class Dashboard extends Component {
  // this is another class-based component we should refactor soon!
  state = {
    stepsCompleted: false,
    team: null,
    permissions: null,
  };

  handleTeamChange = (team) => {
    this.setState({team: team, permissions: team.permissions});
    track('Filtered by team', {screen_name: 'Dashboard', team: team});
  };
  getTeamId = () => {
    return this.state.team != null ? this.state.team.value : null;
  };

  render() {
    let {me} = this.props;
    if (!me) {
      return null;
    }
    let styles = {
      main: {
        maxWidth: '1360px',
        margin: 'auto',
        padding: '24px',
      },
      greeting: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#637280',
        fontWeight: '300',
        fontSize: '20px',
        padding: '0px 0px 10px',
      },
      tile1: {
        backgroundColor: '#FFF',
        color: '#637280',
        boxShadow: '-10px 10px 20px 0 rgba(30,30,30,0.05)',
        boxSizing: 'border-box',
        verticalAlign: 'top',
        padding: '30px 0px',
        marginTop: '20px',
      },
      tile2: {
        backgroundColor: '#D4DDED',
        color: '#354052',
        boxShadow: '-10px 10px 20px 0 rgba(30,30,30,0.05)',
        boxSizing: 'border-box',
        padding: '30px',
        display: 'inline-block',
        verticalAlign: 'top',
      },
      tile3: {
        boxShadow: '-10px 10px 20px 0 rgba(30,30,30,0.05)',
        boxSizing: 'border-box',
        padding: '30px',
        display: 'inline-block',
        verticalAlign: 'top',
        backgroundColor: '#B4E4F3',
        color: '#354052',
      },
      tile4: {
        color: '#354052',
        backgroundColor: 'rgba(120,124,196,0.14)',
        boxSizing: 'border-box',
        padding: '30px',
        display: 'inline-block',
        verticalAlign: 'top',
      },
      tile5: {
        color: '#354052',
        backgroundColor: 'rgba(120,124,196,0.14)',
        boxShadow: '-10px 10px 20px 0 rgba(30,30,30,0.05)',
        boxSizing: 'border-box',
        padding: '30px',
        display: 'inline-block',
        verticalAlign: 'top',
      },
      tile6: {
        background: '#CDE4FE',
        boxShadow: '-10px 10px 20px 0 rgba(30,30,30,0.05)',
        color: '#354052',
        padding: '30px',
        boxSizing: 'border-box',
        display: 'inline-block',
      },
      tile7: {
        boxShadow: '-10px 10px 20px 0 rgba(30,30,30,0.05)',
        gridColumn: '1 / -1',
        padding: '30px',
        backgroundColor: '#ffffff',
      },
      gridContainer: {
        marginTop: '20px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
        gridTemplateRows: 'repeat(2, 600px)',
        gridColumnGap: '20px',
        gridRowGap: '20px',
      },
      loader: {
        marginRight: '110px',
      },
      mainLoader: {
        display: 'flex',
        marginTop: '300px',
        justifyContent: 'center',
        alignItems: 'center',
      },
    };
    let widgets;
    if (!this.state.permissions) {
      widgets = (
        <div style={styles.gridContainer}>
          <div style={styles.tile2}>
            <TodoWidget teamId={this.getTeamId()} />
          </div>
          {/* <div style={styles.tile3}>
            <ReactionWidget teamId={this.getTeamId()} />
          </div> */}
          <div style={styles.tile6}>
            <MostViewedWidget teamId={this.getTeamId()} />
          </div>
          <div style={styles.tile5}>
            <RecentReactionsWidget teamId={this.getTeamId()} />
          </div>
          {this.props.flag.hasSpotlightFlag && (
            <div style={styles.tile7}>
              <SpotlightAnalyticsTable
                teamId={this.getTeamId()}
                showSpotlightModalWithId={this.props.showSpotlightModalWithId}
              />
            </div>
          )}
        </div>
      );
    } else if (
      this.state.permissions &&
      (this.state.permissions.includes('users.can_view_admin_dashboard') ||
        this.state.permissions.includes('users.can_view_expert_dashboard') ||
        this.state.permissions.includes('users.can_view_team_admin_dashboard'))
    ) {
      widgets = (
        <div style={styles.gridContainer}>
          <div style={styles.tile2}>
            <TodoWidget teamId={this.getTeamId()} />
          </div>
          {/* <div style={styles.tile3}>
            <ReactionWidget teamId={this.getTeamId()} />
          </div> */}
          <div style={styles.tile6}>
            <MostViewedWidget teamId={this.getTeamId()} />
          </div>
          <div style={styles.tile5}>
            <RecentReactionsWidget teamId={this.getTeamId()} />
          </div>
          {this.props.flag.hasSpotlightFlag && (
            <div style={styles.tile7}>
              <SpotlightAnalyticsTable
                teamId={this.getTeamId()}
                showSpotlightModalWithId={this.props.showSpotlightModalWithId}
              />
            </div>
          )}
        </div>
      );
    } else if (
      this.state.permissions &&
      this.state.permissions.includes('users.can_view_viewer_dashboard')
    ) {
      widgets = (
        <div style={styles.gridContainer}>
          {/* <div style={styles.tile3}>
            <ReactionWidget teamId={this.getTeamId()} />
          </div> */}
          <div style={styles.tile6}>
            <MostViewedWidget teamId={this.getTeamId()} />
          </div>
        </div>
      );
    }
    return (
      <Fragment>
        <div style={styles.main}>
          <div style={styles.greeting}>
            <GreetingWidget />
            {!this.props.flag.hasQuizFlag && (
              <TeamSelectorDashboard
                value={this.state.team}
                onSelect={this.handleTeamChange}
              />
            )}
          </div>
          {this.props.flag.hasQuizFlag && <MyKnowledgeChecksWidget />}

          {me.company.salesforceorganizations_set.length && !tempHideStatsWidget ? (
            <div style={styles.tile1}>
              {this.state.stepsCompleted || this.props.me.role === 'Super Admin' ? (
                <StatsWidget teamId={this.getTeamId()} />
              ) : (
                <StepsWidget
                  teamId={this.getTeamId()}
                  role={me.role}
                  package={me.package}
                  onComplete={() => this.setState({stepsCompleted: true})}
                />
              )}
            </div>
          ) : !this.state.stepsCompleted ? (
            <div style={styles.tile1}>
              <StepsWidget
                teamId={this.getTeamId()}
                role={me.role}
                package={me.package}
                onComplete={() => this.setState({stepsCompleted: true})}
              />
            </div>
          ) : null}

          {this.props.flag.hasQuizFlag && (
            <TeamSelectorDashboard
              value={this.state.team}
              onSelect={this.handleTeamChange}
            />
          )}

          {!this.state.team ? (
            <div style={styles.mainLoader}>
              <PropagateLoader loading={true} size={10} color='#3576E8' />
            </div>
          ) : (
            widgets
          )}
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showSpotlightModalWithId: (id) => dispatch(showSpotlightModalWithId(id)),
  };
};

export default withRouter(
  connect((state, map) => {
    return {
      me: state.layout.me,
      flag: state.flag,
    };
  }, mapDispatchToProps)(Dashboard)
);
