import React from 'react';
import {Flex, Text, DSButton as Button} from 'spekit-ui';

interface IProps {
  handleSearchClear: () => void;
  heading?: string;
  body?: string;
  buttonText?: string;
}

export const NoResults = (props: IProps) => {
  const {handleSearchClear, heading, body, buttonText} = props;

  const headingText = heading || 'No results found';
  const bodyText =
    body ||
    `The term you entered didn't bring up any results. Modify your search term and try again.`;
  const textForButton = buttonText || 'Clear search';

  return (
    <Flex
      mt={24}
      mb={64}
      direction='column'
      align='center'
      justify='center'
      textAlign='center'
    >
      <Text variant='body1' fontWeight={600} mb={12}>
        {headingText}
      </Text>
      <Text variant='body2' fontWeight={400} maxWidth='380px'>
        {bodyText}
      </Text>
      <Button
        variant='outlined'
        size='medium'
        mt={24}
        onClick={handleSearchClear}
        aria-label={textForButton}
      >
        {textForButton}
      </Button>
    </Flex>
  );
};
