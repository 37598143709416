import {
  IDrive,
  IFileOrFolder,
  IFilePickerParams,
  IFilter,
  IStoreParams,
} from 'spekit-types';
import {sub, startOfDay} from 'spekit-datalayer';
import {fileMimeTypes, mimeTypeGroups, MY_DRIVE, TIntegrationKeys} from './constants';
import {v4 as uuidv4} from 'uuid';

const parseId = (id: string) => {
  try {
    return JSON.parse(id);
  } catch (e) {
    return null;
  }
};

const sharePointMimeTypes = [
  fileMimeTypes.pdf,
  fileMimeTypes.default.doc,
  fileMimeTypes.default.sheet,
  fileMimeTypes.default.slide,
];

const getFileType = (fileType: string) => {
  if (!fileType) return null;
  const typeMap = {
    presentations: fileMimeTypes.default.slide,
    spreadsheets: fileMimeTypes.default.sheet,
    documents: fileMimeTypes.default.doc,
    pdfs: fileMimeTypes.pdf,
  };

  return typeMap[fileType] || null;
};

export const getPeriodClause = (
  period: string,
  propertyKey: string,
  wrapWithQuotes = true
) => {
  const today = new Date();
  const wrap = (date: string) => (wrapWithQuotes ? `'${date}'` : date);

  // Handle yesterday separately since it needs both start and end dates
  if (period === 'yesterday') {
    const yesterdayStart = startOfDay(sub(today, {days: 1})).toISOString();
    const todayStart = startOfDay(today).toISOString();
    return `${propertyKey} >= ${wrap(yesterdayStart)} AND ${propertyKey} <= ${wrap(
      todayStart
    )}`;
  }

  // Map period names to number of days
  const periodDays = {
    today: 0,
    last7days: 7,
    last30days: 30,
    last90days: 90,
  };

  const days = periodDays[period];
  if (!days && days !== 0) return '';

  const startDate = startOfDay(days === 0 ? today : sub(today, {days})).toISOString();
  return `${propertyKey} >= ${wrap(startDate)}`;
};

export const buildSharepointSearchQuery = (params: IFilePickerParams) => {
  const {searchTerm, filter, drive} = params;

  if (!drive) return '*';

  const clauses = [];

  // Add supported file types filter
  let mimeTypesList = [
    fileMimeTypes.pdf,
    fileMimeTypes.default.doc,
    fileMimeTypes.default.sheet,
    fileMimeTypes.default.slide,
  ];

  if (searchTerm) {
    const sanitized = searchTerm.replace(/[<>:"/\\|?*]/g, '');
    clauses.push(`filename:${sanitized}*`);
  }

  if (filter?.period?.value) {
    const dateClause = getPeriodClause(filter.period.value, 'lastModifiedTime', false);
    if (dateClause) clauses.push(dateClause);
  }

  if (filter?.type?.value) {
    const typeClause = getFileType(filter.type.value);
    if (typeClause) mimeTypesList = [typeClause];
  }

  const mimeTypes = mimeTypesList
    .map((mimeType) => `mimetype:'${mimeType}'`)
    .join(' OR ');

  clauses.push(`(${mimeTypes})`);

  clauses.push('isDocument=True');

  clauses.push(`path:"${drive.uri}"`);

  return clauses.join(' ').trim();
};

const buildGoogleQuery = (parentId: string, searchTerm: string, filters?: IFilter) => {
  const clauses = [];

  let mimeTypesList = [
    fileMimeTypes.pdf,
    // arrays of mimetypes
    ...mimeTypeGroups.slides,
    ...mimeTypeGroups.docs,
    ...mimeTypeGroups.sheets,
  ];

  // Only include folders if we're not searching
  if (!searchTerm) {
    mimeTypesList.unshift(fileMimeTypes.folder);
  }

  if (filters) {
    const {period, type} = filters;

    if (period && period.value) {
      const dateClause = getPeriodClause(period.value, 'modifiedDate');
      if (dateClause) {
        clauses.push(dateClause);
      }
    }

    if (type && type.value !== 'all') {
      switch (type.value) {
        case 'presentations':
          mimeTypesList = [...mimeTypeGroups.slides];
          break;
        case 'spreadsheets':
          mimeTypesList = [...mimeTypeGroups.sheets];
          break;
        case 'documents':
          mimeTypesList = [...mimeTypeGroups.docs];
          break;
        case 'pdfs':
          mimeTypesList = [fileMimeTypes.pdf];
          break;
        default:
          break;
      }
    }
  }

  const mimeTypes = mimeTypesList
    .map((mimeType) => `mimeType='${mimeType}'`)
    .join(' or ');

  clauses.push(`(${mimeTypes})`);

  if (searchTerm) {
    clauses.push(`title contains '${searchTerm}'`);
  } else {
    clauses.push(`'${parentId}' in parents`);
  }

  clauses.push('trashed = false');

  return clauses.join(' and ').trim();
};

export const getParentId = (folderId?: string, driveId?: string) => {
  if (folderId) return folderId;
  if (driveId === MY_DRIVE.id) return MY_DRIVE.id;
  return null;
};

export const getParams = (
  store: TIntegrationKeys,
  drive: IDrive,
  parentId: string | null,
  searchTerm: string = '',
  filters?: IFilter
): IStoreParams => {
  if (store !== 'gdrive') {
    return {
      siteId: 'root',
      driveId: drive.id,
      folderId: parentId ? parseId(parentId)?.fileId || 'root' : 'root',
      recursive: false,
    };
  }

  const isMyDrive = drive.id === MY_DRIVE.id;
  const effectiveParentId = isMyDrive
    ? parentId !== MY_DRIVE.id
      ? parentId!
      : 'root'
    : parentId || drive.id;

  return {
    q: buildGoogleQuery(effectiveParentId, searchTerm, filters),
    ...(isMyDrive ? {} : {corpora: 'drive', driveId: drive.id}),
  };
};

export const isFolder = (store: TIntegrationKeys, fields: IFileOrFolder['fields']) => {
  if (store === 'gdrive') {
    return fields.mimeType === fileMimeTypes.folder;
  } else if (store === 'microsoft-sharepoint') {
    return fields.itemType === 'folder';
  }
  return false;
};

export const getFilesOnly = (records: IFileOrFolder[]) => {
  return records.filter((item) => sharePointMimeTypes.includes(item.fields.mimeType));
};

export const getFilesAndFolders = (records: IFileOrFolder[], store: TIntegrationKeys) => {
  return records.filter(
    (item) =>
      sharePointMimeTypes.includes(item.fields.mimeType) || isFolder(store, item.fields)
  );
};

export const connectionEventTracker = (
  connectionKey: string,
  eventScreen: string,
  track: (eventName: string, data?: Record<string, any>) => void
) => {
  const eventName = 'Content Store Connection';
  const eventId = uuidv4();
  const trackingData = {
    connectionKey,
    eventId,
    eventStatus: 'init',
    eventError: '',
    eventDescription: '',
    eventScreen,
  };

  const trackEvent = (status: string, error: string, description: string) => {
    const d = {
      ...trackingData,
      eventStatus: status,
      eventError: error,
      eventDescription: description,
    };
    track(eventName, d);
  };
  return trackEvent;
};
