import {Component} from 'react';
import {connect} from 'react-redux';
import {fetchMiddleWare} from 'spekit-datalayer';
import connectDone from './images/progress_connect_done.svg';
import downloadExtension from './images/progress_downloadExtension_notDone.svg';
import downloadExtensionDone from './images/progress_downloadExtension_done.svg';
import takeItForSpin from './images/progress_takeSpin_notDone.svg';
import takeItForSpinDone from './images/progress_takeSpin_Done.svg';
import delegate from './images/progress_delegate_notDone.svg';
import delegateDone from './images/progress_delegate_done.svg';
import defineField from './images/progress_defineField_notDone.svg';
import defineFieldDone from './images/progress_defineField_done.svg';
import knowledgeCard from './images/progress_knowledgeCard_notDone.svg';
import knowledgeCardDone from './images/progress_knowledgeCard_done.svg';
import bulkUpload from './images/progress_bulkUpload_notDone.svg';
import bulkUploadDone from './images/progress_bulkUpload_done.svg';
import connectSFIncomplete from './images/connectSF_incomplete.svg';

const fetch = fetchMiddleWare.fetchMiddleWare;
class StageStep extends Component {
  resources = {
    connect_sf: {
      img: connectDone,
      imgFalse: connectSFIncomplete,
      imgAlt: 'connected to salesforce',
      imgAltFalse: 'connect to salesforce',
      heading: 'Connect to Salesforce',
      subHeading: 'Why start from scratch when you can use your CRM?',
    },
    create_account: {
      img: connectDone,
      imgAlt: 'created account',
      heading: 'Create account',
      subHeading: 'Get started with your Spekit journey',
    },
    chrome_installed: {
      img: downloadExtensionDone,
      imgFalse: downloadExtension,
      imgAlt: 'downloaded extension',
      imgAltFalse: 'download extension',
      heading: 'Download Extension',
      subHeading: 'Access your knowledge from Chrome or Salesforce',
      link: 'https://chrome.google.com/webstore/detail/spekit/nbphjjmibhopfgkkbpoggemjnihnchhk?authUser=0&hl=en-US&authuser=0',
    },
    take_it_for_a_spin: {
      img: takeItForSpinDone,
      imgFalse: takeItForSpin,
      imgAlt: 'extension used',
      imgAltFalse: 'use extension',
      heading: 'Take it for a spin',
      subHeading: 'Watch the video below and check it out for yourself.',
      link: 'https://vimeo.com/886940438',
    },
    assign_data_expert: {
      img: delegateDone,
      imgFalse: delegate,
      imgAlt: 'assigned expert',
      imgAltFalse: 'assign expert',
      heading: 'Invite & Assign Experts',
      subHeading: 'Assign Speks to empower others to add their expertise.',
      link: '/app/settings/access',
    },
    edit_definition: {
      img: defineFieldDone,
      imgFalse: defineField,
      imgAlt: 'field defined',
      imgAltFalse: 'define field',
      heading: 'Add definition to a field',
      subHeading: 'Eliminate confusion about the meaning of a complex field.',
      link: '/app/wiki',
    },
    create_business_term: {
      img: knowledgeCardDone,
      imgFalse: knowledgeCard,
      imgAlt: 'created spek',
      imgAltFalse: 'create spek',
      heading: 'Create a spek',
      subHeading: 'For a process or policy that you constantly have to explain.',
      link: '/app/wiki',
    },
    bulk_upload: {
      img: bulkUploadDone,
      imgFalse: bulkUpload,
      imgAlt: 'bulk upload done',
      imgAltFalse: 'bulk upload',
      heading: 'Define an object via Bulk Upload',
      subHeading: 'Hassle-free data transfer, here we come!',
      link: '/app/settings/customize/columns',
    },
  };

  logTakeItForASpin = () => {
    let takeItForASpinProgress = {
      type: 'user_progress',
      data: {
        take_it_for_a_spin: true,
      },
    };
    fetch('/api/events/', {
      method: 'post',

      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(takeItForASpinProgress),
    });
  };

  followLink = () => {
    if (this.resources[this.props.name].link) {
      if (this.props.name === 'take_it_for_a_spin') {
        this.logTakeItForASpin();
      }
      window.location.href = this.resources[this.props.name].link;
    }
  };
  render() {
    let styles = {
      main: {
        display: 'inline-block',
        width: 'calc(100% - 32px)',
        verticalAlign: 'middle',
        cursor:
          !!this.resources[this.props.name] && this.resources[this.props.name].link
            ? 'pointer'
            : null,
      },
      img: {
        height: '80px',
      },
      head: {
        width: '90%',
        color: '#354052',
        fontSize: '16px',
        margin: '16px auto 29px',
      },
      subHead: {
        color: '#9FA9BA',
        fontSize: '12px',
      },
    };
    if (!this.resources[this.props.name]) {
      return null;
    }
    return (
      <div style={styles.main} onClick={this.followLink}>
        <img
          src={
            this.props.value
              ? this.resources[this.props.name].img
              : this.resources[this.props.name].imgFalse
          }
          alt={
            this.props.value
              ? this.resources[this.props.name].imgAlt
              : this.resources[this.props.name].imgAltFalse
          }
          style={styles.img}
        />
        <div style={styles.head}>{this.resources[this.props.name].heading}</div>
        <div style={styles.subHead}>{this.resources[this.props.name].subHeading}</div>
      </div>
    );
  }
}

class Stepswidget extends Component {
  registeredStepKeys = [
    'connect_sf',
    'create_account',
    'chrome_installed',
    'take_it_for_a_spin',
    'assign_data_expert',
    'edit_definition',
    'create_business_term',
    'bulk_upload',
  ];
  constructor(props) {
    super(props);
    this.state = {
      stages: {...props.stages},
      busy: false,
    };
  }
  logInstallationStatus = (installed) => {
    let chromeExtensionProgress = {
      type: 'user_progress',
      data: {
        chrome_installed: installed,
      },
    };
    fetch('/api/events/', {
      method: 'post',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(chromeExtensionProgress),
    });
  };
  checkStagesStatus() {
    if (
      this.allComplete({
        ...this.props.stages,
      }) &&
      typeof this.props.onComplete === 'function'
    ) {
      this.props.onComplete();
    }
  }
  allComplete(stages) {
    return (
      Object.keys(stages)
        .filter((key) => this.registeredStepKeys.includes(key))
        .map((k) => stages[k])
        .filter((stg) => !stg).length === 0 && Object.keys(stages).length > 0
    );
  }

  filterForNonSF = (stages) => {
    return Object.keys(stages)
      .filter((key) => stages[key] !== undefined && this.registeredStepKeys.includes(key))
      .sort((a, b) => {
        if (this.props.stages[b] === true && this.props.stages[a] === false) {
          return 1;
        }
        if (this.props.stages[a] === true && this.props.stages[b] === false) {
          return -1;
        }
        return 0;
      });
  };
  render() {
    let styles = {
      main: {
        padding: '40px',
        textAlign: 'center',
      },
      header: {
        color: '#354052',
        fontSize: '30px',
        fontWeight: '600',
        fontFamily: 'lato',
        marginBottom: '40px',
      },
      subHead: {
        color: '#637280',
        fontSize: '16px',
        fontFamily: 'lato',
        marginBottom: '40px',
      },
      loaderContainer: {
        width: '0px',
        margin: 'auto',
        // height: this.state.loaderHeight,
      },
      stepContainer: {
        display: 'inline-block',
        width: '16%',
      },
      img: {
        height: '80px',
      },
      plus: {
        display: 'inline-block',
        color: '#D0DDEF',
        fontSize: '40px',
        verticalAlign: 'middle',
      },
    };
    let stages = this.props.stages || {};
    this.checkStagesStatus();
    const results = this.filterForNonSF(this.props.stages);
    return (
      <div style={styles.main}>
        <div style={styles.header}>
          {Object.keys(results).length} steps to multiply your productivity
        </div>
        {this.props.package === 'Data Dictionary' ? (
          <div style={styles.subHead}>Complete to unlock your stats</div>
        ) : null}
        <div>
          {results.map((stg, n) => {
            return (
              <div key={stg} style={styles.stepContainer}>
                <StageStep name={stg} value={stages[stg]} />
                {n < Object.keys(results).length - 1 ? (
                  <div style={styles.plus}>+</div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    stages: state.userStages.stages,
  };
})(Stepswidget);
