import React from 'react';
import {MenuList as ChakraMenuList, MenuListProps, Portal} from '@chakra-ui/react';

export type TMenuListProps = MenuListProps & {
  containerRef?: React.MutableRefObject<HTMLElement | null>;
  insidePortal?: boolean;
};

export const MenuList = ({containerRef, insidePortal = true, ...props}: TMenuListProps) =>
  insidePortal ? (
    <Portal containerRef={containerRef}>
      <ChakraMenuList {...props} />
    </Portal>
  ) : (
    <ChakraMenuList {...props} />
  );
