import React from 'react';
import {RiFilterLine} from 'react-icons/ri';
import {IFilter} from 'spekit-types';
import {
  DSButton as Button,
  Icon,
  DSPopover as Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  FormLabel,
  Box,
  Flex,
} from 'spekit-ui';
import {periodFilterOptions, typeFilterOptions} from '../../constants';

interface IProps {
  filters: IFilter;
  onChange: (filter: IFilter) => void;
  onFilter: () => void;
  onReset: () => void;
}

export const FilterPopover = (props: IProps) => {
  const {filters, onChange, onFilter, onReset} = props;

  const handleReset = () => {
    onReset();
  };

  const handleFilter = () => {
    onFilter();
  };

  const filterApplied = filters.type.value !== 'all' || filters.period.value !== 'any';

  return (
    <Popover placement='bottom-end' autoFocus={false} returnFocusOnClose={false}>
      {({isOpen, onClose}) => (
        <>
          <PopoverTrigger>
            <Button
              icon={<Icon as={RiFilterLine} />}
              aria-label='filter button'
              aria-expanded={isOpen}
              colorScheme={filterApplied ? 'primary' : 'outlined'}
              variant='icon'
              size='medium'
            />
          </PopoverTrigger>

          <PopoverContent role='dialog' aria-label='Filter options'>
            <PopoverCloseButton />
            <PopoverBody>
              <Flex direction='column' gap={16}>
                <Box>
                  <FormLabel>Type</FormLabel>
                  <Select
                    blurInputOnSelect={false}
                    placeholder='Select type'
                    options={typeFilterOptions}
                    defaultValue={typeFilterOptions[0]}
                    value={filters.type}
                    onChange={(e) => onChange({...filters, type: e as IFilter['type']})}
                  />
                </Box>
                <Box>
                  <FormLabel>Date modified</FormLabel>
                  <Select
                    blurInputOnSelect={false}
                    defaultValue={periodFilterOptions[0]}
                    placeholder='Select date'
                    options={periodFilterOptions}
                    value={filters.period}
                    onChange={(e) =>
                      onChange({...filters, period: e as IFilter['period']})
                    }
                  />
                </Box>

                <Flex justify='flex-end' gap={8} mt={8}>
                  <Button
                    variant='outlined'
                    size='medium'
                    mr={2}
                    onClick={() => {
                      onClose();
                      handleReset();
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    variant='contained'
                    colorScheme='primary'
                    size='medium'
                    onClick={() => {
                      onClose();
                      handleFilter();
                    }}
                  >
                    Apply Filters
                  </Button>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
