import React, {useState} from 'react';
import styles from './engagements.module.css';
import Pill from '../pill/pill';
import Line from '../line/line';
import LinksDropdown from '../linksDropdown/LinksDropdown';
import DestinationInput from '../destinationInput/DestinationInput';
import SpekInput from '../spekInput/SpekInput';
import TopicInput from '../topicInput/TopicInput';
import QuizInput from '../quizInput/QuizInput';
import {normalizeUrl} from 'spekit-ui/src/utils/url';
import {
  State,
  Dispatch,
  Engagements as EngagementType,
  EngagementsContent,
  Select as SelectType,
  Term,
  Topic,
} from '../types';
import {FlowInput} from '../flowInput';
import {FileInput} from '../fileInput/FileInput';
import {
  Flex,
  FormControl,
  FormLabel,
  Select,
  Box,
  FormHelperText,
  FormErrorMessage,
} from 'spekit-ui';

interface Props {
  state: State;
  dispatch: Dispatch;
  containerRef: React.MutableRefObject<Element>;
}

const getEngagementValue = (content: EngagementsContent): Term => {
  if (content.object) return content.object;
  if (content.field) return content.field;
  if (content.field_value) return content.field_value;
  if (content.business_term) return content.business_term;
  if (content.file) return content.file;
  return {
    term_type: 'business_term',
    value: '',
    label: '',
  };
};

const Engagements: React.FC<Props> = ({state, dispatch, containerRef}) => {
  const actionType = state.actionType;
  const hasSpotlightsRevampFlag = state.flag?.hasSpotlightsRevampFlag;
  const dispatchSetErrors = (url: string | undefined) => {
    dispatch({
      type: 'SET_ERRORS',
      payload: {
        engagementUrl: true,
        engagementUrlText: url
          ? 'Must be a valid URL (https://www.example.com)'
          : 'Enter destination URL',
      },
    });
  };

  const handleActionType = (option: SelectType) => {
    dispatch({type: 'SET_ACTION_TYPE', payload: option});
    dispatch({type: 'SET_ACTION_TYPE_ERROR', payload: false});
    dispatch({type: 'SET_SPEKIT_RESOURCE_ERROR', payload: false});

    if (['url', 'acknowledge-and-close'].includes(option.value)) {
      handleLinkSelect(option);
    } else {
      dispatch({
        type: 'SET_ENGAGEMENTS',
        payload: {
          label: '',
          type: '',
          content: {},
        },
      });
    }
  };

  const handleLinkSelect = (option: SelectType) => {
    let engagements = {...state.engagements};
    engagements.type = option.value;
    engagements.content = {};
    engagements.label = {
      url: 'Open',
      term: 'View Spek',
      file: 'View File',
      topic: 'View topic',
      quiz: 'Begin',
      flow: 'Go to Flow',
      'acknowledge-and-close': 'Got it',
    }[option.value]!;
    dispatch({type: 'SET_ENGAGEMENTS', payload: engagements});
    dispatch({type: 'SET_SPEKIT_RESOURCE_ERROR', payload: false});
    dispatch({
      type: 'SET_ERRORS',
      payload: {engagementUrl: false, engagementUrlText: ''},
    });
  };

  const handleLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    let engagements = {...state.engagements};
    engagements.content.url = e.target.value;
    dispatch({type: 'SET_ENGAGEMENTS', payload: engagements});
    if (e.target.value) {
      dispatch({
        type: 'SET_ERRORS',
        payload: {engagementUrl: false, engagementUrlText: ''},
      });
    }
  };

  const handleUrlBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      dispatchSetErrors(state.engagements.content.url);
      return;
    }

    try {
      let {engagements} = state;
      const url = normalizeUrl(engagements.content.url || '');
      engagements = {...engagements, content: {...engagements.content, url}};
    } catch (error) {
      dispatchSetErrors(state.engagements.content.url);
    }
  };

  const handleLabelBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      let engagements = {...state.engagements};
    } else {
      dispatch({type: 'SET_LABEL_ERROR', payload: true});
    }
  };

  const handleSpek = (option: Term) => {
    let engagements = {...state.engagements};
    engagements.content = {
      id: engagements.content.id,
      [option.term_type]: option,
    };
    engagements.content[option.term_type] = option;
    dispatch({type: 'SET_ENGAGEMENTS', payload: engagements});
  };

  const handleTopic = (option: Topic) => {
    let engagements = {...state.engagements};
    engagements.content.topic = option;
    dispatch({type: 'SET_ENGAGEMENTS', payload: engagements});
  };

  const handleQuiz = (option: SelectType) => {
    let engagements = {...state.engagements};
    engagements.content.quiz = option;
    dispatch({type: 'SET_ENGAGEMENTS', payload: engagements});
  };

  const handleFlow = (option: SelectType) => {
    let engagements = {...state.engagements};
    engagements.content.flow = option;
    dispatch({type: 'SET_ENGAGEMENTS', payload: engagements});
  };

  const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let engagements = {...state.engagements};
    engagements.label = e.target.value;
    dispatch({type: 'SET_ENGAGEMENTS', payload: engagements});
  };

  return (
    <>
      {hasSpotlightsRevampFlag ? (
        <Flex flexDirection='column'>
          <Box flex={1}>
            <FormControl
              isRequired
              isInvalid={state.actionTypeError}
              data-test-id='select-button-action'
            >
              <FormLabel>Button action</FormLabel>

              <Select
                options={[
                  {
                    label: 'Go to Spekit resource',
                    value: 'spekit-resource',
                  },
                  {
                    label: 'Go to URL',
                    value: 'url',
                  },
                  {
                    label: 'Acknowledge and close',
                    value: 'acknowledge-and-close',
                  },
                ]}
                value={actionType}
                onChange={(option: any) => {
                  handleActionType(option);
                }}
                placeholder='Select button action'
                isDisabled={state.read_mode}
              />

              {state.engagementContentError && (
                <FormErrorMessage data-testid='button-action-error-message'>
                  Button action required.
                </FormErrorMessage>
              )}
            </FormControl>
          </Box>

          {actionType?.value === 'spekit-resource' && (
            <Box ml={24} mt={12}>
              <FormControl
                isRequired
                isInvalid={state.spekitResourceError}
                data-test-id='select-spekit-resource-type'
              >
                <FormLabel>Spekit resource type</FormLabel>
                <LinksDropdown
                  monitorScrollElement={containerRef}
                  onSelect={handleLinkSelect}
                  value={state.engagements.type}
                  state={state}
                />

                {state.spekitResourceError && (
                  <FormErrorMessage>Select Spekit resource type.</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          )}

          {state.engagements.type === 'term' && (
            <Box ml={24} mt={12}>
              <FormControl
                isRequired
                isInvalid={state.engagementContentError}
                data-test-id='select-spek'
              >
                <FormLabel>Spek</FormLabel>
                <SpekInput
                  value={getEngagementValue(state.engagements.content)}
                  onSelect={handleSpek}
                  teams={state.teams}
                  monitorScrollElement={containerRef}
                  state={state}
                />
                <FormHelperText>
                  Only displaying Speks your selected audience has access to view.
                </FormHelperText>

                {state.engagementContentError && (
                  <FormErrorMessage>Select a Spek.</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          )}

          {state.engagements.type === 'file' && (
            <Box ml={24} mt={12}>
              <FormControl
                isRequired
                isInvalid={state.engagementContentError}
                data-test-id='select-filen'
              >
                <FormLabel>File</FormLabel>

                <FileInput
                  onSelect={handleSpek}
                  value={getEngagementValue(state.engagements.content)}
                  teams={state.teams.map((team: any) => team.value)}
                  isDisabled={state.read_mode}
                />
                <FormHelperText>
                  Only displaying Files your selected audience has access to view.
                </FormHelperText>

                {state.engagementContentError && (
                  <FormErrorMessage>Select a File.</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          )}

          {state.engagements.type === 'topic' && (
            <Box ml={24} mt={12}>
              <FormControl
                isRequired
                isInvalid={state.engagementContentError}
                data-test-id='select-topic'
              >
                <FormLabel>Topic</FormLabel>

                <TopicInput
                  value={state.engagements.content.topic!}
                  onSelect={handleTopic}
                  teams={state.teams}
                  monitorScrollElement={containerRef}
                  state={state}
                />
                <FormHelperText>
                  Only displaying Topics your selected audience has access to view.
                </FormHelperText>

                {state.engagementContentError && (
                  <FormErrorMessage>Select a Topic.</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          )}

          {state.engagements.type === 'flow' && (
            <Box ml={24} mt={12}>
              <FormControl
                isRequired
                isInvalid={state.engagementContentError}
                data-test-id='select-flow'
              >
                <FormLabel>Flow</FormLabel>

                <FlowInput
                  value={state.engagements.content.flow!}
                  onSelect={handleFlow}
                  teams={state.teams}
                  monitorScrollElement={containerRef}
                  state={state}
                />
                <FormHelperText>
                  Only displaying Flows your selected audience has access to view.
                </FormHelperText>

                {state.engagementContentError && (
                  <FormErrorMessage>Select a Flow.</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          )}

          {state.engagements.type === 'quiz' && (
            <Box ml={24} mt={12}>
              <FormControl
                isRequired
                isInvalid={state.engagementContentError}
                data-test-id='select-knowledge-check'
              >
                <FormLabel>Knowledge Check</FormLabel>

                <QuizInput
                  value={state.engagements.content.quiz!}
                  onSelect={handleQuiz}
                  teams={state.teams}
                  monitorScrollElement={containerRef}
                  state={state}
                />
                <FormHelperText>
                  Only displaying Knowledge Checks your selected audience has access to
                  view.
                </FormHelperText>

                {state.engagementContentError && (
                  <FormErrorMessage>Select a Knowledge Check.</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          )}

          {actionType?.value === 'url' && (
            <Box ml={24} mt={12}>
              <DestinationInput
                value={state.engagements.content.url!}
                onChange={handleLabel}
                label='URL'
                placeholder='https://'
                onBlur={handleUrlBlur}
                disabled={state.read_mode}
                error={state.errors.engagementUrl}
                errorText={state.errors.engagementUrlText}
                helperText='URL must begin with "http://" or "https://"'
                hasSpotlightsRevampFlag={hasSpotlightsRevampFlag}
                formControlDataTestId='url-input'
              />
            </Box>
          )}

          <Box mt={16}>
            <DestinationInput
              value={state.engagements.label}
              error={state.engagementLabelError}
              errorText={'Button text required.'}
              onChange={onLabelChange}
              label='Button text'
              disabled={state.read_mode}
              onBlur={handleLabelBlur}
              placeholder='Enter button text'
              maxLength={20}
              hasSpotlightsRevampFlag={hasSpotlightsRevampFlag}
              formControlDataTestId='button-text-input'
            />
          </Box>
        </Flex>
      ) : (
        <div
          className={
            state.engagements.type !== 'acknowledge-and-close'
              ? styles.mainUrl
              : styles.mainAck
          }
        >
          <div className={styles.button}>
            <Pill>Button</Pill>
          </div>
          <div className={styles.hline}>
            <Line orientation='horizontal' />
          </div>
          <div className={styles.vline}>
            <Line orientation='vertical' />
          </div>
          <div className={styles.label}>
            <Pill>label</Pill>
          </div>
          <div className={styles.hbline}>
            <Line orientation='horizontal' />
          </div>

          <div className={styles.dropdown}>
            <LinksDropdown
              monitorScrollElement={containerRef}
              onSelect={handleLinkSelect}
              value={state.engagements.type}
              state={state}
            />
          </div>
          {state.engagements.type === 'url' ? (
            <>
              <div className={styles.h2line}>
                <Line orientation='horizontal' />
              </div>
              <div className={styles.destination}>
                <DestinationInput
                  value={state.engagements.content.url!}
                  onChange={handleLabel}
                  label='Destination'
                  placeholder='Enter URL'
                  onBlur={handleUrlBlur}
                  disabled={state.read_mode}
                  error={state.errors.engagementUrl}
                  errorText={state.errors.engagementUrlText}
                />
              </div>
            </>
          ) : null}
          {state.engagements.type === 'term' ? (
            <>
              <div className={styles.h2line}>
                <Line orientation='horizontal' />
              </div>
              <div className={styles.spekDestination}>
                <SpekInput
                  value={getEngagementValue(state.engagements.content)}
                  onSelect={handleSpek}
                  teams={state.teams}
                  monitorScrollElement={containerRef}
                  state={state}
                />
              </div>
            </>
          ) : null}

          {state.engagements.type === 'file' && (
            <>
              <div className={styles.h2line}>
                <Line orientation='horizontal' />
              </div>
              <div className={styles.spekDestination}>
                <FileInput
                  onSelect={handleSpek}
                  value={getEngagementValue(state.engagements.content)}
                  teams={state.teams.map((team) => team.value)}
                  isDisabled={state.read_mode}
                />
              </div>
            </>
          )}

          {state.engagements.type === 'topic' ? (
            <>
              <div className={styles.h2line}>
                <Line orientation='horizontal' />
              </div>
              <div className={styles.spekDestination}>
                <TopicInput
                  value={state.engagements.content.topic!}
                  onSelect={handleTopic}
                  teams={state.teams}
                  monitorScrollElement={containerRef}
                  state={state}
                />
              </div>
            </>
          ) : null}
          {state.engagements.type === 'quiz' ? (
            <>
              <div className={styles.h2line}>
                <Line orientation='horizontal' />
              </div>
              <div className={styles.spekDestination}>
                <QuizInput
                  value={state.engagements.content.quiz!}
                  onSelect={handleQuiz}
                  teams={state.teams}
                  monitorScrollElement={containerRef}
                  state={state}
                />
              </div>
            </>
          ) : null}
          {state.engagements.type === 'flow' && (
            <>
              <div className={styles.h2line}>
                <Line orientation='horizontal' />
              </div>
              <div className={styles.spekDestination}>
                <FlowInput
                  value={state.engagements.content.flow!}
                  onSelect={handleFlow}
                  teams={state.teams}
                  monitorScrollElement={containerRef}
                  state={state}
                />
              </div>
            </>
          )}
          <div className={styles.labelInput}>
            <DestinationInput
              value={state.engagements.label}
              error={state.engagementLabelError}
              errorText={'Enter button label'}
              onChange={onLabelChange}
              label='Button label'
              disabled={state.read_mode}
              onBlur={handleLabelBlur}
              placeholder='Enter button label'
              maxLength={20}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Engagements;
