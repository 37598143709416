import {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {
  Fontawesome,
  notifications,
  DataTable,
  DSButton as Button,
  DSTooltip as Tooltip,
  TabSelectInput,
  Icon,
} from 'spekit-ui';
import {FaArrowRight} from 'react-icons/fa';
import {connect} from 'react-redux';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {utils, logging, subDays, format, fetchMiddleWare} from 'spekit-datalayer';
import EmptyState from './images/most_viewed_empty_state.svg';

const fetch = fetchMiddleWare.fetchMiddleWare;
const {notify} = notifications;

class MostViewedWidget extends Component {
  state = {
    mostViewedTerms: [],
    selectedTab: 'Last 30 days',
    busy: true,
    textHover: false,
    termHovered: null,
  };
  componentDidMount() {
    this.getTerms();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.teamId !== this.props.teamId) {
      this.getTerms();
    }
  }
  handleTabChange = (selectedTab) => {
    this.setState({selectedTab}, this.getTerms);
  };
  getTerms = () => {
    let start = null;
    if (this.state.selectedTab === 'Last 30 days') {
      let last30Days = format(subDays(Date.now(), 30), 'yyyy-MM-dd');
      start = last30Days;
    }
    let params = {
      type: 'most_viewed',
      teams: this.props.teamId,
      start: start,
    };
    let queryString = utils.stringifyQs(params, {skipNulls: true});
    let url = '/api/stats?' + queryString;
    this.setState({busy: true});

    fetch(url, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success !== false) {
          this.setState({mostViewedTerms: result.terms || [], busy: false});
        } else {
          throw new Error('Could not get most viewed terms');
        }
      })
      .catch((err) => {
        if (err.message === 'Could not get most viewed terms') {
          notify({error: true, text: err.message});
        } else {
          logging.capture(err);

          notify({error: true, text: 'An unknown error occurred'});
        }
        this.setState({busy: false});
      });
  };

  render() {
    let styles = {
      main: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      mostViewedHeading: {
        fontSize: '20px',
        fontWeight: '500',
        verticalAlign: 'top',
        lineHeight: '36px',
      },
      tabContainer: {
        width: '200px',
        display: 'inline-block',
      },
      headingArrow: {
        color: 'rgba(99,114,128,0.42)',
        marginLeft: '0.5em',
      },
      count: {
        fontSize: '22px',
        fontWeight: '300',
      },
      cardTitleHeading: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#637280',
        textDecoration: 'none',
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      cardTitleDescription: {
        fontSize: '14px',
        color: '#637280',
      },
      dataExpert: {
        fontWeight: '600',
        color: '#637280',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      emptyStateContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '415px',
      },
      emptyStateImg: {
        marginBottom: '28px',
      },
      emptyStateFirstLine: {
        fontSize: '20px',
        color: '#4A4A4A',
        fontWeight: 'bold',
        lineHeight: '30px',
      },
      emptyStateSecondLine: {
        fontSize: '14px',
        color: '#4A4A4A',
        lineHeight: '30px',
        fontWeight: '300',
      },
    };
    let teamsParameter = this.props.teamId !== null ? '&teams=' + this.props.teamId : '';
    let viewAllUrl;
    if (
      this.props.wikiStoredState.uiMode === 'data_dictionary' ||
      this.props.me.company.salesforceorganizations_set[0].instance_url === 'dummy'
    ) {
      viewAllUrl =
        '/app/wiki/?type=all&ordering=-views.all_time_with_deleted_users' +
        teamsParameter;
    } else {
      viewAllUrl =
        '/app/wiki/?type=object,field_value,business_term&ordering=-views.all_time_with_deleted_users' +
        teamsParameter;
    }
    return (
      <Fragment>
        <div style={styles.main}>
          <div style={styles.mostViewedHeading}>
            Most viewed
            <Tooltip label='Number of total views per Spek.' shouldWrapChildren>
              <Fontawesome style={styles.headingArrow} name={faInfoCircle} />
            </Tooltip>
          </div>
          <Link to={viewAllUrl} style={{textDecoration: 'none', float: 'right'}}>
            <Button
              colorScheme='primary'
              variant='contained'
              size='medium'
              rightIcon={<Icon as={FaArrowRight} />}
              data-testid='most-viewed-view-all'
            >
              View all
            </Button>
          </Link>
          <div style={styles.tabContainer}>
            <TabSelectInput
              value={this.state.selectedTab}
              options={['Last 30 days', 'All time']}
              onChange={this.handleTabChange}
            />
          </div>
          {this.state.mostViewedTerms.length ? (
            <DataTable
              columns={[
                {
                  fieldName: 'views',
                  displayName: '# of views',
                  noSort: true,
                  width: '20%',
                  template: (c) => {
                    return <div style={styles.count}>{c}</div>;
                  },
                },
                {
                  displayName: 'label',
                  noSort: true,
                  width: '55%',
                  template: (c) => {
                    return (
                      <Fragment>
                        <div>
                          <Link
                            style={styles.cardTitleHeading}
                            to={
                              '/app/wiki/' +
                              c.type +
                              '/' +
                              c.id +
                              '?expanded=true&type=' +
                              c.type
                            }
                          >
                            <span
                              style={{
                                color:
                                  this.state.textHover &&
                                  this.state.termHovered === c.label
                                    ? '#3576E8'
                                    : '#637280',
                              }}
                              onMouseEnter={() =>
                                this.setState({
                                  textHover: true,
                                  termHovered: c.label,
                                })
                              }
                              onMouseLeave={() => this.setState({textHover: false})}
                            >
                              {c.label}
                            </span>
                          </Link>
                        </div>
                        <div style={styles.cardTitleDescription}>
                          {c.type === 'object'
                            ? 'Object'
                            : c.type === 'field'
                            ? 'Field On ' + c.parent.label
                            : c.type === 'field_value'
                            ? 'Picklist Value On ' +
                              c.parent.label +
                              ' On ' +
                              c.parent_object.label
                            : c.type === 'business_term'
                            ? 'Spek'
                            : null}
                        </div>
                      </Fragment>
                    );
                  },
                },
                {
                  fieldName: 'data_expert',
                  displayName: 'Expert',
                  noSort: true,
                  width: '25%',
                  template: (de) => {
                    return (
                      <div style={styles.dataExpert}>
                        {!de ? 'Assign an Expert' : de.first_name + ' ' + de.last_name}
                      </div>
                    );
                  },
                },
              ]}
              busy={this.state.busy}
              data={this.state.mostViewedTerms}
              transparent
            />
          ) : null}
        </div>
        {!this.state.mostViewedTerms.length ? (
          <div style={styles.emptyStateContainer}>
            <img src={EmptyState} alt='empty' style={styles.emptyStateImg} />
            <div style={styles.emptyStateFirstLine}>Not enough views to display</div>
            <div style={styles.emptyStateSecondLine}>
              Keep track of what your team is viewing.
            </div>
            <div style={styles.emptyStateSecondLine}>
              Open an out-of-the-box Spek to see this updated.
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default connect((state, props) => {
  return {
    me: state.layout.me,
    wikiStoredState: state.wikiStoredState.storedState,
  };
})(MostViewedWidget);
