import React, {useState} from 'react';
import {RiSearchLine, RiCloseLine} from 'react-icons/ri';
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormControl,
  FormLabel,
} from '../../Form';
import {Tooltip} from '../../Tooltip';
import {Flex, TFlexProps, Box} from '../../Layout';
import debounce from 'debounce-promise';
import {Button} from '../../Button';
import {Icon} from '../../Icon';
import {ICONS} from '../../../constants';

interface ISearchBarIconElement {
  tooltipLabel: string;
  children: React.ReactNode;
}

type TSearchBarIconElementProps = ISearchBarIconElement & TFlexProps;

export interface ISearchBarProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  onSearch?: () => void;
  placeholder?: string;
  testId?: string;
  autoFocus?: boolean;
  suggestions?: React.ReactNode;
  handleAIAssist?: () => void;
  isDisabled?: boolean;
  width?: string;
}

const SearchBarIconElement = (props: TSearchBarIconElementProps) => {
  const {tooltipLabel, children, ...rest} = props;
  return (
    <Tooltip label={tooltipLabel} placement='bottom'>
      <Flex
        align='center'
        justify='center'
        as='span'
        w='20px'
        h='20px'
        p={4}
        cursor='pointer'
        _hover={{bg: 'neutral.25', borderRadius: 4}}
        _focusVisible={{
          border: '1px solid',
          borderColor: 'neutral.700',
          outline: 'none',
          borderRadius: 4,
          backgroundColor: 'neutral.25',
        }}
        {...rest}
      >
        {children}
      </Flex>
    </Tooltip>
  );
};

export const SearchBar = (props: ISearchBarProps) => {
  const {
    value,
    onChange,
    onClear,
    onSearch,
    placeholder = 'Search',
    testId = 'universal-search-bar',
    autoFocus,
    suggestions,
    handleAIAssist,
    isDisabled = false,
    width = '387px',
  } = props;

  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSearch) {
      setShowSuggestions(false);
      onSearch();
    }
  };

  return (
    <Box w={width} position='relative' display={'flex'} gap={10}>
      <FormControl isDisabled={isDisabled}>
        <InputGroup size='sm'>
          <FormLabel
            position='absolute'
            p={0}
            border={0}
            height='1px'
            width='1px'
            overflow='hidden'
          >
            Search Spekit
          </FormLabel>
          <Input
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyDown={handleSearch}
            onFocus={() => setShowSuggestions(true)}
            onBlur={debounce(() => setShowSuggestions(false), 200)}
            pe={38}
            ps={38}
            fontSize='small'
            data-testid={testId}
            autoFocus={autoFocus}
          />
          <InputLeftElement
            pl={6}
            fontSize='large'
            data-testid='search-icon-button'
            pointerEvents={isDisabled ? 'none' : 'auto'}
          >
            <SearchBarIconElement
              tabIndex={0}
              role='button'
              aria-label='Search'
              tooltipLabel='Search'
              color='neutral.700'
              onClick={onSearch}
            >
              <RiSearchLine />
            </SearchBarIconElement>
          </InputLeftElement>
          {value && (
            <InputRightElement
              pr={6}
              data-testid='search-close-button'
              fontSize='large'
              pointerEvents={isDisabled ? 'none' : 'auto'}
            >
              <SearchBarIconElement
                tabIndex={0}
                role='button'
                aria-label='Clear search'
                tooltipLabel='Clear search'
                color='neutral.500'
                data-testid='clear-search-button'
                onClick={onClear}
              >
                <RiCloseLine />
              </SearchBarIconElement>
            </InputRightElement>
          )}
        </InputGroup>
        {showSuggestions && suggestions}
      </FormControl>
      {handleAIAssist && (
        <Button
          borderRadius={8}
          aria-label={`Open Spekit AI Assist`}
          tooltipLabel='AI Assist'
          colorScheme={'transparent'}
          border='none'
          icon={<Icon as={ICONS['aiAssistColor']} />}
          onClick={handleAIAssist}
          size={'medium'}
          variant='icon'
          padding={8}
          position={'relative'}
          sx={{
            position: 'relative',
            '&:before': {
              content: "''",
              position: 'absolute',
              inset: 0,
              borderRadius: 'var(--spekit-space-8)',
              border: '1px solid transparent',
              background:
                'linear-gradient(135deg, #a6fffa 0%, #0087ff 35%, #7e6afd 66%, #f14ffb 100%) border-box',
              mask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
              maskComposite: 'exclude',
            },
          }}
        />
      )}
    </Box>
  );
};
