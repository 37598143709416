import React from 'react';
import {capitalizeFirstAndLowercaseRest} from 'spekit-datalayer/src/utils/commonUtils';
import {ICustomFieldMultiOptions, IOptionType, TComputedValue} from 'spekit-types';
import {Select} from 'spekit-ui';

interface IMultiOptionField {
  field: ICustomFieldMultiOptions;
  value: IOptionType[];
  handleUpdate: (id: string, values: TComputedValue) => void;
}

export const MultiOptionField = ({field, value, handleUpdate}: IMultiOptionField) => {
  const {options, id, name} = field;
  const selectableOptions = options.map((option) => ({
    label: option.name,
    value: option.id,
  }));
  return (
    <Select
      isMulti
      options={selectableOptions}
      value={value}
      onChange={(newValue) => handleUpdate(id, newValue as IOptionType[])}
      placeholder={`Enter ${capitalizeFirstAndLowercaseRest(name)}`}
    />
  );
};
