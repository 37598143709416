import React, {useEffect, useState} from 'react';
import {IDrive} from 'spekit-types';
import {Box, Flex, SearchBar, SimpleGrid, Spinner, Text} from 'spekit-ui';
import {DriveCard} from './DriveCard';
import {NoResults} from '../NoResults';
import {SearchText} from './SearchText';
import {IContentStore} from '../../constants';

interface IProps {
  selectedDrive?: IDrive;
  isLoading?: boolean;
  drives?: IDrive[];
  onClick: (drive: IDrive) => void;
  store?: IContentStore;
  activeTab: number;
}

export const DriveSelector = ({
  drives = [],
  onClick,
  selectedDrive,
  isLoading,
  store,
  activeTab,
}: IProps) => {
  const [inputValue, setInputValue] = useState('');
  const [submittedSearch, setSubmittedSearch] = useState('');
  const [filteredDrives, setFilteredDrives] = useState(drives);

  const placeholder =
    store?.connectionKey === 'gdrive'
      ? 'Search for Google Drives'
      : 'Search for SharePoint Sites';

  const handleSearch = () => {
    const matchingDrives = drives.filter((drive) =>
      drive.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredDrives(matchingDrives);
    setSubmittedSearch(inputValue);
  };

  const handleSearchClear = () => {
    setInputValue('');
    setSubmittedSearch('');
    setFilteredDrives(drives);
  };

  useEffect(() => {
    if (!submittedSearch) {
      setFilteredDrives(drives);
    }
  }, [drives]);

  useEffect(() => {
    setInputValue('');
    setSubmittedSearch('');
    setFilteredDrives(drives);
  }, [activeTab]);

  if (isLoading) {
    return (
      <Flex mt={10} align='center' justify='center' direction='column' gap={4}>
        <Spinner
          color='primary.500'
          emptyColor='neutral.200'
          size='md'
          speed='1.65s'
          thickness='4px'
          data-testid='drive-spinner'
        />
        <Text variant='body2' color='neutral.500'>
          Loading...
        </Text>
      </Flex>
    );
  }

  return (
    <Flex direction='column' flex='1'>
      <Flex justify='space-between' align='center' my={24}>
        {submittedSearch && (
          <SearchText drives={filteredDrives} searchTerm={submittedSearch} />
        )}
        <Box ml='auto'>
          <SearchBar
            testId='drive-search-bar'
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onClear={handleSearchClear}
            onSearch={handleSearch}
            placeholder={placeholder}
          />
        </Box>
      </Flex>

      {filteredDrives.length === 0 && submittedSearch && (
        <NoResults handleSearchClear={handleSearchClear} />
      )}

      {filteredDrives.length === 0 && !submittedSearch && (
        <Text variant='caption1' fontWeight={400} mt={4} color='neutral.600'>
          No drives available to sync. Check your connection settings and try again.
        </Text>
      )}

      {filteredDrives.length > 0 && (
        <SimpleGrid columns={2} spacing={10}>
          {filteredDrives.map((drive) => (
            <DriveCard
              store={store}
              key={drive.id}
              drive={drive}
              onClick={onClick}
              isSelected={selectedDrive?.id === drive.id}
            />
          ))}
        </SimpleGrid>
      )}
    </Flex>
  );
};
