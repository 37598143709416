import * as React from 'react';
import {Button, DSTooltip as Tooltip, Fontawesome} from 'spekit-ui';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
interface Props {
  heading: string;
  toolTipText: string;
  description: string;
  subDescription: string;
  buttonText?: string;
  tileImage: React.FC<React.SVGProps<SVGSVGElement>>;
  testid: string;
  handleCardAction: () => void;
}

const Card: React.FC<Props> = ({
  heading,
  toolTipText,
  description,
  subDescription,
  buttonText,
  tileImage: TileImage,
  testid,
  handleCardAction,
}) => {
  let styles = {
    main: {
      border: '1px solid #DDD',
      backgroundColor: '#FFF',
      padding: '16px 20px',
    },
    headingContainer: {
      marginBottom: '10px',
    },
    heading: {
      color: '#607281',
      fontSize: '22px',
      letterSpacing: '0.17px',
    },
    icon: {
      color: 'rgba(99,114,128,0.42)',
      marginLeft: '0.5em',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '200px 1fr',
      gridColumnGap: '20px',
    },
    imageContainer: {
      width: '200px',
      height: '200px',
      backgroundColor: '#A7E6F5',
      position: 'relative',
    } as any,
    todoImage: {
      width: '175px',
      height: '175px',
      pointerEvents: 'none',
      position: 'absolute',
      bottom: '-2px',
      right: '0',
    } as any,
    recentNotificationsImage: {
      width: '175px',
      height: '175px',
      pointerEvents: 'none',
      position: 'absolute',
      bottom: '-2px',
      right: '-5px',
    } as any,
    recentReactionsImage: {
      width: '175px',
      position: 'absolute',
      height: '175px',
      right: '0',
      pointerEvents: 'none',
      top: '12px',
    } as any,
    mostViewedImage: {
      width: '175px',
      height: '175px',
      pointerEvents: 'none',
      bottom: '-18px',
      position: 'absolute',
      right: '-1px',
    } as any,
    mostReactedImage: {
      width: '175px',
      height: '175px',
      pointerEvents: 'none',
      position: 'absolute',
      top: '1px',
      right: '-7px',
    } as any,
    defaultImage: {
      width: '175px',
      height: '175px',
      pointerEvents: 'none',
      position: 'absolute',
      top: '26px',
      right: '-1px',
    } as any,
    flex: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'flex-start',
    } as any,
    description: {
      color: '#323232',
      fontSize: '18px',
      fontWeight: 'bold',
      letterSpacing: '-0.18px',
    } as any,
    subDescription: {
      color: '#56606A',
      fontSize: '14px',
      letterSpacing: '0.1px',
    } as any,
    button: {
      borderRadius: '24px',
      height: '32px',
      padding: '9px 20px',
    },
  };
  return (
    <div style={styles.main}>
      <div style={styles.headingContainer}>
        <span style={styles.heading}>{heading}</span>
        <Tooltip label={toolTipText} shouldWrapChildren>
          <Fontawesome name={faInfoCircle} style={styles.icon} />
        </Tooltip>
      </div>
      <div style={styles.grid}>
        <div style={styles.imageContainer}>
          {
            {
              [heading]: <TileImage style={styles.defaultImage} />,
              'My to-do list': <TileImage style={styles.todoImage} />,
              'Recent Notifications': (
                <TileImage style={styles.recentNotificationsImage} />
              ),
              'Recent Reactions': <TileImage style={styles.recentReactionsImage} />,
              'Most Viewed': <TileImage style={styles.mostViewedImage} />,
              'Most Reacted': <TileImage style={styles.mostReactedImage} />,
            }[heading]
          }
        </div>
        <div style={styles.flex}>
          <div style={styles.description}>{description}</div>
          <div style={styles.subDescription}>{subDescription}</div>
          {buttonText ? (
            <Button style={styles.button} onClick={handleCardAction} data-testid={testid}>
              {buttonText}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Card;
