import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getStages, logging} from 'spekit-datalayer';
import {Stages} from 'spekit-datalayer/src/dashboard/stages';
import Dashboard from './dashboard';
import FirstTimeDashboard from './firstTimeDashboard';

interface ComponentProps {}

const DashboardSelector = (props: ComponentProps) => {
  const {stages, role, kcFlag} = useSelector((state: any) => ({
    stages: state.userStages.stages,
    role: state.layout.me ? state.layout.me.role : null,
    kcFlag: state.flag.hasQuizFlag,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!stages && role) {
      getStages()
        .then((stages: Stages) => {
          let userRole = role !== 'Super Admin' ? role : 'Account Admin';
          let userStages = stages[userRole];
          dispatch({type: 'SET_USER_STAGES', userStages});
        })
        .catch((e) => logging.capture(e));
    }
  }, [dispatch, stages, role]);

  if (!stages || !role) {
    return null;
  }

  if (kcFlag && (role.includes('Admin') || role.includes('Expert'))) {
    return <Dashboard />;
  }

  return (stages && !!stages.create_business_term) || role === 'Viewer' ? (
    <Dashboard />
  ) : (
    <FirstTimeDashboard />
  );
};

export default DashboardSelector;
