import React from 'react';
import {IOptionType} from 'spekit-types';
import {
  AsyncSelect,
  Flex,
  Switch,
  Text,
  Icon,
  DSTooltip as Tooltip,
  SelectComponents,
} from 'spekit-ui';
import {team} from 'spekit-datalayer';
import {RiInformationLine} from 'react-icons/ri';
import {hasSomeOf} from '../../../../utils/permissions';
import {ActionMeta} from 'react-select';

import debounce from 'debounce-promise';

interface ITeamPicker {
  allTeams: boolean;
  setAllTeams: (allTeams: boolean) => void;
  selectedTeams: ITeamOption[];
  setSelectedTeams: (teams: ITeamOption[]) => void;
}

interface ITeamOption extends IOptionType {
  isFixed?: boolean;
}

export const TeamPicker = ({
  allTeams,
  selectedTeams,
  setAllTeams,
  setSelectedTeams,
}: ITeamPicker) => {
  const canEditAllTeams = hasSomeOf(['teams.update_all']);

  const getTeamOptions = async (search = '') => {
    const teamSuggestions = await team.getAssignableTeamSuggestions(search);
    return teamSuggestions.map((item) => ({
      value: item.value,
      label: item.label,
    }));
  };

  const handleChange = (newValue: ITeamOption[], actionMeta: ActionMeta<ITeamOption>) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue?.isFixed) {
          return;
        }
        break;
      case 'clear':
        newValue = selectedTeams.filter((v: any) => v.isFixed);
        break;
    }
    setSelectedTeams(newValue);
  };

  return (
    <Flex direction='column' gap={10} mt={20}>
      {canEditAllTeams && (
        <Flex gap={6} data-testid='all-teams-checkbox' alignItems='center'>
          <Switch isChecked={allTeams} onChange={(e) => setAllTeams(e.target.checked)} />
          <Text variant='body2'>Visible to all teams </Text>
          <Tooltip label='Includes all current and future teams'>
            <Flex justifyContent='center' alignItems='center'>
              <Icon as={RiInformationLine} />
            </Flex>
          </Tooltip>
        </Flex>
      )}
      {!allTeams && (
        <Flex direction='column' gap={6}>
          <Text variant='body2'>Teams</Text>
          <AsyncSelect
            inputId='team-picker'
            isMulti
            value={selectedTeams}
            loadOptions={debounce(getTeamOptions, 200)}
            onChange={handleChange}
            cacheOptions
            defaultOptions
            placeholder='Select teams'
            components={SelectComponents('You dont have access to this team')}
          />
        </Flex>
      )}
    </Flex>
  );
};
