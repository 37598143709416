import {checkTaskStatus} from './task/checkStatusTask';
import {retrieve} from './utils/APIHelpers';

interface SalesforceResyncResponse {
  task_id: string;
  success: boolean;
}

export const objectsResync = async (id: string): Promise<SalesforceResyncResponse> => {
  const response: SalesforceResyncResponse = await retrieve(
    `/api/resync/${id}/objects?initial_partial_sync=true`
  );
  await checkTaskStatus(response.task_id);
  return response;
};
