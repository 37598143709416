import {useHistory} from 'react-router-dom';
import GreetingWidget from '../greetingWidget';
import Card from './card';
import {ReactComponent as Todo} from '../images/todo_ftux.svg';
import {ReactComponent as RecentNotification} from '../images/recent_notification_ftux.svg';
import {ReactComponent as RecentReactions} from '../images/recent_reactions_ftux.svg';
import {ReactComponent as MostViewed} from '../images/most_viewed_ftux.svg';
import {ReactComponent as MostReacted} from '../images/most_reacted_ftux.svg';
import {useSelector} from 'react-redux';

const FirstTimeDashboard = () => {
  const me = useSelector((state: any) => state.layout.me);
  const history = useHistory();
  let styles = {
    greetingContainer: {
      margin: '24px',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(550px, 1fr))',
      gridTemplateRows: 'repeat(2, 300px)',
      gridColumnGap: '20px',
      gridRowGap: '20px',
      margin: '24px',
    },
  };

  const routeByRoleHandler = () => {
    if (me.role === 'Expert') {
      return '/app/wiki/topics?appcue=-M4lAj0RY6LbEad1Atu_';
    }
    if (me.role === 'Team Admin') {
      return '/app/settings/access/users';
    }
    return '/app/settings/access?appcue=-M4hAvpYNFiMwILhU7hJ';
  };

  const handleRedirect = () => {
    let route = routeByRoleHandler();
    history.push(route);
  };

  return (
    <>
      <div style={styles.greetingContainer}>
        <GreetingWidget />
      </div>
      <div style={styles.grid}>
        <Card
          heading='My to-do list'
          toolTipText='Speks assigned to you that need a definition.'
          description='See all Speks assigned to you for documentation'
          subDescription='Create a Spek to see your personal to-do list populated'
          buttonText='See All My Speks'
          tileImage={Todo}
          testid='todo-button'
          handleCardAction={() => history.push('/app/wiki?type=all')}
        />
        <Card
          heading='Recent Notifications'
          toolTipText=''
          description='Communicate changes'
          subDescription='Deliver bite-sized, contextual updates to your team in real-time'
          buttonText='First, Create a Spek'
          tileImage={RecentNotification}
          testid='recent-notifications-button'
          handleCardAction={() =>
            history.push('/app/wiki/topics?appcue=-M4lAj0RY6LbEad1Atu_')
          }
        />
        <Card
          heading='Recent Reactions'
          toolTipText='Recent reactions by your team'
          description='Keep your finger on the pulse of your team'
          subDescription='Add a reaction to any spek to see this populated.'
          buttonText={
            me.role === 'Expert' ? 'First, Create a Spek' : 'Invite Your Team First'
          }
          handleCardAction={handleRedirect}
          tileImage={RecentReactions}
          testid='recent-reactions-button'
        />
        <Card
          heading='Most Viewed'
          toolTipText='Number of total views per Spek.'
          description='Track which training your team is viewing most'
          subDescription='Open an out-of-the-box Spek to see this updated.'
          buttonText='First, Create a Spek'
          tileImage={MostViewed}
          testid='most-viewed-button'
          handleCardAction={() =>
            history.push('/app/wiki/topics?appcue=-M4lAj0RY6LbEad1Atu_')
          }
        />
        <Card
          heading='Most Reacted'
          toolTipText='The Speks that have gotten the most reactions from your team.'
          description='See what content is getting engagement'
          subDescription='Reactions can help improve training'
          buttonText={
            me.role === 'Expert' ? 'First, Create a Spek' : 'Invite Your Team First'
          }
          handleCardAction={handleRedirect}
          tileImage={MostReacted}
          testid='most-reacted-button'
        />
      </div>
    </>
  );
};

export default FirstTimeDashboard;
