import React, {useState, useEffect} from 'react';
import {DataTable, DSTooltip as Tooltip, Fontawesome, ModernPagination} from 'spekit-ui';
import {Stats, Track, formatDurationFromSeconds} from 'spekit-datalayer';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {SpotlightStatsType} from 'spekit-datalayer/src/stats/stats.types';
import styles from './spotlightAnalyticsTable.module.css';

const PENDING_AUDIENCE_TOOLTIP_TEXT = `An estimate of the total reach for a Spotlight. 
If targetted publishing conditions include things like 
"Salesforce events" these may happen for different users at different times.`;

const SNOOZED_TOOLTIP_TEXT = `If the user saw the Spotlight, but has dismissed it to view later.`;
const AVG_TIME_SPENT_VIEWING = `Includes time spent preceeding all engagement types, including dismissal.`;

interface AnalyticsProps {
  teamId: string | null;
  showSpotlightModalWithId: CallableFunction;
}

export default function SpotlightAnalyticsTable(props: AnalyticsProps) {
  const [busy, setBusy] = useState(false);
  const [pageSize] = useState(7);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState({
    label: null,
    pending: null,
    unique: null,
    engaged: null,
    dismissed: null,
    time_spent: null,
  });

  const [spotlightAnalytics, setSpotlightAnalytics] = useState<SpotlightStatsType[]>([]);
  const [originalResults, setOriginalResults] = useState<SpotlightStatsType[]>([]);
  const [sortedResults, setSortedResults] = useState<SpotlightStatsType[]>([]);

  useEffect(() => {
    fetchSpotlightStats({
      type: 'spotlights-summary',
      teamId: props.teamId,
    });
    return () => {};
  }, [props.teamId]);

  const fetchSpotlightStats = async (query: any) => {
    setBusy(true);
    try {
      const {results} = await Stats.fetchStatsByType({
        type: query.type,
        teamId: query.teamId,
      });

      const paginatedResults = results.slice(0, pageSize);
      setOriginalResults(results);
      setSpotlightAnalytics(paginatedResults);
      setCount(results.length);
      setBusy(false);
      Track.track('Spotlights Analytics Dashboard Viewed', {screen_name: 'Dashboard'});
    } catch (error) {
      console.log('Error: stats', error);
    }
  };

  const onPageSelect = (pageNumber: number) => {
    let offset = pageNumber * pageSize;
    setPageNumber(pageNumber);

    if (sortedResults.length) {
      const paginatedItems = sortedResults.slice(offset - pageSize, offset);
      setSpotlightAnalytics(paginatedItems);
    } else {
      const paginatedItems = originalResults.slice(offset - pageSize, offset);
      setSpotlightAnalytics(paginatedItems);
    }
  };

  const onColumnClick = (columnName: string, sortOrder: string) => {
    if (sortOrder === 'ASCENDING') {
      setSortBy((prevState) => ({...prevState, [columnName]: 'ASCENDING'}));

      const _originalResults = [...originalResults];

      _originalResults.sort((itemA, itemB) => {
        let a;
        let b;
        if (typeof itemA[columnName] === 'string') {
          a = itemA[columnName].toLowerCase();
          b = itemB[columnName].toLowerCase();
        } else if (typeof itemA[columnName] === 'number') {
          a = itemA[columnName];
          b = itemB[columnName];
        }

        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });

      const paginatedResults = _originalResults.slice(0, pageSize);
      setSpotlightAnalytics(paginatedResults);
      setSortedResults(_originalResults);
    } else if (sortOrder === 'DESCENDING') {
      setSortBy((prevState) => ({...prevState, [columnName]: 'DESCENDING'}));

      const _originalResults = [...originalResults];

      _originalResults.sort((itemA, itemB) => {
        let a;
        let b;
        if (typeof itemA[columnName] === 'string') {
          a = itemA[columnName].toLowerCase();
          b = itemB[columnName].toLowerCase();
        } else if (typeof itemA[columnName] === 'number') {
          a = itemA[columnName];
          b = itemB[columnName];
        }

        if (a > b) {
          return -1;
        }
        if (a < b) {
          return 1;
        }
        return 0;
      });

      const paginatedResults = _originalResults.slice(0, pageSize);
      setSpotlightAnalytics(paginatedResults);
      setSortedResults(_originalResults);
    } else {
      setSortBy((prevState) => ({...prevState, [columnName]: null}));

      const paginatedResults = originalResults.slice(0, pageSize);
      setSpotlightAnalytics(paginatedResults);
      setSortedResults([]);
    }

    setPageNumber(1);
  };

  const onRowClick = (spotlightId: string) => {
    props.showSpotlightModalWithId(spotlightId);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <label className={styles.header_label}>Spotlights</label>
        <Tooltip shouldWrapChildren label='Spotlight Analytics by your team'>
          <Fontawesome
            style={{color: 'rgba(99, 114, 128, 0.42)', marginLeft: '0.5em'}}
            name={faInfoCircle}
          />
        </Tooltip>
      </div>
      <DataTable
        style={{border: 'none'}}
        emptyState={
          <div
            style={{
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            No Spotlights found.
          </div>
        }
        stickyHeader
        busy={busy}
        data={spotlightAnalytics}
        onColumnClick={onColumnClick}
        onRowClick={onRowClick}
        columns={[
          {
            fieldName: 'label',
            template: (label: string, n: any) => {
              return (
                <div>
                  <label className={styles.text}>{label}</label>
                </div>
              );
            },
            headerTemplate: () => {
              return (
                <span className={styles.headerHeadingContainer}>
                  <span className={styles.headerHeadingText}>HEADLINE</span>
                </span>
              );
            },
            height: '100px',
            width: '40%',
            sort: sortBy.label,
            sortBy: 'label',
          },
          {
            fieldName: 'pending',
            template: (pending: number, n: any) => {
              return (
                <div>
                  <label className={styles.header}>{pending}</label>
                </div>
              );
            },
            headerTemplate: () => {
              return (
                <span className={styles.headerPendingViewersContainer}>
                  <span className={styles.headerPendingViewersText}>
                    ~PENDING VIEWERS
                  </span>
                  <Tooltip shouldWrapChildren label={PENDING_AUDIENCE_TOOLTIP_TEXT}>
                    <Fontawesome
                      style={{color: 'rgba(99, 114, 128, 0.42)', marginLeft: '0.5em'}}
                      name={faInfoCircle}
                    />
                  </Tooltip>
                </span>
              );
            },
            width: '30%',
            height: '100px',
            sort: sortBy.pending,
            sortBy: 'pending',
          },
          {
            fieldName: 'unique',
            template: (unique: number, n: any) => {
              return (
                <div>
                  <label className={styles.text}>{unique}</label>
                </div>
              );
            },
            headerTemplate: () => {
              return <span className={styles.headerUniqueViewers}>UNIQUE VIEWERS</span>;
            },
            width: '20%',
            height: '100px',
            sort: sortBy.unique,
            sortBy: 'unique',
          },
          {
            fieldName: 'engaged',
            template: (engaged: any, n: any) => {
              return (
                <div>
                  <label className={styles.text}>{engaged}</label>
                </div>
              );
            },
            headerTemplate: () => {
              return <span className={styles.headerEngaged}>ENGAGED</span>;
            },
            width: '15%',
            height: '100px',
            sort: sortBy.engaged,
            sortBy: 'engaged',
          },
          {
            fieldName: 'dismissed',
            template: (dismissed: any, n: any) => {
              return (
                <div>
                  <label className={styles.text}>{dismissed}</label>
                </div>
              );
            },
            headerTemplate: () => {
              return (
                <span className={styles.headerSnoozedContainer}>
                  <span className={styles.headerSnoozedText}>~SNOOZED</span>
                  <Tooltip shouldWrapChildren label={SNOOZED_TOOLTIP_TEXT}>
                    <Fontawesome
                      style={{color: 'rgba(99, 114, 128, 0.42)', marginLeft: '0.5em'}}
                      name={faInfoCircle}
                    />
                  </Tooltip>
                </span>
              );
            },
            width: '15%',
            height: '100px',
            sort: sortBy.dismissed,
            sortBy: 'dismissed',
          },
          {
            displayName: 'AVG.TIME SPENT VIEWING',
            fieldName: 'time_spent',
            template: (time_spent: number) => (
              <div>
                <label className={styles.text}>
                  {time_spent ? formatDurationFromSeconds(time_spent) : 'N/A'}
                </label>
              </div>
            ),
            headerTemplate: () => {
              return (
                <span className={styles.headerAvgTimeSpentContainer}>
                  <span className={styles.headerAvgTimeSpentText}>
                    AVG.TIME SPENT VIEWING
                  </span>
                  <Tooltip shouldWrapChildren label={AVG_TIME_SPENT_VIEWING}>
                    <Fontawesome
                      style={{color: 'rgba(99, 114, 128, 0.42)', marginLeft: '0.5em'}}
                      name={faInfoCircle}
                    />
                  </Tooltip>
                </span>
              );
            },
            width: '30%',
            height: '100px',
            sort: sortBy.time_spent,
            sortBy: 'time_spent',
          },
        ]}
      />
      <div className={styles.pagination_container}>
        <ModernPagination
          totalPages={Math.ceil(count / pageSize)}
          currentPage={pageNumber}
          onChange={onPageSelect}
        />
      </div>
    </div>
  );
}
