import React from 'react';
import {As, Text as ChakraText, TextProps, forwardRef} from '@chakra-ui/react';
import {FONT_FAMILY} from '../typography';

export type TText = TextProps;

export const TextConfig = {
  baseStyle: {
    fontFamily: FONT_FAMILY,
    _disabled: {
      color: 'neutral.500',
      fontWeight: 'normal',
    },
  },
  variants: {
    body1: {
      fontSize: 'medium',
      color: 'neutral.800',
    },
    body2: {
      fontSize: 'small',
      color: 'neutral.800',
    },
    hyperlink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: 'primary.500',
    },
    caption1: {
      fontSize: 'tiny',
      display: 'inline-block',
      color: 'neutral.800',
    },
    caption2: {
      fontSize: 'micro',
      display: 'inline-block',
      color: 'neutral.800',
    },
  },
  sizes: {},
  defaultProps: {
    variant: 'body1',
  },
};

const textMapper = {
  body1: 'p',
  body2: 'p',
  caption1: 'span',
  caption2: 'span',
  hyperlink: 'a',
};

export const Text = forwardRef<TText, any>((props, ref) => {
  const {variant = 'body1'} = props;
  const as = textMapper[variant] as As;
  return <ChakraText ref={ref} m={0} as={as} {...props} />;
});
