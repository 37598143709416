import {SfPermissionsResponse} from './sfPermissions.types';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {stringifyQs} from '../utils/commonUtils';
import {CustomError} from '../utils/customError';

class TeamsListApiError extends CustomError {}

export const sfPermissions = {
  getPermissions: function (query?: object): Promise<SfPermissionsResponse> {
    let querystring = stringifyQs(query);
    return new Promise((res, rej) => {
      fetch('/api/permission_sets/suggest/?' + querystring, {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((r) => r.json())
        .then((result) => {
          if (!result) {
            throw new TeamsListApiError('unexpected response from user api');
          } else {
            let response: SfPermissionsResponse = result;
            let permissions: any = Object.values(response)[0];
            res(permissions);
          }
        })
        .catch((e) => rej(e));
    });
  },
};
