import React from 'react';
import {TAssetMimeType} from 'spekit-types';
import {FolderIcon, Graphic, TGraphicContentType} from 'spekit-ui';
import {fileMimeTypes, mimeTypeGroups, mimeTypeToGraphicType} from '../../constants';

interface IProps {
  mimeType: TAssetMimeType;
}

const ICON_PROPS = {height: '16px', width: '16px'};

const MIME_TYPE_MAPPING = {
  [fileMimeTypes.pdf]: mimeTypeToGraphicType(fileMimeTypes.pdf),
  ...Object.fromEntries(
    Object.entries(mimeTypeGroups).flatMap(([_, mimes]) =>
      mimes.map((mime) => [mime, mimeTypeToGraphicType(mime)])
    )
  ),
};

export const RecordIcon = (props: IProps) => {
  const {mimeType} = props;

  const contentType = mimeType && (MIME_TYPE_MAPPING[mimeType] as TGraphicContentType);

  if (contentType) {
    return <Graphic variant='base' iconProps={ICON_PROPS} contentType={contentType} />;
  }

  return <FolderIcon {...ICON_PROPS} data-testid='folder-icon' />;
};
