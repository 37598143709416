import React from 'react';
import {RiMoreFill} from 'react-icons/ri';
import {MenuProps} from '@chakra-ui/react';
import {Menu, MenuButton, MenuList} from '../../Menu';
import {Icon} from '../../Icon';
import {IGhostButtonProps, RemovedChakraProps, TMenuButtonProps} from '../../types';
import {Tooltip, TTooltipProps} from '../../Tooltip';
import {Box} from '../../Layout/Box';

export type TSize = TMenuButtonProps['size'];

export type MoreMenuButtonProps = RemovedChakraProps<TMenuButtonProps> & {
  children: React.ReactNode;
  size?: TSize;
  onOpen?: () => void;
  onClose?: () => void;
  menuPlacement?: MenuProps['placement'];
  containerRef?: React.MutableRefObject<HTMLElement | null>;
  tooltipPlacement?: TTooltipProps['placement'];

  // we currently only support using ghost button variant
  variant?: IGhostButtonProps['variant'];
  menuVariant?: 'default' | 'outlook'; // outlook is used for outlook addin
  insidePortal?: boolean;
  zIndex?: number;
};

const paddingInLine = {
  small: 6,
  medium: 12,
  large: 18,
  extralarge: 24,
};

const iconSize = {
  small: {
    h: 3,
    w: 3,
  },
  medium: {
    h: 4,
    w: 4,
  },
  large: {
    h: 6,
    w: 6,
  },
  extralarge: {
    h: 7,
    w: 7,
  },
};

const btnSize = {
  small: {
    h: 6,
    w: 6,
  },
  medium: {
    h: 8,
    w: 8,
  },
  large: {
    h: '44px',
    w: '44px',
  },
  extralarge: {
    h: '52px',
    w: '52px',
  },
};

export const MoreMenuButton = ({
  children,
  size = 'small',
  tooltipLabel = 'More',
  onOpen,
  onClose,
  menuPlacement,
  containerRef,
  tooltipPlacement,
  variant,
  menuVariant = 'default',
  insidePortal = true,
  zIndex,
  ...props
}: MoreMenuButtonProps) => (
  <Menu onOpen={onOpen} onClose={onClose} placement={menuPlacement} variant={menuVariant}>
    <Tooltip
      portalProps={{containerRef}}
      label={tooltipLabel}
      placement={tooltipPlacement}
    >
      <Box h='min-content' w='min-content'>
        <MenuButton
          colorScheme='white'
          flexShrink={0}
          size={size}
          variant={(variant as IGhostButtonProps['variant']) || 'outlined'}
          data-testid='more-btn'
          iconSpacing={0}
          leftIcon={<Icon as={RiMoreFill} {...iconSize[size]} />}
          {...btnSize[size]}
          paddingInline={paddingInLine[size]}
          {...props}
        />
      </Box>
    </Tooltip>
    <MenuList containerRef={containerRef} insidePortal={insidePortal} zIndex={zIndex}>
      {children}
    </MenuList>
  </Menu>
);
