import React from 'react';
import {
  BreadcrumbLink as ChakraBreadcrumbLink,
  BreadcrumbLinkProps,
} from '@chakra-ui/react';

interface IRouteProps {
  to?: string;
}

export type TBreadcrumbLinkProps = BreadcrumbLinkProps & IRouteProps;

export const BreadcrumbLink = (props: TBreadcrumbLinkProps) => (
  <ChakraBreadcrumbLink
    _hover={
      props.isCurrentPage
        ? {textDecoration: 'none', cursor: 'inherit'}
        : {textDecoration: 'underline'}
    }
    color={props.isCurrentPage ? 'neutral.800' : 'primary.700'}
    {...props}
  />
);
