import React from 'react';
import {Icon, TIconProps} from '../design-system/Icon';

export const FolderIcon = (props: TIconProps) => (
  <Icon {...props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12' fill='none'>
    <path
      d='M12.333 1.99935H6.33301L4.99967 0.666016H1.66634C0.933008 0.666016 0.333008 1.26602 0.333008 1.99935V4.66602H13.6663V3.33268C13.6663 2.59935 13.0663 1.99935 12.333 1.99935Z'
      fill='#FFA000'
    />
    <path
      d='M12.333 2H1.66634C0.933008 2 0.333008 2.6 0.333008 3.33333V10C0.333008 10.7333 0.933008 11.3333 1.66634 11.3333H12.333C13.0663 11.3333 13.6663 10.7333 13.6663 10V3.33333C13.6663 2.6 13.0663 2 12.333 2Z'
      fill='#FEC84B'
    />
  </Icon>
);
