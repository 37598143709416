import {
  IFieldSpeksResponse,
  IWikiQuery,
  IWikiResponse,
  ITask,
  IWikiObject,
  IWikiReorderObject,
  IWikiReorderPayload,
  IWikiBulkAddToTopicsPayload,
  IWikiBulkRemoveFromTopicsPayload,
  IWikiBulkAssignExpertPayload,
  IWikiBulkExternalShareExpertPayload,
} from 'spekit-types';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {capture} from '../logging';
import {stringifyQs} from '../utils/commonUtils';
import {WIKI_API} from '../api';
import checkTaskStatus from '../utils/taskStatus';
import {create, retrieve, update} from '../utils/APIHelpers';

export const Wiki = {
  getFields: (value: string): Promise<Array<IFieldSpeksResponse>> => {
    return fetch('/api/wiki/fields/?n=' + value, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((result) => result.results);
  },
  getPageLayoutFields: (searchQuery: string): Promise<Array<string>> => {
    return fetch('/api/v1.5/page_layouts/suggest/?q=' + searchQuery, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((result) => result);
  },
  getTerms: async (queryString: IWikiQuery): Promise<IWikiResponse> => {
    const query = stringifyQs(queryString, {
      arrayFormat: 'comma',
      skipNulls: true,
      encode: false,
      addQueryPrefix: true,
    });
    try {
      let response = await fetch(`${WIKI_API}${query}`);
      let result = await response.json();
      return result;
    } catch (err) {
      capture(err);
      throw err;
    }
  },
  deleteSpeks: async (businessTermIds: string[], assetIds: string[]): Promise<ITask> => {
    const body = {
      business_terms: businessTermIds,
      assets: assetIds,
    };
    const result: ITask = await create('/api/wiki/delete', body);
    if (result.success) {
      return await checkTaskStatus(result.task_id);
    } else {
      throw new Error('Something went wrong');
    }
  },
  getObjects: async () =>
    retrieve<IWikiObject[]>(`${WIKI_API}/objects/?view_hidden=true`),

  getSyncedObjects: async (searchTerm?: string) => {
    return retrieve<IWikiObject[]>(
      `${WIKI_API}/objects/?synced_only=true&n=${searchTerm}`
    );
  },
  getSpeksFromTopic: async (
    topicId: string
  ): Promise<{results: IWikiReorderObject[]}> => {
    const queryString = stringifyQs({
      type: 'all',
      include_topic_ids: topicId,
      source: 'spek_reorder',
      exclude_count: true,
      ordering: 'rank,label',
      include_hidden: true,
    });
    return retrieve(`${WIKI_API}?${queryString}`);
  },

  reorderSpeks: async (data: IWikiReorderPayload) => {
    const result = await update<ITask>(`${WIKI_API}/reorder`, data);
    return checkTaskStatus(result.task_id);
  },
  bulkAddToTopics: async (data: IWikiBulkAddToTopicsPayload) => {
    const result = await create<IWikiBulkAddToTopicsPayload, ITask>(
      '/api/wiki/tag',
      data
    );
    return checkTaskStatus(result.task_id);
  },
  bulkRemoveFromTopics: async (data: IWikiBulkRemoveFromTopicsPayload) => {
    const result = await update<ITask>('/api/wiki/untag', data);
    return checkTaskStatus(result.task_id);
  },

  bulkAssignExpert: async (data: IWikiBulkAssignExpertPayload) => {
    const result = await update<ITask>('/api/wiki/assign_data_expert', data);
    return checkTaskStatus(result.task_id);
  },

  bulkUpdateExternalShare: async (data: IWikiBulkExternalShareExpertPayload) => {
    const result = await create<IWikiBulkExternalShareExpertPayload, ITask>(
      '/api/wiki/update_share_settings',
      data
    );
    return checkTaskStatus(result.task_id);
  },
};
