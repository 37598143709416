import {User} from '../user/user.types';
import {retrieve} from '../utils/APIHelpers';
import {TeamDetail} from '../team/team.types';
import {checkTaskStatus} from '../task/checkStatusTask';

import {
  IGetTeamResponse,
  IGetUserResponse,
  IGetQuizzesResponse,
  IQuizAnalytics,
  ITeamParams,
  TAnalyticsUser,
  IQuizAnalyticsParams,
  IUsersParams,
} from 'spekit-types';
import {stringifyQs} from '../utils/commonUtils';

export const getTeams = async (params: ITeamParams) => {
  const filters = stringifyQs(params);
  const response = await retrieve<IGetTeamResponse>(`/api/v1/teams/?${filters}`);

  return response.results.map((team: TeamDetail) => {
    return {
      label: team.name,
      value: team.id,
    };
  });
};

export const getUsers = async (params: IUsersParams) => {
  const filters = stringifyQs(params);
  const response = await retrieve<IGetUserResponse>(`/api/v1/users?${filters}`);
  return response.results.map((user: TAnalyticsUser) => {
    return {
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
      email: user.email,
    };
  });
};

export const getQuizzes = async (params: IQuizAnalyticsParams) => {
  const filters = stringifyQs(params);
  const response = await retrieve<IGetQuizzesResponse>(
    `/analytics/v1/quiz_attempts/?${filters}`
  );
  return response.results
    .map((x: IQuizAnalytics) => {
      return {
        id: x.quiz.id,
        title: x.quiz.title,
        status:
          x.attempted_questions === 0
            ? 'Not Started'
            : x.quiz.question_count === x.attempted_questions
            ? 'Completed'
            : 'In Progress',
        score: `${x.score} out of ${x.quiz.question_count}`,
        published_on: x.quiz.published_on,
        completion_date:
          x.completion_date && x.quiz.question_count === x.attempted_questions
            ? x.completion_date
            : '',
      };
    })
    .sort((quiz1, quiz2) => {
      let quizA: Date | number = new Date(quiz1.completion_date);
      quizA = quizA.getTime() ? quizA.getTime() : 0;
      let quizB: Date | number = new Date(quiz2.completion_date);
      quizB = quizB.getTime() ? quizB.getTime() : 0;
      return quizB >= quizA ? 1 : -1;
    });
};

export const downloadQuizStats = async (userId: string | undefined) => {
  const response = await retrieve<{task_id: string}>(
    `/analytics/v1/quiz_attempts/export/?format=json&users=${userId}`
  );
  await checkTaskStatus(response.task_id);
  return response;
};
