import React from 'react';
import {IFilter} from 'spekit-types';
import {Flex, Tag} from 'spekit-ui';

interface IFilterTagProps {
  filters: IFilter;
  onRemoveFilter: (tag: string) => void;
}

export const FilterTag = (props: IFilterTagProps) => {
  const {filters, onRemoveFilter} = props;
  const tags = [];

  if (filters.type.value !== 'all') {
    tags.push({key: 'type', label: `Type: ${filters.type.label}`});
  }

  if (filters.period.value !== 'any') {
    tags.push({key: 'period', label: `Date modified: ${filters.period.label}`});
  }

  const handleRemoveFilter = (key: string) => () => {
    onRemoveFilter(key);
  };

  return (
    <Flex gap={4}>
      {tags.map(({key, label}) => (
        <Tag variant='solid' onClose={handleRemoveFilter(key)} key={key}>
          {label}
        </Tag>
      ))}
    </Flex>
  );
};
