import React, {RefObject, useState, useCallback} from 'react';
import {RiCloseLine} from 'react-icons/ri';
import {
  ActionWithConfirmation,
  DSModal as Modal,
  Heading,
  ModalBody,
  Box,
  Flex,
  DSButton as Button,
  Divider,
  FormControl,
  PrivacySettings,
  Alert,
  AlertDescription,
  FormErrorMessage,
  useToast,
  notifications,
  TAlertVariant,
  ModalFooter,
} from 'spekit-ui';
import {FilePreview} from './FilePreview';
import {
  getAssetExtension,
  topics as TopicsAPI,
  getDropzoneAcceptedFiles,
  CONTENT_SHARING,
  notification,
  utils,
} from 'spekit-datalayer';
import {
  IOptionType,
  IAsset,
  TAssetMimeType,
  TCustomFieldValues,
  IExtendedNotificationState,
  IFinalizedAPIPayload,
  TContentIntegration,
} from 'spekit-types';
import {useDropzone, FileRejection} from 'react-dropzone';
import DeleteConfirmation from './DeleteAssetConfirmation';
import type {ICloseProps} from '../AssetModal';
import {TopicSelector} from '../../TopicSelector';
import {CustomFieldsEditor} from '../../Content';
import {getFileName, hasSupportedFileTypes, extractFileName} from '../helpers';
import {
  INVALID_FILE_SIZE_MESSAGE,
  INVALID_LARGE_FILE_SIZE_MESSAGE,
  FILE_SIZE_LIMIT,
  INVALID_FILE_MESSAGE,
  LARGE_FILE_SIZE_LIMIT,
  MAX_FILE_LABEL_CHARACTERS,
} from '../constants';
import {NotificationButton} from '../../Notifications/NotificationButton';
import debounce from 'debounce-promise';
import {ExpertSelector} from '../../ExpertSelector';

const {prepareNotificationPayload} = notification;
const {notify} = notifications;

interface Props {
  isOpen: boolean;
  onClose: (closeArgs?: ICloseProps) => void;
  containerRef?: RefObject<HTMLElement>;
  editableAsset: IAsset;
  editableFile?: File;
  hasLargeFileUploadFlag?: boolean;
  hasExtendedFileTypesFlag?: boolean;
}

interface IAssetDetails {
  fileId: string;
  fileName: string;
  label: string;
  fileSize: number;
  isShareable: boolean;
  customFields: TCustomFieldValues;
  contentType: TAssetMimeType;
  warnMessage?: string;
  store?: 'internal' | TContentIntegration;
}

export const EditAsset = (props: Props) => {
  const {
    isOpen,
    onClose,
    containerRef,
    editableAsset,
    hasLargeFileUploadFlag,
    editableFile,
    hasExtendedFileTypesFlag,
  } = props;
  const fileSizeLimit = hasLargeFileUploadFlag ? LARGE_FILE_SIZE_LIMIT : FILE_SIZE_LIMIT;
  const fileSizeOverLimitMsg = hasLargeFileUploadFlag
    ? INVALID_LARGE_FILE_SIZE_MESSAGE
    : INVALID_FILE_SIZE_MESSAGE;
  const linkedTopic = editableAsset.tags.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));

  const toast = useToast();
  const [selectedTopics, setSelectedTopics] = useState<IOptionType[]>(linkedTopic);
  const [file, setFile] = useState<File | undefined>(editableFile);
  const {data_expert} = editableAsset;
  const [selectedExpert, setSelectedExpert] = useState<IOptionType | null>(
    data_expert
      ? {
          value: data_expert.id,
          label: `${data_expert.first_name} ${data_expert.last_name}`,
        }
      : null
  );
  const [assetDetails, setAssetDetails] = useState<IAssetDetails>({
    label: editableAsset.label,
    fileId: editableAsset.id,
    fileName: editableAsset.file_name,
    fileSize: editableAsset.file_size,
    isShareable: editableAsset.is_externally_shared || false,
    customFields: editableAsset.custom_columns || {},
    contentType: editableAsset.content_type,
    store: editableAsset?.store,
  });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<{
    type: TAlertVariant;
    message: string;
  }>({type: 'success', message: ''});
  const [fileNameError, setFileNameError] = useState(false);
  const [isTopicError, setIsTopicError] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showLoaderOnly, setShowLoaderOnly] = useState(false);

  const onFileAccepted = async (
    fileToBeUploaded: File[],
    fileRejections: FileRejection[]
  ) => {
    setIsAlertOpen(false);
    if (fileRejections.length > 0) {
      setIsAlertOpen(true);
      let message = '';
      if (!hasSupportedFileTypes(fileRejections[0].file.type)) {
        message = INVALID_FILE_MESSAGE;
      } else if (fileRejections[0].file.size > fileSizeLimit) {
        message = fileSizeOverLimitMsg;
      } else {
        message = 'Something went wrong. Please try again.';
      }
      setAlertMessage({
        type: 'error',
        message: message,
      });
    } else {
      setFile(fileToBeUploaded[0]);

      const duplicateMessage = await duplicateFileCheck(fileToBeUploaded[0]);

      setAssetDetails((prevState) => ({
        ...prevState,
        label: extractFileName(fileToBeUploaded[0].name),
        fileName: fileToBeUploaded[0].name,
        fileSize: fileToBeUploaded[0].size,
        contentType: fileToBeUploaded[0].type as TAssetMimeType,
        warnMessage: duplicateMessage,
      }));
    }
  };

  const duplicateFileCheck = async (file: File) => {
    let warnMessage = '';
    const formData = new FormData();
    const newFileName = getFileName(file.name, file.name);
    const onlyName = newFileName.split('.')[0];
    const hashMD5 = await utils.getFileMD5(file);
    formData.append('hash_md5', hashMD5);
    formData.append('file_name', onlyName);
    try {
      setIsSaving(true);
      setShowLoaderOnly(true);
      await TopicsAPI.duplicateFile(formData);
      setIsSaving(false);
      setShowLoaderOnly(false);
    } catch (err) {
      setIsSaving(false);
      setShowLoaderOnly(false);
      warnMessage = err.message;
    }
    return warnMessage;
  };

  const duplicateFileCheckNameOnly = async (fileName: string) => {
    if (
      !fileName ||
      !(fileName.length > 0 && fileName.length <= MAX_FILE_LABEL_CHARACTERS)
    ) {
      return;
    }
    const formData = new FormData();
    if (file) {
      const hashMD5 = await utils.getFileMD5(file);
      formData.append('hash_md5', hashMD5);
    }
    formData.append('file_name', fileName);
    formData.append('file_id', assetDetails.fileId);
    try {
      await TopicsAPI.duplicateFile(formData);
      setAssetDetails((prevState) => ({
        ...prevState,
        warnMessage: '',
      }));
    } catch (err) {
      setAssetDetails((prevState) => ({
        ...prevState,
        warnMessage: err.message,
      }));
    }
  };

  const debouncedFileCheck = useCallback(debounce(duplicateFileCheckNameOnly, 500), [
    file,
  ]);

  const acceptedFiles = getDropzoneAcceptedFiles(hasExtendedFileTypesFlag);
  const {open, getInputProps, getRootProps} = useDropzone({
    onDrop: onFileAccepted,
    noClick: true,
    noKeyboard: true,
    maxSize: fileSizeLimit,
    accept: acceptedFiles,
    noDrag: true,
    disabled: isSaving,
  });
  const handleClose = () => {
    setSelectedTopics([]);
    setIsTopicError(false);
    setIsAlertOpen(false);
    onClose();
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await TopicsAPI.deleteAsset(editableAsset.id);
      onClose({isSuccessful: true, selectedTopics, files: 1, fromEdit: true});
      toast({
        variant: 'success',
        description: 'Asset deleted successfully.',
      });
    } catch (e) {
      setIsDeleting(false);
    }
  };

  const saveChangesMultiPart = async (
    extendedNotification: IExtendedNotificationState
  ) => {
    const topic_ids = selectedTopics.map((topic) => topic.value);
    const notificationToSend = prepareNotificationPayload(extendedNotification);
    const notificationTeams: any = [];
    extendedNotification.teams.forEach((team) => {
      notificationTeams.push(team.value);
    });

    const payload: any = {
      file_size: file?.size || assetDetails.fileSize,
      content_type: assetDetails.contentType,
      file_name: file?.name || assetDetails.fileName,
      tags_list: topic_ids,
      label: assetDetails.label,
      file_id: assetDetails.fileId,
      shareable: assetDetails.isShareable,
      custom_columns: assetDetails.customFields,
      data_expert: selectedExpert?.value,
    };
    if (notificationTeams.length > 0) {
      payload.teams = notificationTeams;
    }
    if (notificationToSend) {
      payload.notification = notificationToSend;
    }

    if (
      assetDetails.label.length < 1 ||
      assetDetails.label.length > MAX_FILE_LABEL_CHARACTERS
    ) {
      return;
    }

    if (file) {
      try {
        setIsSaving(true);
        const {presigned_urls} = await TopicsAPI.updateWithMultipart(
          assetDetails.fileId,
          {
            ...payload,
            file_upload: true,
          }
        );

        const {upload_id, object_key, urls, file_id} = presigned_urls;
        const chunks = utils.createFileChunks(file, Math.ceil(file.size / urls.length));

        let promises = [];
        for (let j = 0; j < chunks.length; j++) {
          promises.push(TopicsAPI.doMultiPartUpload(urls[j], chunks[j]));
        }

        const responses = await Promise.all(promises);

        let finalizedPayload: IFinalizedAPIPayload = {
          upload_id: upload_id,
          object_key: object_key,
          parts: [],
          file_id: file_id,
        };

        responses.forEach((res, _index) => {
          finalizedPayload.parts.push({
            part_number: _index + 1,
            //@ts-ignore
            etag: res.headers.get('ETag').replace(/['"]+/g, ''),
          });
        });

        await TopicsAPI.finalizedMultiPartUpload(finalizedPayload);
        notify({
          text: notificationToSend
            ? `File upload in progress. You will be notified when it's successful, and user will be notified!`
            : 'File upload in progress. You will not see the new file until the upload is successful.',
        });

        onClose({
          isSuccessful: true,
          selectedTopics,
          files: 1,
          fromEdit: true,
          assetId: editableAsset.id,
          notification: extendedNotification,
          isLF: true,
          fileDetails: {
            value: editableAsset.id,
            label: assetDetails.fileName,
          },
        });

        return;
      } catch (e) {
        setIsSaving(false);
        notify({text: 'Error updating File.', error: true});
        return;
      }
    }

    try {
      setIsSaving(true);
      await TopicsAPI.updateWithMultipart(assetDetails.fileId, {
        ...payload,
        file_upload: false,
      });
      toast({
        variant: 'success',
        description: 'Changes saved!',
      });
      onClose({
        isSuccessful: true,
        selectedTopics,
        files: 1,
        fromEdit: true,
        assetId: editableAsset.id,
        notification: extendedNotification,
        fileDetails: {
          value: editableAsset.id,
          label: assetDetails.fileName,
        },
      });
    } catch (e) {
      setIsSaving(false);
      toast({
        variant: 'error',
        description: 'Error updating details.',
      });
    }
  };

  const saveChanges = async (extendedNotification: IExtendedNotificationState) => {
    if (
      !(
        assetDetails.label.trim().length > 0 &&
        assetDetails.label.trim().length <= MAX_FILE_LABEL_CHARACTERS
      )
    ) {
      setFileNameError(true);
      return;
    }
    if (selectedTopics.length === 0) {
      setIsTopicError(true);
      return;
    }

    if (assetDetails.label && selectedTopics.length > 0) {
      const formData = new FormData();
      if (file) {
        formData.append('file', file);
      }
      formData.append('file_name', assetDetails.label);
      formData.append('shareable', assetDetails.isShareable.toString());
      selectedTopics.forEach((topic) => {
        formData.append('tags_list', topic.value);
      });
      extendedNotification.teams.forEach((team) => {
        formData.append('teams', team.value);
      });

      const notificationToSend = prepareNotificationPayload(extendedNotification);

      if (notificationToSend) {
        formData.append('notification', JSON.stringify(notificationToSend));
      }
      formData.append('custom_columns', JSON.stringify(assetDetails.customFields));
      setIsSaving(true);
      try {
        await TopicsAPI.updateAssets(editableAsset.id, formData);
        toast({
          variant: 'success',
          description: notificationToSend
            ? 'Changes saved and users notified!'
            : 'File updated!',
        });
        onClose({
          isSuccessful: true,
          selectedTopics,
          files: 1,
          fromEdit: true,
          assetId: editableAsset.id,
          notification: extendedNotification,
          fileDetails: {
            value: editableAsset.id,
            label: assetDetails.fileName,
          },
        });
      } catch (error) {
        setIsSaving(false);
        toast({
          variant: 'error',
          description: 'Error updating details.',
        });
      }
    }
  };

  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };

  const handleExpertsChange = (newValue: IOptionType) => {
    setSelectedExpert(newValue);
  };

  if (deleteConfirmation) {
    return (
      <DeleteConfirmation
        isOpen={deleteConfirmation}
        handleClose={() => setDeleteConfirmation(false)}
        handleDelete={handleDelete}
        containerRef={containerRef}
        isDeleting={isDeleting}
      />
    );
  }

  return (
    <Modal
      portalProps={{containerRef}}
      isOpen={isOpen}
      onClose={handleClose}
      size='md'
      trapFocus={false}
      scrollBehavior='inside'
      variant='file'
      blockScrollOnMount={utils.getEnvironment() === 'webapp'}
      isCentered
      id='edit-asset-modal'
    >
      <Flex alignItems='start' px={24} pt={24}>
        <Heading
          as='h4'
          fontWeight='semibold'
          data-testid='modal-heading'
          noOfLines={3}
          paddingBottom={4}
          paddingRight={24}
          marginBottom={4}
          wordBreak='break-word'
        >
          Edit {editableAsset.label}
        </Heading>

        <Flex alignItems='center' ml='auto'>
          <>
            <NotificationButton
              contentType='File'
              onSave={(extendedNotification) =>
                hasLargeFileUploadFlag
                  ? saveChangesMultiPart(extendedNotification)
                  : saveChanges(extendedNotification)
              }
              mode='edit'
              disabled={isSaving || isDeleting}
              isSubmitting={isSaving}
              testId='save-button'
              topics={selectedTopics.map((topic) => topic.value) || []}
            />
            <Divider orientation='vertical' h='30px' ml={20} mr={10} />
          </>

          <ActionWithConfirmation
            icon={RiCloseLine}
            confirmationHeader='Close'
            confirmationMessage='Are you sure? Closing this will cause your progress to be deleted.'
            confirmActionText='Yes, close'
            confirmAction={handleClose}
            actionTooltip='Close'
          />
        </Flex>
      </Flex>

      <ModalBody px={24} mt={16}>
        {isAlertOpen && (
          <Alert
            data-testid='alert-message'
            variant={alertMessage.type}
            onClose={handleAlertClose}
            mb={12}
          >
            <AlertDescription>{alertMessage.message}</AlertDescription>
          </Alert>
        )}

        {
          <>
            <FormControl isInvalid={fileNameError}>
              <Flex
                mt={24}
                gap={10}
                direction='row'
                data-testid='uploaded-files-list'
                alignItems={'center'}
                {...getRootProps({})}
              >
                <FilePreview
                  hasUploaded={false}
                  isUploading={isSaving || isDeleting}
                  store={assetDetails.store}
                  fileName={assetDetails.label}
                  fileSize={assetDetails.fileSize}
                  fileExtension={getAssetExtension(assetDetails.contentType)}
                  contentType={assetDetails.contentType}
                  onChange={(updatedFileName: string) => {
                    setAssetDetails((prevState) => ({
                      ...prevState,
                      label: updatedFileName,
                      warnMessage: '',
                    }));
                    setFileNameError(false);
                    debouncedFileCheck(updatedFileName);
                  }}
                  onEditFile={open}
                  warnMessage={assetDetails.warnMessage}
                  showLoaderOnly={showLoaderOnly}
                />
                <input {...getInputProps()} data-testid='dropzone-input' />
              </Flex>

              {fileNameError && (
                <FormErrorMessage data-testid='form-error'>
                  Please enter a valid file name
                </FormErrorMessage>
              )}
            </FormControl>

            <Divider mt={24} />
          </>
        }

        <>
          {/* topic selector */}
          <Box mt={24}>
            <TopicSelector
              dataTestId='topic-label'
              errorTestId='topic-error'
              placeholder='Select Topics'
              label='Add to Topics'
              defaultValues={selectedTopics}
              handleTopicsChange={(newValue) => {
                // typecasting here because <Select /> generics are messed up
                setSelectedTopics(newValue as IOptionType[]);
                setIsTopicError(false);
              }}
              isInvalid={isTopicError}
              allowedOnlyTopics
              isDisabled={isSaving || isDeleting}
              blurInputOnSelect
            />
          </Box>

          <Box mt={24}>
            <ExpertSelector
              label='Expert'
              topics={selectedTopics.map((topic) => topic.label)}
              handleExpertsChange={handleExpertsChange}
              placeholder='Select expert'
              isInvalid={false}
              dataTestId='assign-expert-selector'
              errorTestId='assign-expert-error'
              defaultExpert={selectedExpert}
              isRequired={false}
            />
          </Box>

          <Box mt={24}>
            <CustomFieldsEditor
              mode={isSaving || isDeleting ? 'view' : 'edit'}
              values={assetDetails.customFields}
              type='file'
              updateValues={(values) =>
                setAssetDetails({...assetDetails, customFields: values})
              }
            />
          </Box>

          {/* privacy settings */}
          <Box mt={24}>
            <PrivacySettings
              isDisabled={isSaving || isDeleting}
              alertMessage='Existing external links for this content will no longer be accessible.'
              data-testid='asset-external-share-checkbox'
              isChecked={assetDetails.isShareable}
              onChange={() => {
                setAssetDetails((prevState) => ({
                  ...prevState,
                  isShareable: !prevState.isShareable,
                }));
              }}
            >
              {CONTENT_SHARING.ALL_USERS}
            </PrivacySettings>
          </Box>
        </>
      </ModalBody>
      <ModalFooter justifyContent='space-between' px={24} pb={24}>
        <Button
          size='medium'
          variant='danger'
          onClick={() => setDeleteConfirmation(true)}
          isLoading={isDeleting}
          data-testid='delete-button'
          isDisabled={isSaving || isDeleting}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditAsset;
