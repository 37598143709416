import {Component} from 'react';
import {connect} from 'react-redux';
import {Fontawesome, DSTooltip as Tooltip, DSButton as Button, Icon} from 'spekit-ui';
import {PropagateLoader} from 'react-spinners';
import {Link} from 'react-router-dom';
import {utils, logging, fetchMiddleWare} from 'spekit-datalayer';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import trophy from './images/success_state_trophy.svg';
import EmptyState from './images/todo_empty_state.svg';
import {FaArrowRight} from 'react-icons/fa';

const fetch = fetchMiddleWare.fetchMiddleWare;

class TodoWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terms: [],
      busy: false,
      totalTerms: 0,
      definedTerms: 0,
    };
  }
  componentDidMount() {
    this.getTodos();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.teamId !== this.props.teamId) {
      this.getTodos();
    }
  }
  getTodos = () => {
    let params = {type: 'my_todos', teams: this.props.teamId};
    let queryString = utils.stringifyQs(params, {skipNulls: true});
    this.setState({busy: true});

    fetch(`/api/stats/?` + queryString, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          terms: result.terms_list,
          busy: false,
          totalTerms: result.assigned_terms,
          definedTerms: result.defined_terms,
        });
      })
      .catch((err) => {
        logging.capture(err);
        this.setState({
          busy: false,
        });
      });
  };
  gotoTutorial = () => {
    window.location.href = '/app/wiki';
  };
  render() {
    let styles = {
      myTodoList: {
        color: '#637280',
        height: '20px',
        fontSize: '22px',
        fontWeight: '500',
        marginBottom: '25px',
      },
      loaderContainer: {
        width: '0px',
        margin: 'auto',
        marginTop: '200px',
      },
      todoCounter: {
        marginBottom: '8px',
        fontSize: '30px',
        color: '#637280',
        fontWeight: '500',
      },
      completed: {
        fontSize: '20px',
        lineHeight: '20px',
        color: '#637280',
        fontWeight: '500',
        marginLeft: '0.5em',
      },
      arrowBtn: {
        backgroundColor: '#3576E8',
        color: '#FFF',
        borderRadius: '18px',
        width: '120px',
        float: 'right',
      },
      arrowBtnCompleted: {
        backgroundColor: '#FFF',
        color: '#C673D2',
        borderRadius: '18px',
        width: '120px',
        float: 'right',
        marginBottom: '24px',
      },
      arrow: {
        marginLeft: '0.5em',
      },
      failHeading: {
        fontSize: '30px',
        fontWeight: '500',
        marginBottom: '22px',
      },
      failText: {
        fontSize: '20px',
        fontWeight: '300',
        marginBottom: '27px',
      },
      failButton: {
        backgroundColor: '#FFFFFF',
        color: '#3576E8',
        fontWeight: '300',
        borderRadius: '30px',
        padding: '10px 30px',
      },
      congrats: {
        fontSize: '30px',
        fontWeight: '500',
        marginBottom: '41px',
      },
      congratsDescription: {
        fontSize: '20px',
        fontWeight: '300',
        lineHeight: '27px',
        width: '60%',
        display: 'inline-block',
      },
      trophyImage: {
        display: 'inline-block',
        height: '180px',
        width: '40%',
      },
      infoCircle: {
        color: 'rgba(99,114,128,0.42)',
        marginLeft: '0.5em',
      },
      ttIcon: {
        color: 'rgba(159,169,186,0.5)',
        cursor: 'pointer',
        display: 'inline-block',
        marginLeft: '0.5em',
        position: 'relative',
      },
      tt: {
        position: 'absolute',
        width: '200px',
        right: '-222px',
        top: '6px',
        background: '#04254a',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '4px',
        fontSize: '14px',
        zIndex: '1',
        boxShadow: '0px 0px 30px 2px rgba(0,0,0,0.4)',
      },
      emptyStateContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '384px',
      },
      emptyStateImg: {
        marginBottom: '28px',
      },
      emptyStateFirstLine: {
        fontSize: '20px',
        color: '#4A4A4A',
        fontWeight: 'bold',
        lineHeight: '30px',
      },
      emptyStateSecondLine: {
        fontSize: '14px',
        color: '#4A4A4A',
        lineHeight: '30px',
        fontWeight: '300',
      },
    };
    let cardToRender = null;
    let viewAllUrl;
    if (
      this.props.wikiStoredState.uiMode === 'data_dictionary' ||
      this.props.me.company.salesforceorganizations_set[0].instance_url === 'dummy'
    ) {
      viewAllUrl =
        '/app/wiki/?type=object,field,field_value,business_term&data_experts=' +
        this.props.me.id;
    } else {
      viewAllUrl =
        '/app/wiki/?type=object,field,field_value,business_term&spek_mode=true&data_experts=' +
        this.props.me.id;
    }
    if (this.state.busy) {
      cardToRender = (
        <div>
          <div style={styles.loaderContainer}>
            <PropagateLoader loading={this.state.busy} size={20} color='#FFF' />
          </div>
        </div>
      );
    } else if (this.state.totalTerms === 0) {
      cardToRender = (
        <div style={styles.emptyStateContainer}>
          <img src={EmptyState} alt='empty' style={styles.emptyStateImg} />
          <div style={styles.emptyStateFirstLine}>
            Hmm… we don’t see any Speks assigned to you
          </div>
          <div style={styles.emptyStateSecondLine}>
            Create a Spek without a definition and assign yourself as the Expert to see
            your personal to-do list populated.
          </div>
        </div>
      );
    } else if (this.state.definedTerms === this.state.totalTerms) {
      cardToRender = (
        <div>
          <div style={styles.todoCounter}>
            {this.state.definedTerms + '/' + this.state.totalTerms}
            <span style={styles.completed}>Completed</span>
            <Link to={viewAllUrl}>
              <Button
                colorScheme='primary'
                variant='contained'
                size='medium'
                rightIcon={<Icon as={FaArrowRight} />}
                data-testid='todo-list-view-all'
              >
                Define now
              </Button>
            </Link>
          </div>
          <div style={styles.congrats}>Congrats {this.props.me.first_name}!</div>
          <div style={{display: 'inline-block'}}>
            <div style={styles.congratsDescription}>
              <div style={{marginBottom: '27px'}}>
                You’ve defined all the Speks assigned to you.
              </div>

              <div style={{lineHeight: '27px'}}>
                Thanks for sharing expertise to boost your team’s productivity!
              </div>
            </div>
            <img src={trophy} alt='Trophy' style={styles.trophyImage} />
          </div>
        </div>
      );
    } else {
      cardToRender = (
        <div>
          <div style={styles.todoCounter}>
            {this.state.definedTerms + '/' + this.state.totalTerms}
            <span style={styles.completed}>Completed</span>
            <Link to={viewAllUrl} style={{textDecoration: 'none', float: 'right'}}>
              <Button
                colorScheme='primary'
                variant='contained'
                size='medium'
                rightIcon={<Icon as={FaArrowRight} />}
                data-testid='todo-list-view-all'
              >
                Define now
              </Button>
            </Link>
          </div>
          {this.state.terms.map((term) => {
            return <TodoListWidget term={term} key={term.id} />;
          })}
        </div>
      );
    }

    return (
      <div>
        <div style={styles.myTodoList}>
          My to-do list
          <Tooltip
            label='Speks assigned to you that need a definition.'
            shouldWrapChildren
          >
            <Fontawesome name={faInfoCircle} style={styles.infoCircle} />
          </Tooltip>
        </div>
        {cardToRender}
      </div>
    );
  }
}

class TodoListWidget extends Component {
  termTypes = {
    object: 'Object',
    field: 'Field',
    field_value: 'Picklist value',
    business_term: 'Spek',
  };
  render() {
    let styles = {
      main: {
        marginBottom: '16px',
      },
      checkmark: {
        border: '3px solid #3576E8',
        backgroundColor: '#FAFBFC',
        height: '25px',
        width: '25px',
        boxSizing: 'border-box',
        marginRight: '19.5px',
        display: 'inline-block',
        verticalAlign: 'middle',
        borderRadius: '50%',
      },
      text: {
        display: 'inline-block',
        verticalAlign: 'middle',
        maxWidth: 'calc(100% - 166px)',
        whiteSpace: 'nowrap',
      },
      somethingElse: {
        fontSize: '16px',
        color: '#637280',
        fontWeight: '600',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      picklistOnStage: {
        color: '#637280',
        fontSize: '12px',
        lineHeight: '15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      arrowBtn: {
        backgroundColor: '#FFF',
        color: '#3576E8',
        borderRadius: '18px',
        width: '120px',
        float: 'right',
      },
      arrow: {
        marginLeft: '0.5em',
      },
    };
    let {term} = this.props;
    return (
      <div style={styles.main}>
        <div style={styles.checkmark} />
        <div style={styles.text}>
          <div style={styles.somethingElse} title={term.label}>
            {term.label}
          </div>
          <div style={styles.picklistOnStage}>
            {this.termTypes[term.type] + (term.parent ? ' on ' + term.parent.label : '')}
          </div>
        </div>
        <Link
          style={{textDecoration: 'none', float: 'right'}}
          to={`/app/wiki/${term.type}/${term.id}?expanded=true&spek_mode=true&type=${term.type}`}
        >
          <Button
            colorScheme='white'
            variant='ghost'
            size='medium'
            rightIcon={<Icon as={FaArrowRight} />}
          >
            Define now
          </Button>
        </Link>
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    me: state.layout.me,
    wikiStoredState: state.wikiStoredState.storedState,
  };
})(TodoWidget);
