import {
  IGetRecommendationsPayload,
  TGetRecommendationsResponse,
  TGetSummariesResponse,
  TGetChatResponse,
  TFeedbackQuestionsResponse,
  TFollowUpQuestionResponse,
  RexApp,
  IChatMessage,
} from 'spekit-types';
import {create, retrieve} from '../utils/APIHelpers';
import {
  SPEKIT_SUMMARIES_API,
  SPEKIT_RECOMMENDATION_API,
  SPEKIT_CHAT_API,
  CHATBOT_FEEDBACK_QUESTIONS_API,
  SPEKIT_CHAT_FOLLOWUP_API,
} from '../api';

export const getContextualRecommendations = async (
  payload: IGetRecommendationsPayload
) => {
  const response: Promise<TGetRecommendationsResponse> = create(
    `${SPEKIT_RECOMMENDATION_API}`,
    payload
  );
  return response;
};

export const getContextualSummaries = async (payload: {
  context: string;
  existing_prompt: string;
  engine?: string;
  app?: RexApp;
}) => {
  const response: TGetSummariesResponse = await create(
    `${SPEKIT_SUMMARIES_API}`,
    payload
  );
  return response.summaries;
};

export const getChatResponse = async (message: string): Promise<TGetChatResponse> => {
  const response: TGetChatResponse = await create(`${SPEKIT_CHAT_API}`, {
    message,
    context: message,
    engine: 'default-metarerank',
  });
  return response;
};

export const getFeedbackQuestions = async (): Promise<TFeedbackQuestionsResponse> => {
  return retrieve(CHATBOT_FEEDBACK_QUESTIONS_API);
};

export const getFollowUpQuestions = async (
  question: string,
  answer: string,
  sources: string[],
  chatHistory: IChatMessage[]
): Promise<TFollowUpQuestionResponse> => {
  const response: TFollowUpQuestionResponse = await create(
    `${SPEKIT_CHAT_FOLLOWUP_API}`,
    {
      question,
      answer,
      sources,
      chat_history: chatHistory,
    }
  );
  return response;
};
