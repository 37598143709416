import qs from 'qs';
import {
  TermReadResponse,
  TermReactionsResult,
  Term,
  PermissionRequest,
  PermissionResponse,
  IsStarredResponse,
  TermResponse,
  StarredTerm,
  StarredTermResponse,
  TermQuery,
  CheckTermsResponse,
  TermMarkAsReadResponse,
  TermType,
  TermViewsResult,
  TermReactionsResponse,
  TTermTeamSummary,
  TAnalyticsData,
  TAnalyticsConfig,
  ReportType,
  TTermViewsResponse,
} from './terms.types';
// @ts-ignore
import {Context} from '../customHooks/useStateHistory';
import {fetchMiddleWare} from '../fetchMiddleWare';
import {stringifyQs} from '../utils/commonUtils';
import csvStringify from 'csv-stringify/lib/sync';
import {retrieve, create, update as put} from '../utils/APIHelpers';
import {PROGRESSION_API} from '../api';
import {TermApiError} from './TermApiError';
import {TermReactionsApiError} from './TermReactionsApiError';

const fetch = fetchMiddleWare;

/*** Domain Types ***/
export type Reaction =
  | 'ThumbsUp'
  | 'HeartEyes'
  | 'OneHundred'
  | 'PartyPopper'
  | 'ThumbsUp'
  | 'ThumbsDown'
  | 'FaceWithEyebrow';

export type TermReactions = {
  count: number;
  myReaction?: any;
  reactions: {
    count: number;
    reaction: Reaction;
  }[];
  results: TermReactionsResult;
};

type Report = {
  defaultTeam: string;
  tabTitle: string;
};

export const TERM_TYPES = {
  business_term: 'business_terms',
  object: 'salesforce_objects',
  field: 'salesforce_fields',
  field_value: 'salesforce_field_values',
  asset: 'assets',
};

export interface IPermissionResponse {
  data: {
    assets: string[];
    business_terms: string[];
    field_values: string[];
    fields: string[];
    objects: string[];
  };
}

const transformToPermissionSet = (data: IPermissionResponse['data']) => {
  const set = new Set<string>();
  Object.keys(data).forEach((contentType) => {
    data[contentType].forEach((item: string) => set.add(item));
  });
  return set;
};

export const terms = {
  buildQuery: (appliedFilters: any) => {
    const {
      documented,
      unDocumented,
      redirectedByTopic,
      redirectedByAsset,
      internalShare,
      externalShare,
      gdrive,
      sharepoint,
      uploaded,
      ...type
    } = appliedFilters;
    const keys = Object.keys(type);
    const types = [];

    let typeQuery = {};
    let topicQuery = {};
    let search = {};
    let readQuery = {};
    let documentedParam = {};
    let unDocumentedParam = {};
    let sharingPermission = {};

    const enabledContentSources = [
      gdrive && 'gdrive',
      sharepoint && 'sharepoint',
      uploaded && 'uploaded',
    ].filter(Boolean);

    const query = enabledContentSources.join(',');
    const contentSourceQuery = query ? {content_source: query} : {};

    //handle for type query param
    for (const key of keys) {
      if (appliedFilters[key] === true) {
        types.push(key);
      }
    }

    typeQuery = {type: types.length ? types : null};

    if (internalShare || externalShare) {
      sharingPermission = {
        only_spekit_share: internalShare,
        external_share: externalShare,
      };
    }

    //handle for topics query param
    if (appliedFilters.topics.length) {
      const tags = appliedFilters.topics.map((topic: any) => topic.name);
      topicQuery = {
        include_tags: encodeURIComponent(`${csvStringify([tags]).trim()}`),
      };
    }

    const getCorrectOrdering = () => {
      if (redirectedByAsset) return '-last_edited';
      if (redirectedByTopic) return 'rank,label';

      return 'label';
    };

    topicQuery = {
      ...topicQuery,
      ordering: getCorrectOrdering(),
    };

    if (documented || unDocumented) {
      if (documented && unDocumented) {
        documentedParam = {};
        unDocumentedParam = {};
      } else if (documented) {
        documentedParam = {exclude_undefined: true};
      } else if (unDocumented) {
        unDocumentedParam = {undefined: true};
      }
    }

    search = {
      q: appliedFilters.searchTerm ? appliedFilters.searchTerm : null,
    };

    readQuery = {include_read_speks_flag: true};

    return {
      ...readQuery,
      ...search,
      ...typeQuery,
      ...topicQuery,
      ...documentedParam,
      ...unDocumentedParam,
      ...sharingPermission,
      ...contentSourceQuery,
    };
  },
  termsToPermissionBody: function (terms: Term[]): PermissionRequest {
    return terms.reduce((body, term) => {
      if (Array.isArray(body[term.type + 's'])) {
        body[term.type + 's'].push(term.id);
      } else {
        body[term.type + 's'] = [term.id];
      }
      return body;
    }, {});
  },
  termsToPermissionAnyBody: function (terms: Term[], perms: string[]): PermissionRequest {
    return terms.reduce((body, term) => {
      if (Array.isArray(body[term.type + 's'])) {
        body[term.type + 's'].push(term.id);
      } else {
        body[term.type + 's'] = [term.id];
      }
      body['permissions'] = perms;
      return body;
    }, {});
  },
  permissionResponseToTerms: function (
    response: PermissionResponse,
    terms: Term[]
  ): Term[] {
    return terms.map((term) => {
      if (response.data[term.type + 's'].includes(term.id)) {
        term.editable = true;
      } else {
        term.editable = false;
      }
      return term;
    });
  },
  permissionAnyResponseToTerms: function (
    response: PermissionResponse,
    terms: Term[],
    permissionKey: 'shareable' | 'hideable'
  ): Term[] {
    return terms.map((term) => {
      if (response.data[term.type + 's'].includes(term.id)) {
        term[permissionKey] = true;
      } else {
        term[permissionKey] = false;
      }
      return term;
    });
  },
  getTerms: function (query: TermQuery, page?: number): Promise<TermResponse> {
    const options = {
      indices: false,
      arrayFormat: 'comma' as 'comma',
      skipNulls: true,
      encode: false,
    };
    query = {
      ...query,
      limit: 15,
      offset: 15 * (page || 0),
    };
    return new Promise((resolve, reject) => {
      fetch(`/api/v1.5/wiki?${qs.stringify(query, options)}`)
        .then((r) => r.json())
        .then((response) => {
          resolve({
            next:
              response.highest_count > 15 * ((page || 0) + 1)
                ? () => terms.getTerms(query, (page || 0) + 1)
                : undefined,
            terms: response.results,
          });
        })
        .catch((e) => reject(e));
    });
  },
  isTermStarred: (term: Term): Promise<IsStarredResponse> => {
    return new Promise((resolve, reject) => {
      fetch('/api/wiki/is_starred', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          [`${term.type}s`]: [term.id],
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          const ids = res.data[`${term!.type}s`];
          resolve({
            starred: !!ids.length,
            id: ids[0],
          });
        })
        .catch((e) => reject(e));
    });
  },
  star: function (term: Term): Promise<TermResponse> {
    return fetch('/api/starred_terms/', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        term_type: term.type,
        term_id: term.id,
      }),
    })
      .then((res) => res.json())
      .then((res) => res.results);
  },
  unstar: function (starId: string): void {
    fetch(`/api/starred_terms/${starId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },
  getRelatedTerm: async (id: string, type: string): Promise<Term> => {
    let response = await fetch(`/api/v1/wiki/${type}/${id}`);
    let result = await response.json();
    return result;
  },
  getRelatedSpeks: function (context: Context): Promise<TermResponse> {
    return terms.getTerms({
      type: 'business_term',
      object_scope: context.id,
      ordering: '-last_edited',
      exclude_undefined: true,
    });
  },
  getSpeksMatching: function (searchTerm?: string): Promise<TermResponse> {
    const query: TermQuery = {
      exclude_undefined: true,
    };
    if (!!searchTerm) {
      query.q = searchTerm;
    }
    return terms.getTerms(query);
  },
  getStarredTerms: function (): Promise<StarredTermResponse> {
    return new Promise((resolve, reject) => {
      fetch('/api/starred_terms')
        .then((r) => r.json())
        .then((response) => {
          resolve({
            terms: response.results.map((t: StarredTerm) => ({
              id: t.id,
              term: t.term,
            })),
          });
        })
        .catch((e) => reject(e));
    });
  },
  getTopicSpeks: function (topicName: string): Promise<TermResponse> {
    return terms.getTerms({
      include_tags: topicName,
      ordering: 'label',
      exclude_undefined: true,
    });
  },
  checkTerms: function (limit?: number, page?: number): Promise<CheckTermsResponse> {
    const query: TermQuery = {
      limit: limit || 25,
      offset: (limit || 25) * (page || 0),
    };
    return fetch(`/api/v1.5/wiki/defined_terms?${stringifyQs(query)}`).then((r) =>
      r.json()
    );
  },
  getReactions: async function (
    termType: string,
    termId: string
  ): Promise<TermReactions> {
    return fetch(`/api/v1.2/term_reactions/${termType}/${termId}/`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((result: TermReactionsResponse) => {
        if (!result.results) {
          throw new TermReactionsApiError('unexpected response from term reactions api');
        } else {
          return {
            results: result.results,
            count: result.count,
            reactions: result.reactions.map((r) => ({
              count: r.count,
              reaction: r.reaction as Reaction,
            })),
            myReaction: result.my_reaction,
          };
        }
      })
      .catch((e) => {
        throw e;
      });
  },
  getViewers: async function (term: Term): Promise<TTermViewsResponse> {
    const url =
      term.type === TermType.asset
        ? `topic_assets/${term.id}/views?deleted=true`
        : `wiki/${term.type}s/${term.id}/views?deleted=true`;

    return fetch(`/api/v1.2/${url}`, {
      credentials: 'include',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new TermApiError('Response returned with error.');
      }
    });
  },
  getPermissions: function (terms: Term[]): Promise<Term[]> {
    let body = this.termsToPermissionBody(terms);
    return new Promise((res, rej) => {
      fetch('/api/wiki/can_user_edit_term', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            throw new TermApiError('unexpected response');
          } else {
            res(this.permissionResponseToTerms(result, terms));
          }
        })
        .catch((e) => rej(e));
    });
  },
  getSelectedPermissions: function (
    terms: Term[],
    permissions: string[],
    permissionKey: 'shareable' | 'hideable'
  ): Promise<Term[]> {
    let body = this.termsToPermissionAnyBody(terms, permissions);

    return new Promise((res, rej) => {
      fetch('/api/wiki/has_perms_any', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            throw new TermApiError('unexpected response');
          } else {
            res(this.permissionAnyResponseToTerms(result, terms, permissionKey));
          }
        })
        .catch((e) => rej(e));
    });
  },
  getSelectedPermissionsSet: async function (
    terms: Term[],
    permissions: string[],
    signal?: AbortController['signal']
  ) {
    const response = await put<IPermissionResponse>(
      '/api/wiki/has_perms_any',
      this.termsToPermissionAnyBody(terms, permissions),
      signal
    );
    return transformToPermissionSet(response.data);
  },
  getEditPermissionsSet: async function (
    terms: Term[],
    signal?: AbortController['signal']
  ) {
    const response = await put<IPermissionResponse>(
      '/api/wiki/can_user_edit_term',
      this.termsToPermissionBody(terms),
      signal
    );
    return transformToPermissionSet(response.data);
  },
  getReadStatus: async (termType: string, termId: string) => {
    const result = await retrieve<TermReadResponse>(
      `${PROGRESSION_API + termType}/${termId}`
    );
    return result;
  },
  markAsRead: async (payload: object) => {
    const results: TermMarkAsReadResponse = await create(`${PROGRESSION_API}`, payload);
    return results;
  },

  getTermStatus: async (termType: string, termId: string) => {
    let response = await fetch(`/api/wiki/${termType}/${termId}/status/`, {
      credentials: 'include',
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response;
  },
  getEditShareHidePermissionForTerms: async function (terms: Term[]) {
    const [
      termWithEditPermission,
      termWithShareablePermissions,
      termWithHideablePermissions,
    ] = await Promise.all([
      this.getPermissions(terms),
      this.getSelectedPermissions(
        terms,
        [
          'businessterms.share_own',
          'salesforcefields.share_own',
          'salesforcefieldvalues.share_own',
          'salesforceobjects.share_own',
        ],
        'shareable'
      ),
      this.getSelectedPermissions(
        terms,
        [
          'businessterms.hide_own',
          'salesforcefields.hide_own',
          'salesforcefieldvalues.hide_own',
          'salesforceobjects.hide_own',
        ],
        'hideable'
      ),
    ]);
    const permissions = new Map<string, {hideable?: boolean; shareable?: boolean}>();
    termWithShareablePermissions.forEach((term) => {
      permissions.set(term.id, {shareable: term.shareable});
    });
    termWithHideablePermissions.forEach((term) => {
      const value = permissions.get(term.id);
      permissions.set(term.id, {...value, hideable: term.hideable});
    });

    return termWithEditPermission.map((term) => ({...term, ...permissions.get(term.id)}));
  },

  downloadAssetFile: async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    // get filename from response header
    const filename =
      response.headers.get('Content-Disposition')?.split('filename=')[1] || 'file';
    const URL = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = URL;
    a.download = filename;
    // we need to append the element to the dom, otherwise it will not work in firefox
    document.body.appendChild(a);
    a.click();
    a.remove();
  },

  checkAssetExists: async (url: string) => {
    const response = await fetch(url, {
      method: 'head',
    });
    return response.status === 200;
  },
};

function generateSummary(viewsResult: TermViewsResult) {
  const views: TTermTeamSummary = {};
  Object.keys(viewsResult).forEach((team) => {
    const users = viewsResult[team];
    const {totalExternalShares, totalInternalViews} = users.reduce(
      (acc, user) => {
        return {
          totalExternalShares: acc.totalExternalShares + user.external_shares,
          totalInternalViews: acc.totalInternalViews + user.views,
        };
      },
      {totalExternalShares: 0, totalInternalViews: 0}
    );

    views[team] = {totalExternalShares, totalInternalViews};
  });
  return views;
}

function retrieveTeamOptions(data: TAnalyticsData) {
  return Object.keys(data).map((t, i) => ({
    label: t,
    value: i,
  }));
}

const reports: Record<ReportType, Report> = {
  views: {
    defaultTeam: 'all spekit users',
    tabTitle: 'Views',
  },
  reactions: {
    defaultTeam: 'all teams',
    tabTitle: 'Reactions',
  },
};

export function processAnalyticsData(config: TAnalyticsConfig, mode: ReportType) {
  let teams, summary, totalExternalViews;
  if (config.kind === 'view') {
    const {results, total_external_views} = config.data;
    teams = retrieveTeamOptions(results);
    summary = generateSummary(results);
    totalExternalViews = total_external_views;
  } else {
    teams = retrieveTeamOptions(config.data);
  }
  const team = teams.find((t) => t.label.toLowerCase() === reports[mode].defaultTeam);
  return {
    teams,
    team,
    summary,
    totalExternalViews,
  };
}
