import {Component} from 'react';
import {PropagateLoader} from 'react-spinners';
import {Fontawesome, TabSelectInput} from 'spekit-ui';

import {utils, logging, fetchMiddleWare} from 'spekit-datalayer';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import objectIcon from './images/stats_object.svg';
import fieldIcon from './images/stats_field.svg';
import fieldValueIcon from './images/stats_picklist_value.svg';
import knowledgeIcon from './images/stats_knowledge_card.svg';

const fetch = fetchMiddleWare.fetchMiddleWare;

class StatsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: null,
      tab: 'My stats',
      titlehovered: false,
      busy: false,
      error: null,
    };
    this.loadStats = this.loadStats.bind(this);
  }
  componentDidMount() {
    this.loadStats();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.teamId !== this.props.teamId) {
      this.loadStats();
    }
  }

  loadStats() {
    this.setState({busy: true});
    let statType = {
      'My stats': 'my_stats',
      'All stats': 'all_stats',
    };
    let params = {type: statType[this.state.tab], teams: this.props.teamId};
    let queryString = utils.stringifyQs(params, {skipNulls: true});
    fetch('/api/stats/?' + queryString, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        this.setState({
          stats: result,
          busy: false,
        });
      })
      .catch((err) => {
        logging.capture(err);
        this.setState({
          error: 'Sorry. Could not load stats.',
          busy: false,
        });
      });
  }
  render() {
    let styles = {
      header: {
        textAlign: 'center',
        color: '#354052',
        fontSize: '22px',
        fontFamily: 'Lato',
        marginBottom: '23px',
      },
      ttIcon: {
        color: 'rgba(159,169,186,0.5)',
        marginLeft: '1em',
        cursor: 'pointer',
        display: 'inline-block',
        position: 'relative',
      },
      tt: {
        position: 'absolute',
        width: '200px',
        right: '-222px',
        top: '6px',
        background: '#04254a',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '4px',
        fontSize: '14px',
        zIndex: '1',
        boxShadow: '0px 0px 30px 2px rgba(0,0,0,0.4)',
      },
      tabContainer: {
        maxWidth: '240px',
        margin: 'auto',
      },
      spinnerHContainer: {
        textAlign: 'center',
      },
      spinnerVContainer: {
        padding: '30px 0px',
        margin: 'auto',
        width: '0px',
      },
      stat: {
        display: 'inline-block',
        width: '25%',
        textAlign: 'center',
        padding: '0px 20px',
        boxSizing: 'border-box',
      },
      table: {
        width: '100%',
        margin: '60px 0px',
      },
      number: {
        verticalAlign: 'middle',
        textAlign: 'left',
        fontSize: '50px',
        fontWeight: '300',
      },
      desc: {
        verticalAlign: 'middle',
        textAlign: 'right',
      },
      image: {
        height: '80px',
        marginRight: '10px',
      },
      typeText: {
        color: '#354052',
        textTransform: 'uppercase',
        spacing: '2.8px',
        fontSize: '16px',
        whiteSpace: 'nowrap',
        fontWeight: '400',
      },
    };
    if (this.state.busy) {
      return (
        <div style={styles.spinnerHContainer}>
          <div style={styles.spinnerVContainer}>
            <PropagateLoader loading={this.state.busy} color='#3576E8' />
          </div>
        </div>
      );
    }
    if (this.state.error) {
      return <div>{this.state.error}</div>;
    }
    let {stats} = this.state;
    if (!stats) {
      return null;
    }
    return (
      <div>
        <div style={styles.header}>
          Progress stats
          <div
            style={styles.ttIcon}
            onMouseEnter={() => this.setState({titlehovered: true})}
            onMouseLeave={() => this.setState({titlehovered: false})}
          >
            <Fontawesome name={faInfoCircle} />
            {this.state.titlehovered ? (
              <div style={styles.tt}>
                Reflects the number of Speks for which you have edited the definition. Not
                seeing your changes? This data is refreshed every 6 hours.
              </div>
            ) : null}
          </div>
        </div>
        <div style={styles.tabContainer}>
          <TabSelectInput
            options={['My stats', 'All stats']}
            value={this.state.tab}
            onChange={(tab) => this.setState({tab}, this.loadStats)}
          />
        </div>
        <div style={styles.stat}>
          <table style={styles.table}>
            <tbody>
              <tr>
                <td style={styles.desc}>
                  <img src={objectIcon} style={styles.image} alt='objects' />
                </td>
                <td style={{...styles.number, color: '#5DC6E7'}}>
                  <div style={styles.typeText}>Objects</div>
                  {stats.objects}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={styles.stat}>
          <table style={styles.table}>
            <tbody>
              <tr>
                <td style={styles.desc}>
                  <img src={fieldIcon} style={styles.image} alt='fields' />
                </td>
                <td style={{...styles.number, color: '#3576E8'}}>
                  <div style={styles.typeText}>Fields</div>
                  {stats.fields}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={styles.stat}>
          <table style={styles.table}>
            <tbody>
              <tr>
                <td style={styles.desc}>
                  <img src={fieldValueIcon} style={styles.image} alt='picklist values' />
                </td>
                <td style={{...styles.number, color: '#C673D2'}}>
                  <div style={styles.typeText}>Picklist Values</div>
                  {stats.field_values}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={styles.stat}>
          <table style={styles.table}>
            <tbody>
              <tr>
                <td style={styles.desc}>
                  <img src={knowledgeIcon} style={styles.image} alt='Speks' />
                </td>
                <td style={{...styles.number, color: '#F7819E'}}>
                  <div style={styles.typeText}>Speks</div>
                  {stats.business_terms}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default StatsWidget;
