import React from 'react';
import {IOptionType} from 'spekit-types';
import {FormControl, FormLabel, FormErrorMessage, AsyncSelect} from 'spekit-ui';

import debounce from 'debounce-promise';
import {user} from 'spekit-datalayer';

interface IExpertSelectorProps {
  isInvalid: boolean;
  handleExpertsChange: (newValue: IOptionType) => void;
  topics: string[];
  label: string;
  placeholder?: string;
  dataTestId?: string;
  errorTestId?: string;
  defaultExpert: IOptionType | null;
  blurInputOnSelect?: boolean;
  isRequired?: boolean;
}

export function ExpertSelector({
  isInvalid,
  handleExpertsChange,
  label,
  placeholder = 'Select Expert',
  dataTestId,
  errorTestId,
  topics,
  defaultExpert,
  blurInputOnSelect,
  isRequired = true,
}: IExpertSelectorProps) {
  const getExperts = async (searchText: string) => {
    try {
      const {results} = await user.getExperts(searchText, topics);
      return results;
    } catch (err) {
      return [];
    }
  };

  const getDebouncedExperts = debounce(getExperts, 200, {leading: false});

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel data-testid={dataTestId}>{label}</FormLabel>

      <AsyncSelect
        name='experts'
        placeholder={placeholder}
        aria-live='off'
        menuPlacement='auto'
        isClearable
        defaultOptions
        cacheOptions
        loadOptions={getDebouncedExperts}
        onChange={handleExpertsChange}
        defaultValue={defaultExpert}
        blurInputOnSelect={blurInputOnSelect}
        key={JSON.stringify(topics)}
      />
      {isInvalid && (
        <FormErrorMessage data-testid={errorTestId}>Select expert</FormErrorMessage>
      )}
    </FormControl>
  );
}
