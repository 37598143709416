import {IAppConfigResponse, TAppConfigUpdatePayload} from 'spekit-types';
import {retrieve, update} from './utils/APIHelpers';

export function fetchAppConfig() {
  return retrieve<IAppConfigResponse>('/api/v1/app_config/');
}

export function updateConfig(config: TAppConfigUpdatePayload) {
  return update<IAppConfigResponse>('/api/v1/app_config/', {config});
}
