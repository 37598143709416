import {useQuery} from '@tanstack/react-query';
import {QUERY_KEYS} from 'spekit-datalayer';
import {IFileOrFolder, IActionOutput, IFilePickerParams} from 'spekit-types';
import {MY_DRIVE} from '../constants';
import {useIntegrationApp, IntegrationAppClient} from '@integration-app/react';
import {getFilesAndFolders, getParams} from '../helpers';

const getListFiles = async (filters: IFilePickerParams, iApp: IntegrationAppClient) => {
  const {drive, store, parentId, searchTerm, filter} = filters;
  if (!drive) return;

  let cursor = '';
  let records: IFileOrFolder[] = [];
  do {
    const params = getParams(store, drive, parentId, searchTerm, filter);
    if (cursor) params['cursor'] = cursor;
    if (drive.id !== MY_DRIVE.id) params['driveId'] = drive.id;
    const response = await iApp.connection(store).action('list-files').run(params);
    const output = response.output as IActionOutput<IFileOrFolder>;
    let updated = output.records;
    if (store === 'microsoft-sharepoint') {
      updated = getFilesAndFolders(updated, store);
    }
    records.push(...updated);
    cursor = output.cursor;
  } while (cursor);
  return records;
};

export const useListFiles = (params: IFilePickerParams) => {
  const iApp = useIntegrationApp();

  const hasSearchTerm = Boolean(params.searchTerm);

  const hasFilters =
    params.filter?.period?.value !== 'any' || params.filter?.type?.value !== 'all';

  const enabled = !(
    (hasSearchTerm || hasFilters) &&
    params.store === 'microsoft-sharepoint'
  );

  const query = useQuery({
    queryKey: [QUERY_KEYS.ListContentFiles, params],
    queryFn: () => getListFiles(params, iApp),
    refetchOnWindowFocus: false,
    enabled,
    staleTime: 5 * 60 * 1000,
  });

  return query;
};
