import {Flex, Card, Text, Tag, Icon, DSButton as Button, Heading} from 'spekit-ui';
import {DATE_FORMAT, format, parseISO} from 'spekit-datalayer';
import {IQuiz} from 'spekit-types';
import {track} from 'spekit-datalayer/src/analytics';
import {useHistory} from 'react-router-dom';
import {RiArrowRightLine, RiCheckDoubleLine, RiTimeLine} from 'react-icons/ri';
import {handleView} from '../../../../utils/ExtensionActions';

type TProgressVariant = 'warning' | 'pink' | 'success';

const progressToTagVariant: {[value: string]: TProgressVariant} = {
  'Not started': 'warning',
  'In progress': 'pink',
  Completed: 'success',
};

const ItemCard = (knowledgeCheck: IQuiz) => {
  const history = useHistory();

  const handleClick = () => {
    handleView({
      action: 'viewKnowledgeCheck',
      data: {knowledgeCheckId: knowledgeCheck.id},
      history,
    });
    track('My Knowledge Checks Widget - View Knowledge Check', {
      id: knowledgeCheck.id,
      title: knowledgeCheck.title,
    });
  };

  return (
    <Card
      key={knowledgeCheck.id}
      p='16'
      _hover={{
        boxShadow: '0 0 45px 0 rgba(221, 221, 221, 0.55)',
        transition: 'box-shadow 0.4s ease',
      }}
      onClick={handleClick}
      cursor='pointer'
    >
      <Flex alignItems='center' justifyContent='space-between'>
        <Flex direction='column' gap='16'>
          <Flex gap='12'>
            <Heading as='h4' fontWeight='normal'>
              {knowledgeCheck.title}
            </Heading>
            <Tag
              colorScheme={progressToTagVariant[knowledgeCheck.progress]}
              variant='status'
              fontWeight='normal'
            >
              {knowledgeCheck.progress}
            </Tag>
          </Flex>
          <Flex gap='12'>
            <Flex alignItems='center' gap='4'>
              <Icon as={RiCheckDoubleLine} w='16px' h='16px' />
              <Text variant='body2' fontWeight='normal'>
                {knowledgeCheck.progress === 'Completed'
                  ? `${knowledgeCheck.score} of ${knowledgeCheck.questions_count} correct`
                  : `${knowledgeCheck.questions_count} ${
                      knowledgeCheck.questions_count === 1 ? 'question' : 'questions'
                    }`}
              </Text>
            </Flex>
            <Flex alignItems='center' gap='4'>
              <Icon as={RiTimeLine} w='16px' h='16px' />
              <Text variant='body2' fontWeight='normal'>
                {knowledgeCheck.progress === 'Completed'
                  ? `Completed ${format(
                      parseISO(knowledgeCheck.completed_on!),
                      DATE_FORMAT
                    )}`
                  : `Published ${format(
                      parseISO(knowledgeCheck.published_on!),
                      DATE_FORMAT
                    )}`}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Button
          aria-label='view knowledge check'
          size='medium'
          variant='ghost'
          colorScheme='primary'
          fontWeight='normal'
          rightIcon={<Icon as={RiArrowRightLine} w='22px' h='22px' />}
          onClick={handleClick}
        >
          {knowledgeCheck.progress === 'Completed' ? 'View' : 'Take'}
        </Button>
      </Flex>
    </Card>
  );
};

export default ItemCard;
