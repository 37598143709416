import React from 'react';
import {Accordion as ChakraAccordion, AccordionProps} from '@chakra-ui/react';

export type TAccordionProps = AccordionProps;

export const AccordionConfig = {
  parts: ['accordion', 'item', 'button', 'panel'],
  variants: {
    default: {
      button: {
        background: 'neutral.0',
        border: 'none',
        color: 'neutral.800',
        _hover: {
          background: 'neutral.25',
          color: 'neutral.800',
          cursor: 'pointer',
        },
        _focusVisible: {
          border: '1px',
          borderColor: 'primary.500',
          color: 'neutral.800',
          boxShadow: 'none',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
  baseStyle: {},
  sizes: {},
  defaultProps: {variant: 'default'},
};
export const Accordion = (props: TAccordionProps) => <ChakraAccordion {...props} />;
