import * as React from 'react';
import {Icon} from 'spekit-ui';
import styles from './StatusBar.module.css';
import {
  RiAccountCircleLine,
  RiQuestionLine,
  RiSettings3Line,
  RiShareBoxLine,
  RiErrorWarningLine,
} from 'react-icons/ri';

interface IProps {
  appPath?: string;
  chrome?: boolean;
  goToSettings?: () => void;
  isInSettings?: boolean;
  menuOpen?: boolean;
  openProfileMenu?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  session?: {role: string};
  trackNeedHelpClick?: () => void;
  handleErrorReport?: () => void | null;
}

const StatusBar = (props: IProps) => {
  const {
    appPath,
    chrome,
    goToSettings,
    isInSettings,
    menuOpen,
    openProfileMenu,
    session,
    trackNeedHelpClick,
    handleErrorReport,
  } = props;

  if (chrome && appPath) {
    return (
      <div
        className={`${styles.chromeStatusBar} ${styles.statusBar}`}
        data-testid='statusBarChromeMain'
        style={!handleErrorReport ? {gap: '40px'} : {}}
      >
        <a
          className={styles.statusBarItem}
          href={appPath}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Icon as={RiShareBoxLine} mr={4} />
          <span>Webapp</span>
        </a>
        <div
          className={`${styles.statusBarItem} ${isInSettings ? styles.menuOpen : ''}`}
          onClick={goToSettings}
        >
          <Icon as={RiSettings3Line} mr={4} />
          <span>Settings</span>
        </div>
        {handleErrorReport && (
          <div className={styles.statusBarItem} onClick={handleErrorReport}>
            <Icon as={RiErrorWarningLine} mr={4} />
            <span>Report a bug</span>
          </div>
        )}

        <>
          {session?.role.includes('Admin') && trackNeedHelpClick && (
            <a
              className={styles.statusBarItem}
              href='https://help.spekit.com/'
              target='_blank'
              rel='noopener noreferrer'
              onClick={trackNeedHelpClick}
              data-testid='helpCenterLink'
            >
              <Icon as={RiQuestionLine} mr={4} />
              <span>Support</span>
            </a>
          )}
        </>
      </div>
    );
  }

  return (
    <div
      className={`${styles.webStatusBar} ${styles.statusBar}`}
      data-testid='statusBarWebMain'
    >
      <div
        className={`${styles.statusBarItem} ${menuOpen ? styles.menuOpen : ''}`}
        onClick={(e) => openProfileMenu && openProfileMenu(e)}
        data-testid='statusBarProfileBtn'
      >
        <Icon as={RiAccountCircleLine} mr={4} />
        <span>Profile</span>
      </div>
      <a className={styles.statusBarItem} href='https://help.spekit.com/' target='_blank'>
        <Icon as={RiQuestionLine} mr={4} />
        <span>Help Center</span>
      </a>
    </div>
  );
};

export default StatusBar;
