import {
  IPerson,
  TContentIntegration,
  TCustomFieldValues,
  TFileUpdateMeta,
} from 'spekit-types';
import {User} from '../user/user.types';

/*** Endpoint Types ***/
export type TermReactor = Pick<
  User,
  'deleted' | 'email' | 'first_name' | 'id' | 'last_name' | 'username'
>;

export interface TermReaction {
  reaction: string;
  term: Pick<Term, 'id' | 'type'>;
  created_by: TermReactor;
  created_on: string;
}

export type TermReactionsResult = Record<string, TermReaction[]>;

export type TermReactionsResponse = {
  count: number;
  my_reaction: null;
  reactions: {
    count: number;
    reaction: string;
  }[];
  results: TermReactionsResult;
};

export interface TermViewResponse {
  deleted: boolean;
  first_name: string;
  id: string;
  last_name: string;
  read: boolean;
  viewed_on: string;
  views: number;
  external_shares: number;
}
export type TermViewsResult = Record<string, TermViewResponse[]>;

export type TTermViewsResponse = {
  total_external_views: number;
  results: TermViewsResult;
};

/****/

export enum TermType {
  asset = 'asset',
  business_term = 'business_term',
  field = 'field',
  field_value = 'field_value',
  object = 'object',
}

export interface Term {
  id: string;
  definition: string;
  business_rule: string;
  link: string;
  data_expert: IPerson;
  created_by: IPerson;
  updated_by: IPerson;
  last_edited_by: IPerson | null;
  type: TermType;
  tags: {
    id: string;
    name: string;
  }[];
  data: {
    [key: string]: string;
  };
  viewers: {
    [key: string]: {
      first_name: string;
      last_name: string;
      id: string;
      viewed_on: string;
      views: number;
    }[];
  };
  related_objects?: {
    id: string;
    label: string;
  }[];
  objects?: {
    id: string;
    label: string;
  }[];
  fields?: number;
  business_terms?: {
    id: string;
    label: string;
  }[];
  created_on: string;
  deleted: boolean;
  label: string;
  label_plural?: string;
  definition_updated: boolean;
  last_edited: string | null;
  hidden: boolean;
  updated_on: string;
  editable?: boolean;
  shareable?: boolean;
  is_externally_shared?: boolean;
  hideable?: boolean;
  system: boolean;
  sys_period: string;
  organization: string;
  media_files: {}[];
  sfdc_api_name?: string;
  key_prefix?: string;
  count?: number;
  managed?: boolean;
  custom?: boolean;
  synced?: boolean;
  salesforce_id?: string;
  field_type?: string;
  field_values?: number;
  formula?: string;
  default_value?: string;
  global_api_name?: string;
  help_text?: string;
  sfdc_link?: string;
  last_deployed?: string;
  internal_description?: string;
  encrypted?: boolean;
  length?: number;
  controller_name?: string;
  required?: boolean;
  page_layouts?: {
    id: string;
    name: string;
  }[];
  parent?: {
    id: string;
    label: string;
    url: string;
  };
  parent_object?: {
    id: string;
    label: string;
  };
  is_read?: boolean;
  is_system_spek?: boolean;
  is_starred?: boolean;
  views?: {
    all_time: number;
    all_time_with_deleted_users: number;
    last_30: null;
    last_30_with_deleted_users: null;
  };
  external_shares_count?: number;
  external_views?: number;
  custom_columns: TCustomFieldValues;
  reference_url: string | null;
  store: 'internal' | TContentIntegration;
  update_meta: TFileUpdateMeta;
}

export interface PermissionRequest {
  objects?: string[];
  fields?: string[];
  field_values?: string[];
  business_terms?: string[];
  permissions?: string[];
}

export interface PermissionResponse {
  data: PermissionRequest;
}

export interface IsStarredResponse {
  starred: boolean;
  id: string;
}

export interface TermResponse {
  terms: Term[];
  next?: () => Promise<TermResponse>;
}

export interface ChromeTermResponse {
  next?: Function;
  highest_count: number;
  results: Array<Term>;
  similar_results: {highestCount: number; results: Array<Term>};
  tags: Array<{name: string; term_count: number}>;
}

export interface StarredTerm {
  term: Term;
  id: string;
}

export interface StarredTermResponse {
  terms: StarredTerm[];
  next?: () => Promise<TermResponse>;
}

export interface TermQuery {
  type?: 'business_term';
  object_scope?: string;
  include_tags?: string;
  ordering?: string;
  exclude_undefined?: boolean;
  include_read_speks_flag?: boolean;
  q?: string;
  limit?: number;
  offset?: number;
}

export interface Domain {
  name: string;
  include_subdomain: boolean;
  paths: string[];
}

export interface Tag {
  id: string;
  name: string;
  domains: Domain[];
}

export interface CheckTermsResponse {
  highest_count: number;
  results: {
    id: string;
    label: string;
    label_plural: string;
    context: string[];
    tags: Tag[];
    type: 'object' | 'field' | 'field_value' | 'business_term';
    field_label: string | null;
  }[];
}

export interface TermReadResponse {
  read: boolean;
  details?: {
    id: string;
    term_id: string;
    term_type: string;
    source: string;
    term_view_id: string;
  };
  message?: string;
}

export interface TermMarkAsReadResponse {
  id: string;
  term_id: string;
  term_type: string;
  source: string;
  term_view_id: string;
  success: string;
  message: string;
}

export type TTermTeamSummary = Record<
  string,
  {totalInternalViews: number; totalExternalShares: number}
>;

export type TAnalyticsConfig =
  | {
      kind: 'view';
      data: TTermViewsResponse;
    }
  | {
      kind: 'reaction';
      data: TermReactionsResult;
    };
export type TAnalyticsData = TermReactionsResult | TermViewsResult;

export type ReportType = 'views' | 'reactions';
