import {retrieve} from './utils/APIHelpers';
import {IGlobalBlacklistResponse} from 'spekit-types';
import {api} from './api';
import {AxiosRequestConfig} from 'axios';

export function fetchGlobalBlacklist() {
  return retrieve<IGlobalBlacklistResponse>('/api/v1/global_blacklists/');
}

export const getGlobalBlacklist = async (params?: AxiosRequestConfig['params']) => {
  return api
    .get<IGlobalBlacklistResponse>('/api/v1/global_blacklists/', {params})
    .then(({data}) => data);
};
