import {TeamSelectorDropdown, ITeamSelectorCallback} from 'spekit-shared-components';
import {Flex, Text, Box} from 'spekit-ui';
import {IOptionType} from 'spekit-types';

interface IProps {
  value: IOptionType | null;
  onSelect: (cbValue: ITeamSelectorCallback | null) => void;
}

const TeamSelectorDashboard = (props: IProps) => (
  <Flex mt={20} alignItems='center' justifyContent='flex-end' gap={8} flex='1'>
    <Text variant='body1' fontWeight='bold'>
      View analytics for
    </Text>
    <Box w='300px' fontSize={16}>
      <TeamSelectorDropdown
        {...props}
        disablePermissions={true}
        sortAlphabetically
        loadfirstTeamByDefault
      />
    </Box>
  </Flex>
);

export default TeamSelectorDashboard;
