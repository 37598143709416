import React from 'react';
import {user} from 'spekit-datalayer';
import {ControlledSelect} from 'spekit-ui';

interface IExpertPickerProps {
  tags: string[];
  isDisabled?: boolean;
  showTooltip?: boolean;
}

export const ExpertPicker = ({
  tags,
  isDisabled,
  showTooltip = true,
}: IExpertPickerProps) => {
  const getExperts = async (searchText: string) => {
    try {
      let {results} = await user.getExperts(searchText, tags);
      return results;
    } catch (err) {
      return [];
    }
  };

  return (
    <ControlledSelect
      isClearable
      testId='expert-picker'
      menuTestId='expert-picker-menu'
      key={JSON.stringify(tags)}
      name='expert'
      id='expert'
      label='Expert'
      labelTooltip={
        showTooltip ? 'Expert will be notified they’ve been assigned to this Spek' : ''
      }
      placeholder='Select Expert'
      isAsync
      loadOptions={async (searchText: string) => getExperts(searchText)}
      cacheOptions
      defaultOptions
      isDisabled={isDisabled}
    />
  );
};
