import {Component, Fragment} from 'react';
import {notifications, DSTooltip as Tooltip, Fontawesome} from 'spekit-ui';
import {faInfoCircle, faClock} from '@fortawesome/free-solid-svg-icons';
import {utils, fromNow, fetchMiddleWare} from 'spekit-datalayer';
import HeartEyes from './images/star_eyes.png';
import OneHundred from './images/one_hundred.png';
import PartyPopper from './images/party_popper_new.png';
import ThumbsUp from './images/thumbs_up.png';
import ThumbsDown from './images/thumbs_down.png';
import Eyebrow from './images/eyebrow_new.png';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import EmptyState from './images/recent_reaction_empty_state.svg';

const fetch = fetchMiddleWare.fetchMiddleWare;
const {notify} = notifications;

const emojisImageMapper = {
  HeartEyes: HeartEyes,
  OneHundred: OneHundred,
  PartyPopper: PartyPopper,
  ThumbsUp: ThumbsUp,
  ThumbsDown: ThumbsDown,
  FaceWithEyebrow: Eyebrow,
};

const emojisLabelMapper = {
  HeartEyes: 'Incredible',
  OneHundred: 'On point',
  PartyPopper: 'Celebrate',
  ThumbsUp: 'Helpful',
  ThumbsDown: 'Not helpful',
  FaceWithEyebrow: 'Confused',
};

class RecentReactionsWidget extends Component {
  state = {
    activities: [],
  };
  componentDidMount() {
    this.fetchRecentActivity();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.teamId !== this.props.teamId) {
      this.fetchRecentActivity();
    }
  }
  fetchRecentActivity = async () => {
    try {
      let params = {
        type: 'recent_activity',
        teams: this.props.teamId,
      };
      let queryString = utils.stringifyQs(params, {skipNulls: true});
      let response = await fetch('/api/v1/stats/?' + queryString, {});

      if (response.status === 200) {
        let result = await response.json();
        this.setState({activities: result});
      } else {
        throw new Error('Could not get the most recent activity for reactions');
      }
    } catch (err) {
      notify({error: true, text: err.message});
    }
  };

  render() {
    let styles = {
      heading: {
        fontSize: '20px',
        color: '#354052',
        fontWeight: '500',
        marginBottom: '20px',
      },
      container: {
        display: 'grid',
        height: '66px',
        gridGap: '3px',
        gridTemplateColumns: 'repeat(8, 1fr)',
        gridTemplateRows: '40px auto',
        gridTemplateAreas: `
                            "emoji emoji text text text text text text"
                            "date date text text text text text text"
                           `,
        marginBottom: '20px',
      },
      firstColumn: {
        gridArea: 'emoji',
      },
      img: {
        height: '26px',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: '0.5em',
      },
      blueText: {
        color: '#3576E8',
        cursor: 'pointer',
        textDecoration: 'none',
      },
      firstLine: {
        display: 'inline-block',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '20px',
      },
      labelText: {
        display: 'inline-block',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '20px',
      },
      secondColumn: {
        gridArea: 'text',
      },
      secondRFirstC: {
        gridArea: 'date',
        fontSize: '12px',
        fontWeight: '600',
        color: '#8186AD',
      },
      headingArrow: {
        color: 'rgba(99,114,128,0.42)',
        marginLeft: '0.5em',
      },
      faClock: {
        marginRight: '0.5em',
        marginLeft: '0.2em',
      },
      emptyStateContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '415px',
      },
      emptyStateImg: {
        marginBottom: '28px',
      },
      emptyStateFirstLine: {
        fontSize: '20px',
        color: '#4A4A4A',
        fontWeight: 'bold',
        lineHeight: '30px',
      },
      emptyStateSecondLine: {
        fontSize: '14px',
        color: '#4A4A4A',
        lineHeight: '30px',
        fontWeight: '300',
      },
    };
    let {activities} = this.state;
    let dataExpertsUrl;
    if (
      this.props.wikiStoredState.uiMode === 'data_dictionary' ||
      this.props.me.company.salesforceorganizations_set[0].instance_url === 'dummy'
    ) {
      dataExpertsUrl = `&type=all`;
    } else {
      dataExpertsUrl = `&type=object,field_value,business_term`;
    }
    return (
      <Fragment>
        <div style={styles.heading}>
          Recent reaction activity
          <Tooltip label='Recent reactions by your team' shouldWrapChildren>
            <Fontawesome style={styles.headingArrow} name={faInfoCircle} />
          </Tooltip>
        </div>
        <Fragment>
          {activities.length === 0 ? (
            <div style={styles.emptyStateContainer}>
              <img src={EmptyState} alt='empty' style={styles.emptyStateImg} />
              <div style={styles.emptyStateFirstLine}>Not enough reaction activity.</div>
              <div style={styles.emptyStateSecondLine}>
                Keep your finger on the pulse of your team.
              </div>
              <div style={styles.emptyStateSecondLine}>
                Add a reaction to any Spek to see this populated.
              </div>
            </div>
          ) : (
            <Fragment>
              {activities
                .slice(0, 5)
                .map(
                  (
                    {reaction, created_by: {first_name, last_name, id}, term, created_on},
                    index
                  ) => (
                    <div style={styles.container} key={index}>
                      <div style={styles.firstColumn}>
                        <img
                          src={emojisImageMapper[reaction]}
                          style={styles.img}
                          alt='emoji'
                        />
                        <div style={styles.firstLine}>{emojisLabelMapper[reaction]}</div>
                      </div>
                      <div style={styles.secondColumn}>
                        <div style={styles.labelText}>
                          <span>
                            <Link
                              style={styles.blueText}
                              to={`/app/wiki/?data_experts=${id}${dataExpertsUrl}`}
                            >
                              {first_name + ' ' + last_name}
                            </Link>
                            {' reacted to '}
                            <Link
                              style={styles.blueText}
                              to={`/app/wiki/${term.type}/${term.id}?expanded=true&type=${term.type}`}
                            >
                              {term.label}
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div style={styles.secondRFirstC}>
                        <Fontawesome name={faClock} style={styles.faClock} />
                        <span>{fromNow(created_on, {addSuffix: true})}</span>
                      </div>
                    </div>
                  )
                )}
            </Fragment>
          )}
        </Fragment>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    me: state.layout.me,
    wikiStoredState: state.wikiStoredState.storedState,
  };
};

export default connect(mapStateToProps)(RecentReactionsWidget);
