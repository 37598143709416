import React from 'react';
import {
  BreadcrumbItem as ChakraBreadcrumbItem,
  BreadcrumbItemProps,
} from '@chakra-ui/react';

export type TBreadcrumbItemProps = BreadcrumbItemProps;

export const BreadcrumbItem = (props: TBreadcrumbItemProps) => (
  <ChakraBreadcrumbItem {...props} />
);
